<template>
  <div>
    <p class="txt-title poppins">Perfil</p>
    <!-- content information -->
    <div v-if="!activeEdit">
      <div>
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Nombres</p>
                <p class="txt-value-global">{{ name }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Apellidos</p>
                <p class="txt-value-global">{{ lastname }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Correo electrónico</p>
                <p class="txt-value-global">{{ email }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Cargo laboral</p>
                <p class="txt-value-global">{{ position }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Teléfono</p>
                <p class="txt-value-global">{{ phone }}</p>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <div>
                <p class="txt-label-global">Fecha de creación</p>
                <p class="txt-value-global">{{ date }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="border-line mt-9 mb-9" />
      <div class="display-flex align-items-center justify-content-flex-end">
        <v-btn
          @click="activeEdit = true"
          class="button-primary poppins pl-9 pr-9"
          elevation="0"
        >
          Modificar
        </v-btn>
      </div>
    </div>
    <layout-profile-edit :editArr="editArr" @cancel="cancel" v-else />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutProfileEdit from "@/layouts/Profile/Edit.vue";

export default {
  name: "LayoutProfileContent",
  data() {
    return {
      name: "",
      email: "",
      lastname: "",
      phone: "",
      position: "",
      date: "",
      bLoading: false,
      activeEdit: false,
      editArr: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    cancel: function () {
      this.activeEdit = false;
    },
    getData: function () {
      this.bLoading = true;

      DB.get(
        `${URI}/enterprises/${this.$store.state.sEnterpriseId}/customers/${this.$store.state.sUserId}/profile`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.bLoading = false;
          let item = response.data.results;
          this.editArr = item;

          // nombre
          this.name = item.sName ? item.sName : "Información no capturada.";
          // apellido
          this.lastname = item.sLastname
            ? item.sLastname
            : "Información no capturada.";
          // correo
          this.email = item.oGeneralInfo.sEmail
            ? item.oGeneralInfo.sEmail
            : "Información no capturada.";
          // telefono
          this.phone = item.oContactInfo.sPhoneNumber
            ? this.formatPhone(item.oContactInfo)
            : "Información no capturada.";
          // puesto
          this.position = item.oEnterpriseInfo.sJobTitle
            ? item.oEnterpriseInfo.sJobTitle
            : "Información no capturada.";
          // fecha de creacion
          this.date = item.oGeneralInfo.tCreatedAt
            ? item.oGeneralInfo.tCreatedAt
            : "Información no capturada.";
          // refresher
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          console.log(error.response.data.message);
        });
    },
    formatPhone: function (item) {
      let result = "";

      if (item.sPhoneExtension == "") {
        result =
          "+" +
          item.sCountryCallingCode +
          " " +
          item.sAreaCallingCode +
          item.sPhoneNumber;
      } else {
        result =
          "+" +
          item.sCountryCallingCode +
          " " +
          item.sAreaCallingCode +
          item.sPhoneNumber +
          " Ext. " +
          item.sPhoneExtension;
      }
      return result;
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      this.getData();
    },
  },
  components: {
    LayoutProfileEdit,
  },
};
</script>

<style scoped>
.border-line {
  border-bottom: 1px solid #d0dbf2;
}

.txt-item {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #a1acc3;
  opacity: 1;
}

.txt-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

@media (max-width: 500px) {
  .button-primary {
    width: 100%;
  }
}
</style>