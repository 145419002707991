<template>
  <div>
    <div>
      <div class="content-title-section mb-7">Planes disponibles</div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <v-select
                v-model="sCustomPlan"
                :items="aPlans"
                item-text="sName"
                item-value="sCustomPlanId"
                label="Planes"
                placeholder="Elegir plan"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                persistent-placeholder
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span>Planes <span class="color-red">*</span></span>
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div>
      <div class="content-title-section mb-7">Selección de tarjetas SIM</div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <v-text-filed-currency
                v-model="iLargeSIMsQuantityRequired"
                label="Tarjeta tamaño: Estándar"
                :options="oOptionsSimSize"
                outline
                sClass="global-auth-inputs poppins global-input-currency"
                placeholder="0"
                color="#2759A2"
                :persistentPlaceholder="true"
                hide-details
                dense
              />
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-text-filed-currency
                v-model="iMediumSIMsQuantityRequired"
                label="Tarjeta tamaño: Micro"
                :options="oOptionsSimSize"
                outline
                sClass="global-auth-inputs poppins global-input-currency"
                placeholder="0"
                color="#2759A2"
                :persistentPlaceholder="true"
                hide-details
                dense
              />
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-text-filed-currency
                v-model="iSmallSIMsQuantityRequired"
                label="Tarjeta tamaño: Nano"
                :options="oOptionsSimSize"
                outline
                sClass="global-auth-inputs poppins global-input-currency"
                placeholder="0"
                color="#2759A2"
                :persistentPlaceholder="true"
                hide-details
                dense
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <!-- seccion añadida informacion adicional de la solicitud -->
    <div>
      <div class="content-title-section mb-7">
        Información adicional de la solicitud
      </div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <v-textarea
                v-model="sComments"
                :label="`Comentarios adicionales`"
                :placeholder="`Escribe tus comentarios aquí`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="500"
                persistent-placeholder
                rows="4"
                auto-grow
              ></v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div class="content-active-cards">
      <v-checkbox
        v-model="bActiveCards"
        color="#f89925"
        class="mt-0 pt-0"
        hide-details
      ></v-checkbox>
      <p class="text-active-cards">
        Requiero que todas las tarjetas SIM tengan un estado: Activo.
      </p>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div>
      <p class="content-title-section">Información de entrega</p>
      <!-- <p class="subcontent-text-section">Datos de envío</p> -->
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <v-select
                v-model="tipoEnvioSeleccionado"
                :items="aTypeSend"
                item-text="text"
                item-value="value"
                label="Tipo de entrega"
                placeholder="Elige un tipo de entrega"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                persistent-placeholder
                @change="changeTipoEnvio"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span>Tipo de entrega <span class="color-red">*</span></span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col v-if="tipoEnvioSeleccionado == 2" cols="12" md="3">
            <div>
              <v-select
                v-model="sCarrierId"
                :items="aCarriers"
                item-value="sCarrierId"
                item-text="sName"
                label="Paquetería de preferencia"
                placeholder="Elige tu paquetería de preferencia"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                persistent-placeholder
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span
                    >Paquetería de preferencia
                    <span class="color-red">*</span></span
                  >
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <p class="subcontent-text-section mt-4">Datos de ubicación</p>
      <v-container class="pl-0 pr-0" fluid v-if="tipoEnvioSeleccionado !== 3">
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <v-select
                v-model="sCountry"
                :label="`País de ${nombreEnvio}`"
                :placeholder="`País de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                persistent-placeholder
                :items="aCountries"
                item-text="sName"
                item-value="sCountryId"
                @change="selectStates()"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span
                    >País de {{ nombreEnvio }}
                    <span class="color-red">*</span></span
                  >
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-select
                v-model="sState"
                :label="`Estado de ${nombreEnvio}`"
                :placeholder="`Estado de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                persistent-placeholder
                :items="aStates"
                item-text="sName"
                item-value="sStateId"
                no-data-text="No hay datos disponibles"
              >
                <template slot="label">
                  <span
                    >Estado de {{ nombreEnvio }}
                    <span class="color-red">*</span></span
                  >
                </template></v-select
              >
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-text-field
                v-model="sShippingCity"
                :label="`Ciudad de ${nombreEnvio}`"
                :placeholder="`Ciudad de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="40"
                persistent-placeholder
                @keypress="formatCity"
              >
              <template slot="label">
                  <span
                    >Ciudad de {{ nombreEnvio }}
                    <span class="color-red">*</span></span
                  >
                </template>
            </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <v-text-field
                v-model="sShippingPostalCode"
                :label="`Código postal de ${nombreEnvio}`"
                :placeholder="`Código postal de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="5"
                persistent-placeholder
                @keypress="soloNumbers"
              >
              <template slot="label">
                <span>Código postal de {{nombreEnvio}} <span class="color-red">*</span></span>
              </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <v-textarea
                v-model="sShippingAddressLine1"
                :label="`Dirección de ${nombreEnvio}`"
                :placeholder="`Dirección de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="500"
                persistent-placeholder
                rows="4"
                auto-grow
              >
              <template slot="label">
                <span>Dirección de {{nombreEnvio}} <span class="color-red">*</span></span>
              </template>
            </v-textarea>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <v-textarea
                v-model="sShippingAddressLine2"
                :label="`Referencias adicionales de ${nombreEnvio}.`"
                :placeholder="`Referencias adicionales de ${nombreEnvio}`"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="500"
                persistent-placeholder
                rows="4"
                auto-grow
              >
              <template slot="label">
                <span>Referencias adicionales de {{nombreEnvio}} <span class="color-red">*</span></span>
              </template>
              </v-textarea>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="pl-0 pr-0" fluid v-else>
        <v-row>
          <v-col>
            <div>
              <h3>Emprenet</h3>
              <h4>SOLUCIONES EN COMUNICACION MOVIL EMPRESARIAL SA DE CV</h4>
              <h5>Lisboa 806, Mirador, 64070 Monterrey, N.L., Mexico</h5>
              <h5>+52 81 2504 1824</h5>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <p class="subcontent-text-section mt-4">Datos de contacto</p>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="4">
            <div>
              <v-text-field
                v-model="sReceiverFullName"
                label="Nombre completo de destinatario"
                placeholder="Nombre completo de destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="40"
                persistent-placeholder
                @keypress="soloLetters"
              >
            <template slot="label">
              <span>Nombre completo de destinatario <span class="color-red">*</span></span>
            </template>
            </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <v-text-field
                v-model="sReceiverEmail"
                label="Correo electrónico del destinatario"
                placeholder="Correo electrónico del destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="40"
                persistent-placeholder
                @keypress="formatEmail"
              >
              <template slot="label">
                <span>Correo electrónico del destinatario <span class="color-red">*</span></span>
              </template>
              </v-text-field>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <v-text-field
                v-model="sReceiverPhone"
                label="Número telefónico del destinatario"
                placeholder="Número telefónico del destinatario"
                dense
                color="#2759A2"
                class="global-auth-inputs poppins"
                maxlength="10"
                persistent-placeholder
                @keypress="soloNumbers"
              >
              <template slot="label">
                <span>Número telefónico del destinatario <span class="color-red">*</span></span>
              </template>
              </v-text-field>
            </div>
          </v-col>
          <!-- <v-col cols="12" sm="12">
            <div>
              <v-textarea v-model="sComments" label="Comentarios" placeholder="Escribe tus comentarios aquí" dense
                color="#2759A2" class="global-auth-inputs poppins" maxlength="500" persistent-placeholder rows="4"
                auto-grow></v-textarea>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
      <div class="content-buttons-and-message mb-9">
        <div class="content-message">
          <span>
            1.- Un (1) envío gratis al mes dentro de la república mexicana (1 a
            3 días hábiles).
          </span>
          <br />
          <br />
          <span>
            2.- Envíos internacionales pueden generar costos de envío
            adicionales que se podrán visualizar en la próxima factura.</span
          >
          <br />
          <br />
          <span>
            3.-Las solicitudes de tarjetas recibidas después de las 11:00 am
            (CST) Monterrey México serán despachadas al siguiente día
            hábil.</span
          >
        </div>
      </div>
      <div class="content-buttons">
        <v-spacer></v-spacer>
        <v-btn class="button-secondary" @click="onReturn"> Regresar </v-btn>
        <v-btn
          @click="sendRequest"
          class="button-primary"
          :loading="bLoading"
          :disabled="!validationForm"
        >
          Solicitar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutSimsAddForm",
  data() {
    return {
      aPlans: [],
      aCarriers: [],
      aStates: [],
      aCountries: [],
      sCustomPlan: "",
      sCarrierId: "",
      tipoEnvioSeleccionado: "",
      nombreEnvio: "domicilio",
      aTypeSend: [
        {
          text: "Entrega a domicilio",
          value: 1,
        },
        {
          text: "Entrega en sucursal",
          value: 2,
        },
        {
          text: "Buscar Emprenet",
          value: 3,
        },
      ],
      sCountry: "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b",
      sState: "1ab1a61b-aed2-4243-a417-4451760a1019",
      iLargeSIMsQuantityRequired: 0,
      iMediumSIMsQuantityRequired: 0,
      iSmallSIMsQuantityRequired: 0,
      sShippingAddressLine1: "",
      sShippingAddressLine2: "",
      sShippingCity: "",
      sCarrierId: "",
      sShippingPostalCode: "",
      sCustomPlan: "",
      sReceiverFullName: "",
      sReceiverEmail: "",
      sReceiverPhone: "",
      sComments: "",
      bLoading: false,
      bActiveCards: false,
      // sCommentsAtional: "",
      oOptionsSimSize: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 4,
        precision: 0,
      },
    };
  },
  beforeMount() {
    this.getPlans();
    this.getPaqueteria();
    this.getCountries();
    this.getStates();
  },
  methods: {
    sendRequest: function () {
      // this.bLoading = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          iSmallSIMsQuantityRequired: this.iSmallSIMsQuantityRequired === ''? 0 : this.iSmallSIMsQuantityRequired,
          iMediumSIMsQuantityRequired: this.iMediumSIMsQuantityRequired === ''? 0:this.iMediumSIMsQuantityRequired,
          iLargeSIMsQuantityRequired: this.iMediumSIMsQuantityRequired === '' ? 0 :this.iMediumSIMsQuantityRequired,
          sShippingAddressLine1: this.sShippingAddressLine1,
          sShippingAddressLine2: this.sShippingAddressLine2,
          sShippingCity: this.sShippingCity,
          sShippingStateId: this.sState, //sCarrierId
          sShippingPostalCode: this.sShippingPostalCode,
          sCustomPlanId: this.sCustomPlan,
          sReceiverFullName: this.sReceiverFullName,
          sReceiverEmail: this.sReceiverEmail,
          sReceiverPhone: this.sReceiverPhone,
          sComments: this.sComments,
          bActiveSIMCards: this.bActiveCards,
          eDeliveryType: this.tipoEnvioSeleccionado,
          // sCommentsAditional: this.sCommentsAditional,
          // sCarrierId: this.sCarrierId,
        };
      if (this.tipoEnvioSeleccionado === 2) {
        payload.sCarrierId = this.sCarrierId;
      }

      DB.post(`${URI}/sim-requests`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);

          this.onReturn();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message);
        });
    },
    getCountries: function () {
      DB.get(`${URI}/countries`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aCountries = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    selectStates: function () {
      this.sState = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(`${URI}/states`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          sCountryId: this.sCountry,
        },
      })
        .then((response) => {
          this.aStates = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    changeTipoEnvio: function () {
      if (this.tipoEnvioSeleccionado == 1) {
        this.nombreEnvio = "domicilio";
      } else if (this.tipoEnvioSeleccionado == 2) {
        this.nombreEnvio = "sucursal";
      } else {
        this.nombreEnvio = "";
        this.sCountry = "236ce2ad-d8d8-41eb-8451-a0c7c6a7434b";
        this.sState = "36030259-16f9-43c8-856d-2854d7f6111b";
        this.sShippingAddressLine1 = "Lisboa 806, Mirador";
        this.sShippingAddressLine2 = "Emprenet";
        this.sShippingCity = "Monterrey";
        this.sShippingPostalCode = "64070";
      }
    },
    getPlans: function () {
      DB.get(
        `${URI}/enterprises/${this.$store.state.sEnterpriseId}/custom-plans`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.aPlans = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    getPaqueteria: function () {
      DB.get(`${URI}/carriers`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.aCarriers = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onReturn: function () {
      this.$router.push({ name: "sims" });
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    setValidateSIMQuantityRequired() {
      if (this.iSmallSIMsQuantityRequired || this.iMediumSIMsQuantityRequired || this.iLargeSIMsQuantityRequired) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    validationForm: function () {
      return (
        // this.iSmallSIMsQuantityRequired !== "" &&
        // this.iMediumSIMsQuantityRequired !== "" &&
        // this.iLargeSIMsQuantityRequired !== "" &&
        this.setValidateSIMQuantityRequired() &&
        this.sShippingAddressLine1 !== "" &&
        this.sShippingAddressLine2 !== "" &&
        this.sShippingCity !== "" &&
        this.sState !== "" &&
        this.sShippingPostalCode !== "" &&
        this.sCustomPlanId !== "" &&
        this.sReceiverFullName !== "" &&
        this.sReceiverEmail !== "" &&
        this.sReceiverPhone !== "" &&
        this.eDeliveryType !== ""
        // && this.sCommentsAtional !== ""
      );
    },
  },
};
</script>

<style scoped>
.content-active-cards {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f8a130;
  border-left: 5px solid #f8a130;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.text-active-cards {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #f89925;
  opacity: 1;
  margin-bottom: 0;
}

.content-message {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2759a2;
  border-left: 5px solid #2759a2;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #2759a2;
}

.content-buttons,
.content-buttons-and-message {
  display: flex;
  align-items: center;
}

.button-secondary,
.button-primary {
  height: 40px !important;
  padding: 0px 20px !important;
}

.button-primary,
.button-secondary {
  margin-left: 10px;
}

.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.subcontent-text-section {
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
}

@media (max-width: 600px) {
  .content-buttons,
  .content-buttons-and-message {
    display: block;
    width: 100%;
  }

  .button-primary,
  .button-secondary {
    width: 100%;
  }

  .button-primary,
  .button-secondary {
    margin-left: 0px;
    margin-top: 20px;
  }
}
</style>
