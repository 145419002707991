<template>
  <div class="content-login">
    <div class="display-flex align-items-center justify-content-center mb-9">
      <div class="content-image">
        <img
          src="@/assets/images/logo-color.svg"
          alt=""
        >
      </div>
    </div>
    <div class="display-flex align-items-center justify-content-center">
      <p class="txt-title poppins">
        Recuperar contraseña
      </p>
    </div>
    <div class="display-flex align-items-center justify-content-center mb-5">
      <p class="txt-description poppins">
        Ingresa el correo electrónico asociado a tu cuenta <br> EMPRENET para continuar.
      </p>
    </div>
    <div class="content">
      <v-text-field
        v-model="email"
        type="email"
        label="Correo electrónico"
        placeholder="Correo electrónico"
        dense
        color="#2759A2"
        class="global-inputs poppins mb-9"
        persistent-placeholder
        autocomplete=""
        maxlength="85"
        @keypress="preForgotPassword"
      ></v-text-field>
    </div>
    <div class="mt-9">
      <v-btn
        @click="forgotPassword"
        :disabled="!emptyValidation"
        :loading="bLoading"
        elevation="0"
        class="button-primary poppins width-100"
      >
        Enviar recuperación
      </v-btn>
    </div>
    <div class="mt-5">
      <p
        @click="login"
        class="txt-link mb-0"
      >
        Si ya tienes cuenta incia sesión
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutForgotPasswordForm",
  data() {
    return {
      email: "",
      bLoading: false,
    };
  },
  methods: {
    preForgotPassword: function ($event) {
      if ($event.code == "Enter") {
        if (this.emptyValidation) {
          this.forgotPassword();
        } else {
          this.mixError(
            "Favor de validar la información. Los campos no pueden estar vacíos."
          );
        }
      }
    },
    login: function () {
      this.$router
        .push({
          name: "login",
        })
        .catch(() => {});
    },
    forgotPassword: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
      };

      DB.post(`${URI}/auth/password`, payload)
        .then((response) => {
          this.bLoading = false;
          this.mixInfo(response.data.message);
          this.$router
            .push({
              name: "login",
            })
            .catch(() => {});
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message);
        });
    },
  },
  computed: {
    emptyValidation: function () {
      return this.email !== "";
    },
  },
};
</script>

<style scoped>
.txt-title {
  text-align: center;
  font-size: 25px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

.txt-description {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 500;
  margin-bottom: 50px;
}

.content-login {
  width: 80%;
}

.content-image {
  width: 300px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}
</style>