<template>
  <div class="content-header">
    <div @click="onReturn" class="content-header">
      <p class="txt-title poppins mb-0">
        <v-icon color="#283c4d"> mdi-arrow-left-thick </v-icon>
        Solicitud de : {{ oCustomPlan.sName }}
      </p>
    </div>
    <v-spacer />
    <!-- <div>
      <v-chip :color="getColorStatus(sSIMRequestStatusId).sColorBackground"
        :text-color="getColorStatus(sSIMRequestStatusId).sColorText">
        {{ sName }}
      </v-chip>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "LayoutDetailSimsHeader",
  props: {
    itemsSims: {
      type: Object,
    },
  },
  beforeMount() {
    this.getData()
  },
  data() {
    return {
      oItem: {},
      oCustomPlan: {},
      sName: "",
      sSIMRequestStatusId: ""
    };
  },
  methods: {
    onReturn: function () {
      this.$router.push({ name: "sims" });
    },
    getData: function () {

      DB.get(`${URI}/sim-requests/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.oItem = response.data.results;
          this.oCustomPlan = response.data.results.CustomPlan
          this.sName = response.data.results.SIMRequestStatusRecord[0].sName
          this.sSIMRequestStatusId = response.data.results.SIMRequestStatusRecord[0].sSIMRequestStatusId

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message);
        });
    },
    getColorStatus(sStatusID) {
      let oColors = {};
      switch (sStatusID) {
        case "7f595ba0-3275-435f-a48e-4dfaa811702a": //Solicitud enviada
          oColors.sColorText = "#2C9DCE"
          oColors.sColorBackground = "#C0E2F0"
          return oColors;
        case "cd495340-fa3b-4234-89cd-b56c59db318d": //Evaluando
          oColors.sColorText = "#E7C127"
          oColors.sColorBackground = "#F8EDBE"

          return oColors;
        case "5834ff8c-0c17-42ee-afd1-5a84163363e8": //Confirmado
          oColors.sColorText = "#8E4DFF"
          oColors.sColorBackground = "#DDCAFD"
          return oColors;
        case "2a94cfb4-b4f1-420d-a513-c3a368946a48": //Enviado
          oColors.sColorText = "#68BBDF"
          oColors.sColorBackground = "#D2EBF6"

          return oColors;
        case "8ee1565e-ee98-462d-a445-b1d7248dbaed": //Entregado
          oColors.sColorText = "#1EA966"
          oColors.sColorBackground = "#BCE5D1"

          return oColors;
        case "875c3c08-cf5a-424b-988b-e724d33e4e8b": //Cancelado
          oColors.sColorText = "#D77580"
          oColors.sColorBackground = "#F3D6D9"

          return oColors;
        case "e6ea266d-ed35-40f4-9f06-98cffb846c9b": //Reposición
          oColors.sColorText = "#424657"
          oColors.sColorBackground = "#C6C8CD"

          return oColors;

        default:
          oColors.sColorText = "#00000"
          oColors.sColorBackground = "#00000"

          return oColors;
      }
    }
  },
};
</script>

<style scoped>
.content-header {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.content-return:hover {
  opacity: 0.8;
  cursor: pointer;
  width: fit-content;
}

.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  font-size: 35px;
}

.txt-name {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  color: #2759a2;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 600px) {
  .content-header {
    display: block;
    align-items: center;
    width: 100% !important;
  }

  .return-text,
  .icon {
    font-size: 25px;
  }
}
</style>