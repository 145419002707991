<template>
    <div class="content-global">
        <header-component-global sNameHeader="Conexión a API'S" :bBtnAdd="false" sNameBtnAdd="" />
        <v-container fluid>
            <v-row>
                <v-col v-for="(item, index) in aItemApis" :key="index" cols="12" sm="6" md="6" lg="4" xl="3">
                    <v-card class="content-card-global">
                        <v-card-title class="content-title-card poppins font-weight-bold">{{ item.sTitle }}
                        </v-card-title>
                        <v-card-text class="content-text-card">{{ item.sDescription }}</v-card-text>
                        <v-card-text class="content-btns-card">
                            <v-btn @click="downloadDoc(item.sLinkDocument)" class="btn-outline-blue-global"
                                elevation="0">
                                <v-icon> mdi-download</v-icon> Descargar manual
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div class="content-pagination-cards-global ">
                <v-pagination v-model="iPageNumber" :length="iItemsPerPage" ></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            aItemApis: [
                {
                    sTitle: "Envío de SMS",
                    sDescription: `Manual para la conexión a la API de envío de mensaje SMS`,
                    sLinkDocument: "https://emprenet-public-images.s3.amazonaws.com/MANUAL+API+EMPRENET.pdf",
                },
                

            ],
            iCurrentPage: 1,
            iPageNumber: 1,
            iItemsPerPage: 1,
            numPages: 0,
            totalItems: 0,
        }
    },
    methods: {
        downloadDoc(sLinkDocument) {
            window.open(sLinkDocument, "_blank")
        },
        getApis(ItemPerPage, PageNumber) {
            this.
            this.numPages = this.aItemApis.length;
        }
    }
}
</script>

<style scoped >
.content-pagination-cards-global {
    margin-top: 20px;
}

/*#region modo-responsivo */
/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-title-card {
        place-content: center;
    }

    .content-text-card {
        text-align: center;
    }

    .content-btns-card {
        display: flex;
        place-content: center;
    }
}

/*#endregion XS*/
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }
}

/*#endregion SM*/
/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD*/
/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG*/
/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL*/
/*#endregion modo-responsivo */
</style>