<template>
  <div>
    <layout-employee-header @setDialogAdd="setDialogAdd" />
    <layout-employee-searchbar
      @setSearch="setSearch"
      @setNameOrder="setNameOrder"
      @setPlatformAccess="setPlatformAccess"
    />
    <layout-employee-content
      :sSearch="sSearch"
      @setOptions="setOptions"
      :sNameOrder="sNameOrder"
      :bPlatformAccess="bPlatformAccess"
    />
    <layout-employee-add
      :bDialogAdd="bDialogAdd"
      @setDialogAdd="setDialogAdd"
    />
    <!-- general component  -->
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutEmployeeHeader from "@/layouts/Employee/Header.vue";
import LayoutEmployeeContent from "@/layouts/Employee/Content.vue";
import LayoutEmployeeSearchbar from "@/layouts/Employee/Searchbar.vue";
import LayoutEmployeeAdd from "@/layouts/Employee/Add.vue";

export default {
  name: "EmployeeView",
  data() {
    return {
      sSearch: "",
      bDialogAdd: false,
      oOptions: {},
      sNameOrder: undefined,
      bPlatformAccess: undefined,
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setNameOrder: function (val) {
      this.sNameOrder = val;
    },
    setPlatformAccess: function (val) {
      this.bPlatformAccess = val;
    },
  },
  components: {
    LayoutEmployeeHeader,
    LayoutEmployeeContent,
    LayoutEmployeeSearchbar,
    LayoutEmployeeAdd,
  },
};
</script>