<template>
  <v-app class="p-relative">
    <router-view />
    <v-btn @click="setSendMsg"  class="btn-whatsapp" icon>
      <v-icon color="#25D366" size="45px"> mdi-whatsapp </v-icon>
    </v-btn>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      aDontShowBtnWhatsapp: [],
    };
  },
  methods: {
    setSendMsg() {
      window.open("https://api.whatsapp.com/send?phone=528185262396 ")
    }
  },
  computed: {
    bShowBtnWhatsapp() {
      return this.$store.state.bShowBtnWhatsapp;
    },
  },
};
</script>
<style src="@/style.css"></style>

<style>
.p-relative {
  position: relative !important;
}
.btn-whatsapp {
  z-index: 10!important;
  position: fixed !important;
  bottom: 90px;
  right: 10px;
  background-color: white !important;
}
html {
  overflow: auto !important;
}

.v-application {
  font-family: "Poppins", sans-serif !important;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.width-100 {
  width: 100%;
}

.w-space {
  white-space: nowrap !important;
}

.height-100 {
  height: 100%;
}

.display-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: center;
}

.align-items-flex-end {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-scroll {
  overflow: auto;
}

.txt-bold {
  font-weight: bold;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

/* Pagination Styles */
.pagination-styles .theme--light.v-pagination .v-pagination__item--active {
  color: #ffffff !important;
  font-size: 12px !important;
}

.pagination-styles .v-pagination__item,
.pagination-styles .v-pagination__navigation {
  height: 30px;
  width: 30px;
  font-size: 14px !important;
}

.pagination-styles {
  width: 310px;
}

/* input currency styles  */

.global-input-currency .v-input__control .v-input__slot{
  background: transparent !important;

}

.input-currency {
  background: transparent;
  border-radius: 0;
  font-size: 12px;
  width: 100%;
  color: #000000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  letter-spacing: 0px;
  text-align: right;
}


.input-currency:focus-visible {
  outline: none;
}

/* texts dropdown menu  */
.txt-title-down-menu {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: bold;
}

.txt-description-down-menu {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-down-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 40px #3a79bd19;
  border-radius: 14px;
  padding: 10px;
}

.content-down-menu .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0;
}

.content-menu-options .v-list-item__content {
  font-size: 14px;
}

.content-menu-options .v-list-item__icon {
  margin-right: 0px !important;
}

.content-menu-options-dots {
  position: absolute;
  top: 10px;
  right: 10px;
}

.position-relative {
  position: relative;
}

.content-title-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: fit-content;
  padding: 0px 10px;
}

.content-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 0px 15px;
  width: fit-content;
}

.txt-label-global {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #2759a2;
  margin-bottom: 0px !important;
}

.txt-value-global {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #3c3c3c;
  margin-bottom: 0px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

@media (max-width: 500px) {
  .txt-rows-table,
  .txt-pages-table,
  .txt-number-pages-table .v-list-item__content,
  .txt-current-page-table {
    font-size: 12px;
  }

  .content-pagination {
    padding: 15px 0px;
  }

  .content-card-txt-title {
    font-size: 20px;
  }
}

.content-overlay-global .v-overlay__content {
  display: inline-grid;
  place-items: center;
}

.mgl-map-wrapper {
  height: 500px !important;
  position: relative;
  width: 100%;
}

.chk-sim
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  i {
  color: #f8a130 !important;
}

.v-application--wrap {
  min-height: auto !important;
}
</style>
