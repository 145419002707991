import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sToken: localStorage.getItem("sToken")
      ? localStorage.getItem("sToken")
      : "", // Global alerts
    sTypeUser: "",
    sUserId: "",
    sStatus: "",
    sName: "",
    sLastname: "",
    sFullName: "",
    sEnterpriseId: "",
    bManager: false,
    aPermissions: null,

    aMenuGlobal: [
      // {
      //   title: "Principal",
      //   link: "inicio",
      //   icon: "home",
      //   bManager: false,
      //   sCustomerModuleId: "1",
      // },
      // {
      //   title: "Tarjetas SIM globales",
      //   link: "sim-cards",
      //   icon: "sim_card",
      //   bManager: false,
      //   sCustomerModuleId: "a6882b11-6811-4c71-9ffa-449968c18118",
      // },
      // {
      //   title: "Tarjetas SIM locales",
      //   link: "sim-cards-local",
      //   icon: "sim_card",
      //   bManager: false,
      //   sCustomerModuleId: "a6882b11-6811-4c71-9ffa-449968c18118",
      // },
      // {
      //   title: "Solicitudes de tarjetas SIM",
      //   link: "sims",
      //   icon: "add_circle_outline",
      //   bManager: false,
      //   sCustomerModuleId: "7ca28590-682d-4359-91fa-0c05d7efc5b4",
      // },
      // {
      //   title: "Empleados",
      //   link: "employee",
      //   icon: "people_outline",
      //   bManager: false,
      //   sCustomerModuleId: "29c9c299-ff00-4498-afd4-2e069cc4b7c9",
      // },
      // {
      //   title: "Conexión a API's",
      //   link: "apis",
      //   icon: "api",
      //   bManager: false,
      //   sCustomerModuleId: "1",
      // },
      // {
      //   title: "Clientes",
      //   link: "customer",
      //   icon: "mdi mdi-human-greeting-proximity",
      //   bManager: false,
      //   sCustomerModuleId: "56411fa1-20a9-4980-b315-1123e624afa9",
      // },
      // {
      //   title: "Auditoría",
      //   link: "audit",
      //   icon: "fact_check",
      //   bManager: false,
      //   sCustomerModuleId: "957abd40-832d-47e0-a066-4d2fd2c82fde",
      // },
      // {
      //   title: "Prefacturación",
      //   link: "prebilling",
      //   icon: "receipt",
      //   bManager: false,
      //   sCustomerModuleId: "1929e76c-8789-4324-881b-32587b7966bf",
      // },
    ],
    bShowBtnWhatsapp: false,
    aDontShowBtnWhatsapp: ["login", "forgotPassword"],

    //#region GLOBAL PERMISSIONS//

    bShowPromotionsClient: false,
    bAdminPromotionsClient: false,

    bShowSIMCards: false,
    bAdminSIMCards: false,

    bShowSIMCardsSolicitud: false,
    bAdminSIMCardsSolicitud: false,

    bShowPlan: false,
    bAdminPlan: false,

    bShowEmployees: false,
    bAdminEmployees: false,

    bShowEndCustomer: false,
    bAdminEndCustomer: false,

    bShowNotification: false,
    bAdminNotification: false,

    bShowAudit: false,
    bAdminAudit: false,

    bShowPrebilling: false,
    bAdminPrebilling: false,

    bShowDashboard: false,
    bAdminDashboard: false,

    //#endregion GLOBAL PERMISSIONS//

    // #region refs Dashboard contents
    iContentPromotionsDashboard: 0,
    iContentPlansDashboard: 0,
    // #endregion refs Dashboard contents

    sMessageErrorAccess: "Acesso denegado",

    bMenu: false,
    bMenuResponsive: false,
    refresh: false,
    bDialogSendSms: false,

    iCurrentPageGlobal: 1,
    sFilterTypeGlobal: null,
    sSearchGlobal: "",
    iCurrentPageLocal: 1,
    sFilterTypeLocal: null,
    sSearchLocal: "",
    aNotificationGlobal: [],

    bGoToTop: false,
  },
  mutations: {
    setMenuGlobal(state, value) {
      state.aMenuGlobal = [
        {
          title: "Principal",
          link: "inicio",
          icon: "home",
          bManager: false,
          sCustomerModuleId: "1",
        },
        {
          title: "Tarjetas SIM globales",
          link: "sim-cards",
          icon: "sim_card",
          bManager: false,
          sCustomerModuleId: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        {
          title: "Tarjetas SIM locales",
          link: "sim-cards-local",
          icon: "sim_card",
          bManager: false,
          sCustomerModuleId: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        {
          title: "Solicitudes de tarjetas SIM",
          link: "sims",
          icon: "add_circle_outline",
          bManager: false,
          sCustomerModuleId: "7ca28590-682d-4359-91fa-0c05d7efc5b4",
        },
        {
          title: "Empleados",
          link: "employee",
          icon: "people_outline",
          bManager: false,
          sCustomerModuleId: "29c9c299-ff00-4498-afd4-2e069cc4b7c9",
        },
        {
          title: "Conexión a API's",
          link: "apis",
          icon: "api",
          bManager: false,
          sCustomerModuleId: "1",
        },
        {
          title: "Clientes",
          link: "customer",
          icon: "mdi mdi-human-greeting-proximity",
          bManager: false,
          sCustomerModuleId: "56411fa1-20a9-4980-b315-1123e624afa9",
        },
        {
          title: "Auditoría",
          link: "audit",
          icon: "fact_check",
          bManager: false,
          sCustomerModuleId: "957abd40-832d-47e0-a066-4d2fd2c82fde",
        },
        {
          title: "Prefacturación",
          link: "prebilling",
          icon: "receipt",
          bManager: false,
          sCustomerModuleId: "1929e76c-8789-4324-881b-32587b7966bf",
        },
      ];
    },
    setShowBtnWhatsapp(state, value) {
      state.bShowBtnWhatsapp = value;
    },
    setEnterpriseId(state, value) {
      state.sEnterpriseId = value;
    },
    setName(state, value) {
      state.sName = value;
    },
    setFullName(state, value) {
      state.sFullName = value;
      state.sName = value.sName;
      state.sLastname = value.sLastname;
    },
    setToken(state, value) {
      state.sToken = value;
    },
    setTypeUser(state, value) {
      state.sTypeUser = value;
    },
    setUserId(state, value) {
      state.sUserId = value;
    },
    setStatus(state, value) {
      state.sStatus = value;
    },
    setManager(state, value) {
      state.bManager = value;
    },
    setMenu(state, value) {
      state.bMenu = value;
    },
    setMenuResponsive(state, value) {
      state.bMenuResponsive = value;
    },
    refresher(state, item) {
      state.refresh = item;
    },

    setDialogSendSms(state, value) {
      state.bDialogSendSms = value;
    },
    setCurrentPageGlobal(state, value) {
      state.iCurrentPageGlobal = value;
    },
    setFilterTypeGlobal(state, value) {
      state.sFilterTypeGlobal = value;
    },
    setSearchGlobal(state, value) {
      state.sSearchGlobal = value;
    },
    setCurrentPageLocal(state, value) {
      state.iCurrentPageLocal = value;
    },
    setFilterTypeLocal(state, value) {
      state.sFilterTypeLocal = value;
    },
    setSearchLocal(state, value) {
      state.sSearchLocal = value;
    },
    setNotificationGlobal(state, value) {
      state.aNotificationGlobal = value;
    },

    setGoToTop(state, value) {
      state.bGoToTop = value;
    },

    // #region refs Dashboard contents
    setContentPromotionsDashboard(state, value) {
      state.iContentPromotionsDashboard = value;
    },
    setContentPlansDashboard(state, value) {
      state.iContentPlansDashboard = value;
    },
    // #endregion refs Dashboard contents

    // SET PERMISSIONS IN MENU
    setPermissions(state, item) {
      state.aPermissions = item.aUserPermissions;
    },
    setPermissionPromotionsClient(state, item) {
      state.bShowPromotionsClient = item.bShow;
      state.bAdminPromotionsClient = item.bAdmin;
    },
    setPermissionSIMCards(state, item) {
      state.bShowSIMCards = item.bShow;
      state.bAdminSIMCards = item.bAdmin;
    },
    setPermissionPlan(state, item) {
      state.bShowPlan = item.bShow;
      state.bAdminPlan = item.bAdmin;
    },
    setPermissionSIMCardsSolicitud(state, item) {
      state.bShowSIMCardsSolicitud = item.bShow;
      state.bAdminSIMCardsSolicitud = item.bAdmin;
    },
    setPermissionEmployees(state, item) {
      state.bShowEmployees = item.bShow;
      state.bAdminEmployees = item.bAdmin;
    },
    setPermissionEndCustomer(state, item) {
      state.bShowEndCustomer = item.bShow;
      state.bAdminEndCustomer = item.bAdmin;
    },
    setPermissionNotification(state, item) {
      state.bShowNotification = item.bShow;
      state.bAdminNotification = item.bAdmin;
    },
    setPermissionAudit(state, item) {
      state.bShowAudit = item.bShow;
      state.bAdminAudit = item.bAdmin;
    },
    setPermissionPrebilling(state, item) {
      state.bShowPrebilling = item.bShow;
      state.bAdminPrebilling = item.bAdmin;
    },
    setPermissionDashboard(state, item) {
      state.bShowDashboard = item.bShow;
      state.bAdminDashboard = item.bAdmin;
    },
  },
  actions: {
    getPermissionsByUserGlobal({ dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(
          `${URI}/enterprises/${state.sEnterpriseId}/customers/${state.sUserId}/profile`,
          {
            headers: {
              Authorization: "Bearer " + state.sToken,
            },
          }
        )
          .then((response) => {
            let aPermissions = [];
            if (response.data.results.aCustomerPermissions !== null) {
              response.data.results.aCustomerPermissions.push(
                {
                  sCustomerModuleId: "1",
                  sModuleName: "Inicio",
                  aPermissions: [
                    {
                      sPermissionId: "c5ba3a96-36b3-47e0-93e9-f788257a52e3",
                      sPermissionName: "Ver",
                      bBoolean: false,
                    },
                    {
                      sPermissionId: "a0fb6c70-93b3-4681-9ec4-2e58a44df078",
                      sPermissionName: "Administrar",
                      bBoolean: false,
                    },
                  ],
                },
                {
                  sCustomerModuleId: "4",
                  sModuleName: "Perfil",
                  aPermissions: [
                    {
                      sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                      sPermissionName: "Ver",
                      bBoolean: true,
                    },
                    {
                      sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                      sPermissionName: "Administrar",
                      bBoolean: true,
                    },
                  ],
                },
                {
                  sCustomerModuleId: "5",
                  sModuleName: "Notificaciones",
                  aPermissions: [
                    {
                      sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                      sPermissionName: "Ver",
                      bBoolean: true,
                    },
                    {
                      sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                      sPermissionName: "Administrar",
                      bBoolean: true,
                    },
                  ],
                }
              );
              aPermissions = response.data.results.aCustomerPermissions;
            } else {
              aPermissions = null;
            }
            commit("setPermissions", {
              aUserPermissions: aPermissions,
            });
            dispatch("setPermissionsBtnGlobal", aPermissions);
            resolve(aPermissions);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    setPermissionsBtnGlobal({ commit, state }, aPermissions) {
      return new Promise((resolve, reject) => {
        try {
          let bShow = false;
          let bAdmin = false;
          if (aPermissions !== null) {
            commit("setPermissionPromotionsClient", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionSIMCards", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionSIMCardsSolicitud", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionPlan", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionEmployees", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionEndCustomer", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionAudit", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionPrebilling", {
              bShow: false,
              bAdmin: false,
            });
            commit("setPermissionDashboard", {
              bShow: false,
              bAdmin: false,
            });
            aPermissions.forEach((element) => {
              bShow = false;
              bAdmin = false;

              //#region PROMOCIONES CLIENTE//
              if (
                element.sCustomerModuleId ==
                "8a4ba6de-a39b-4aeb-b8ad-00ca06f486f6"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //#region VER//
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //#endregion VER//
                  //#region ADMINISTRAR //
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                  //#endregion ADMINISTRAR //
                });
                commit("setPermissionPromotionsClient", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }

              //#endregion PROMOCIONES CLIENTE//

              //#region TARJETA SIM//
              if (
                element.sCustomerModuleId ==
                "a6882b11-6811-4c71-9ffa-449968c18118"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionSIMCards", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }

              //#endregion TARJETA SIM//

              //#region SOLICITUDES DE TARJETAS SIM//
              if (
                element.sCustomerModuleId ==
                "7ca28590-682d-4359-91fa-0c05d7efc5b4"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionSIMCardsSolicitud", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion SOLICITUDES DE TARJETAS SIM//

              //#region PLANES//
              if (
                element.sCustomerModuleId ==
                "32f0edaa-be6b-415b-a8c2-94820fe1a347"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionPlan", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion PLANES//

              //#region EMPLEADOS//
              if (
                element.sCustomerModuleId ==
                "29c9c299-ff00-4498-afd4-2e069cc4b7c9"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionEmployees", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion EMPLEADOS//

              //#region CLIENTE FINAL //
              if (
                element.sCustomerModuleId ==
                "56411fa1-20a9-4980-b315-1123e624afa9"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionEndCustomer", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion CLIENTE FINAL //

              //#region AUDITORÍAS//
              if (
                element.sCustomerModuleId ==
                "957abd40-832d-47e0-a066-4d2fd2c82fde"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAudit", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion AUDITORÍAS//

              //#region PRE FACTURACIÓN //
              if (
                element.sCustomerModuleId ==
                "1929e76c-8789-4324-881b-32587b7966bf"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionPrebilling", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion PRE FACTURACIÓN //

              //#region DASHBOARD //
              if (
                element.sCustomerModuleId ==
                "265a3875-0f68-47a2-ba5d-2e76700b4c2e"
              ) {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "c5ba3a96-36b3-47e0-93e9-f788257a52e3"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "a0fb6c70-93b3-4681-9ec4-2e58a44df078"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionDashboard", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //#endregion DASHBOARD //
            });
          } else {
            commit("setPermissionPromotionsClient", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionSIMCards", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionSIMCardsSolicitud", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionPlan", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionEmployees", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionEndCustomer", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAudit", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionPrebilling", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionDashboard", {
              bShow: true,
              bAdmin: true,
            });
          }
          resolve("success");
        } catch (error) {
          reject(error);
        }
      });
    },

    //#region NOTIFICATIONS GLOBALS//
    getNotificationGlobal({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          DB.get(`${URI}/notifications`, {
            headers: {
              Authorization: `Bearer ${state.sToken}`,
            },
            params: {
              // iPageNumber: this.iCurrentPage,
              // sSearch: this.sSearch,
              // iItemsPerPage: this.iItemsPerPage,
              // sNameOrder: this.sNameOrder,
              // bPlatformAccess: this.bPlatformAccess,
            },
          })
            .then((response) => {
              commit("setNotificationGlobal", response.data.results);
              resolve("success");
            })
            .catch((error) => {
              console.log(error.response.data.message, error.response.status);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    setNotificationReadGlobal({ dispatch, state }, item) {
      return new Promise((resolve, reject) => {
        try {
          DB.get(`${URI}/notifications/${item.sNotificationId}`, {
            headers: {
              Authorization: `Bearer ${state.sToken}`,
            },
            params: {},
          })
            .then((response) => {
              dispatch("getNotificationGlobal")
                .then((resp) => {
                  if (
                    router.name === item.sCode &&
                    route.params.id === item.sCustomId
                  ) {
                    router.go();
                  } else {
                    router
                      .push({
                        name: item.sCode,
                        params: {
                          id: item.sCustomId,
                        },
                      })
                      .catch(() => {});
                    router.go();
                  }
                })
                .catch((err) => {});
              resolve("success");
            })
            .catch((error) => {
              console.log(error.response.data.message, error.response.status);
            });
          resolve("Success");
        } catch (error) {
          reject(error);
        }
      });
    },

    //#endregion NOTIFICATIONS GLOBALS//

    getPermissionClientGlobal({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          DB.get(
            `${URI}/enterprises/${state.sEnterpriseId}/customers/${state.sUserId}/profile`,
            {
              headers: {
                Authorization: `Bearer ${state.sToken}`,
              },
              params: {},
            }
          )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response.data.message);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  modules: {},
  plugins: [PersistedState()],
});
