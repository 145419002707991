<template>
  <v-dialog v-model="bDialogForgotPassword" persistent :width="screenWidth">
    <div class="content-card-dialog">
      <v-btn class="content-card-button-close" @click="setDialogForgotPassword" icon>
        <v-icon color="#000" size="16px">
          mdi-close
        </v-icon>
      </v-btn>
      <div>
        <p class="content-card-txt-title">Recuperación de contraseña</p>
        <p class="content-card-txt-description">
          ¿Seguro que deseas enviar una solicitud de cambio de contraseña para el empleado? <br />
          Se enviará un correo electrónico a la dirección registrada con los pasos a seguir.
        </p>
      </div>
      <div class="mt-9">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-start">
                <v-btn @click="setDialogForgotPassword" class="button-secondary poppins" elevation="0" width="100%">
                  Regresar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col cols="12" sm="5">
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn :loading="bLoading" @click="setSendRecovery" class="button-primary poppins" elevation="0"
                  width="100%">
                  Enviar solicitud
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutDetailEmployeeDialogForgotPassword",
  props: {
    sEmail: String,
    bDialogForgotPassword: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setDialogForgotPassword: function () {
      this.$emit("setDialogForgotPassword", false);
    },
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.screenWidth = 524 + "px";
      } else {
        this.screenWidth = 100 + "%";
      }
    },
    setSendRecovery() {
      this.bLoading = true;

      const payload = {
        sEmail: this.sEmail,
      };

      DB.post(`${URI}/auth/password`, payload)
        .then((response) => {
          this.mixInfo(response.data.message);
          this.setDialogForgotPassword();
          this.bLoading = false;
          
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message);
        });
    }
  },
};
</script>