<template>
    <div class="mt-4 content-permission mb-9">
        <div class="content-txt-title-section poppins">
            Permisos administrativos
        </div>
        <div class="content-card mt-4">
            <v-container class="pa-0" fluid>
                <!-- HTML HEADER  -->
                <v-row>
                    <v-col class="pb-0" md="6">
                        <div>
                            <p class="txt-table poppins mb-0">Nombre</p>
                        </div>
                    </v-col>
                    <v-col class="pb-0" md="3">
                        <div>
                            <p class="txt-table poppins mb-0">Visualizar</p>
                        </div>
                    </v-col>
                    <v-col class="pb-0" md="3">
                        <div>
                            <p class="txt-table poppins mb-0">Gestionar</p>
                        </div>
                    </v-col>
                </v-row>
                <!-- HTML LINE SEPARATOR -->
                <v-row>
                    <v-col>
                        <div class="border-line"></div>
                    </v-col>
                </v-row>
                <!-- HTML PLANES  -->
                <v-row v-for="(item, i) in aPermissionsEdit" :key="i">
                    <v-col class="pt-0" md="6">
                        <div class="display-flex align-items-center height-100">
                            <p class="txt-table-item poppins mb-0">{{ item.sModuleName }}</p>
                        </div>
                    </v-col>
                    <v-col class="pt-0" md="3">
                        <div style="width: fit-content;">
                            <v-switch v-model="item.bShow" :disabled="bAddOrEdit? false:!editData"
                                @change="validateForm(), validateCheckShow(item)" color="#2759a2" hide-details>
                            </v-switch>
                        </div>
                    </v-col>
                    <v-col class="pt-0" md="3">
                        <div style="width: fit-content;">
                            <v-switch v-model="item.bAdmin" :disabled="bAddOrEdit? false:!editData"
                                @change="validateForm(), validateCheckAdmin(item)" color="#2759a2" hide-details>
                            </v-switch>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
export default {
    name: "LayoutDetailEmployeesPermision",
    props: {
        bAddOrEdit: Boolean,
        aPermissionsDynamic: [],
        editData: Boolean
    },
    data() {
        return {
            aPermissions: [],
            aPermissionsEdit: [],
        };
    },
    beforeMount() {
        this.permissionsGetOrSet();
    },
    methods: {
        permissionsGetOrSet() {
            if (this.bAddOrEdit) {
                this.getPermissions();
            } else {
                this.setPermissions();
            }
        },
        getPermissions() {
            DB.get(`${URI}/modules/customer`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {},
            })
                .then((response) => {
                    this.aPermissions = response.data.results.map((e) => {
                        return {
                            ...e,
                            sModuleId: e.sCustomerModuleId,
                            sModuleName: e.sModuleName,
                            bShow: e.aPermissions[0].bBoolean,
                            sShowPermissionId: e.aPermissions[0].sPermissionId,
                            bAdmin: e.aPermissions[1].bBoolean,
                            sAdminPermissionId: e.aPermissions[1].sPermissionId,
                        };
                    })
                    this.aPermissionsEdit = response.data.results.map((e) => {
                        return {
                            ...e,
                            sModuleId: e.sCustomerModuleId,
                            sModuleName: e.sModuleName,
                            bShow: e.aPermissions[0].bBoolean,
                            sShowPermissionId: e.aPermissions[0].sPermissionId,
                            bAdmin: e.aPermissions[1].bBoolean,
                            sAdminPermissionId: e.aPermissions[1].sPermissionId,
                        };
                    })
                })
                .catch((error) => {
                    this.mixError(error.response.data.message);
                });
        },
        setPermissions() {
            this.aPermissions = this.aPermissionsDynamic.map((e) => {
                return {
                    ...e,
                    sModuleId: e.sCustomerModuleId,
                    sModuleName: e.sModuleName,
                    bShow: e.aPermissions[0].bBoolean,
                    sShowPermissionId: e.aPermissions[0].sPermissionId,
                    bAdmin: e.aPermissions[1].bBoolean,
                    sAdminPermissionId: e.aPermissions[1].sPermissionId,
                };
            });
            this.aPermissionsEdit = this.aPermissionsDynamic.map((e) => {
                return {
                    ...e,
                    sModuleId: e.sCustomerModuleId,
                    sModuleName: e.sModuleName,
                    bShow: e.aPermissions[0].bBoolean,
                    sShowPermissionId: e.aPermissions[0].sPermissionId,
                    bAdmin: e.aPermissions[1].bBoolean,
                    sAdminPermissionId: e.aPermissions[1].sPermissionId,
                };
            });
        },
        addPermissions() {

        },
        validateCheckAdmin(item) {
            if (item.bAdmin == true) {
                item.bShow = true;
                this.validateForm();
            }

        },
        validateCheckShow(item) {
            if (item.bShow == false) {
                item.bAdmin = false;
                this.validateForm();
            }
        },
        validateForm() {
            this.$emit("setPermissions", this.aPermissionsEdit);

            this.$emit("setValdatePermissions", this.bAddOrEdit ? this.validateFormAdd() : this.validateFormEdit());
        },
        validateFormAdd() {
            return (this.aPermissionsEdit.filter((e) => e.bShow !== false || e.bAdmin !== false)
                .length > 0)
        },
        validateFormEdit() {
            if (
                this.aPermissionsEdit.filter(
                    (e) => e.bShow !== false || e.bAdmin !== false
                ).length > 0
            ) {
                for (let i = 0; i < this.aPermissions.length; i++) {
                    if (
                        this.aPermissions[i].bShow !== this.aPermissionsEdit[i].bShow ||
                        this.aPermissions[i].bAdmin !== this.aPermissionsEdit[i].bAdmin
                    ) {
                        return false;
                    }
                }
            } else {
                return true;
            }
        },
    },
    watch: {
        editData() {
            if (this.editData) {
                this.permissionsGetOrSet();
                this.$emit("setPermissions", this.aPermissionsEdit);
            } else {
                this.aPermissionsEdit = this.aPermissions;

                this.$emit("setPermissions", this.aPermissionsEdit);
            }
        },
        aPermissionsDynamic() {
            this.permissionsGetOrSet();
        }
    },
};
</script>

<style scoped>
.content-buttons {
    margin: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content-buttons .button-secondary {
    margin-right: 10px;
}

.content-permission .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}

.border-line {
    border-bottom: 1px solid #a1acc3;
}

.txt-table {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold;
}

.txt-table-item {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 400;
}

.content-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 20px 40px #3a79bd19;
    padding: 20px;
    border-radius: 10px;
}

.content-txt-title-section {
    background: #d3e5ff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    padding: 2px 20px;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #2759a2;
    width: fit-content;
}

@media (max-width: 600px) {
    .content-buttons {
        display: block;
    }

    .button-secondary,
    .button-primary {
        width: 100%;
    }

    .button-primary {
        margin: 20px 0px;
    }
}
</style>