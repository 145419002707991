export const mixs = {
  methods: {
    mixSuccess(e) {
      this.$toast.success(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    mixError(e, sStatus) {
      this.$toast.error(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      if (sStatus === 401) {
        this.$store.commit("setToken", "");
        this.$store.commit("setTypeUser", "");
        this.$store.commit("setUserId", "");
        this.$store.commit("setStatus", "");
        this.$store.commit("setManager", null);
        // remove local storage
        localStorage.removeItem("bManager");
        localStorage.removeItem("bMenu");
        localStorage.removeItem("bMenuResponsive");
        localStorage.removeItem("sStatus");
        localStorage.removeItem("sToken");
        localStorage.removeItem("sTypeUser");
        localStorage.removeItem("sUserId");

        this.$router.push("/login").catch(() => {});
      } else if (sStatus === 403) {
        this.$router.go(-1);
      }
    },
    mixWarning(e) {
      this.$toast.warning(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    mixInfo(e) {
      this.$toast.info(e, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    //#region FUNCIONES GLOBALES //
    getObjectEmptyGlobal(obj) {
      return Object.keys(obj).length > 0;
    },
    setRedirectNotification(sCode) {
      let sName = "";
      switch (sCode) {
        case "SR-DTL":
          sName = "detailSimCards";
          break;

        default:
          break;
      }
      return sName;
    },
    formatMoneyGlobal(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getPermissionsActionsGlobal() {
      switch (this.$route.name) {
        case "sim-cards":
          return this.bAdminSIMCards;
        case "detailSimCards":
          return this.bAdminSIMCards;
        
          case "sim-cards-local":
            return this.bAdminSIMCards;
          case "detailSimCardsLocal":
            return this.bAdminSIMCards;

        case "sims":
          return this.bAdminSIMCardsSolicitud;
        case "addSims":
          return this.bAdminSIMCardsSolicitud;
        case "detailSims":
          return this.bAdminSIMCardsSolicitud;

        case "employee":
          return this.bAdminEmployees;
        case "AddEmployee":
          return this.bAdminEmployees;
        case "detailEmployee":
          return this.bAdminEmployees;

        case "customer":
          return this.bAdminEndCustomer;
        case "AddCustomer":
          return this.bAdminEndCustomer;
        case "detailCustomer":
          return this.bAdminEndCustomer;

        case "inicio":
          return this.bShowDashboard;

        default:
          break;
      }
    },
    getWithoutPermissionsActionsGlobal(sTextDynamic) {
      {
        return sTextDynamic.includes("notificación");
      }
    },
    getColorGlobal: function (iValue, iLimit) {
      let dPorcent = this.getPercentGlobal(iValue, iLimit);
      let color = "gray";
      if (dPorcent < 24.99) {
        color = "green";
      } else if (dPorcent >= 25 && dPorcent < 49.99) {
        color = "yellow";
      } else if (dPorcent >= 50 && dPorcent < 74.99) {
        color = "orange";
      } else {
        color = "red";
      }
      return color;
    },
    getPercentGlobal(iValue, iLimit) {
      let res = 0;

      res = (iValue / iLimit) * 100;

      return res === Infinity ? 100 : isNaN(res) ? 0 : res;
    },
    //#endregion FUNCIONES GLOBALES //
  },
  computed: {
    //#REGION GLOBAL PERMISSIONS//
    bShowPromotionsClient() {
      return this.$store.state.bShowPromotionsClient;
    },
    bAdminPromotionsClient() {
      return this.$store.state.bAdminPromotionsClient;
    },
    bShowSIMCards() {
      return this.$store.state.bShowSIMCards;
    },
    bAdminSIMCards() {
      return this.$store.state.bAdminSIMCards;
    },
    bShowSIMCardsSolicitud() {
      return this.$store.state.bShowSIMCardsSolicitud;
    },
    bAdminSIMCardsSolicitud() {
      return this.$store.state.bAdminSIMCardsSolicitud;
    },
    bShowPlan() {
      return this.$store.state.bShowPlan;
    },
    bAdminPlan() {
      return this.$store.state.bAdminPlan;
    },
    bShowEmployees() {
      return this.$store.state.bShowEmployees;
    },
    bAdminEmployees() {
      return this.$store.state.bAdminEmployees;
    },
    bShowEndCustomer() {
      return this.$store.state.bShowEndCustomer;
    },
    bAdminEndCustomer() {
      return this.$store.state.bAdminEndCustomer;
    },
    bShowNotification() {
      return this.$store.state.bShowNotification;
    },
    bAdminNotification() {
      return this.$store.state.bAdminNotification;
    },
    bShowAudit() {
      return this.$store.state.bShowAudit;
    },
    bAdminAudit() {
      return this.$store.state.bAdminAudit;
    },
    bShowPrebilling() {
      return this.$store.state.bShowPrebilling;
    },
    bAdminPrebilling() {
      return this.$store.state.bAdminPrebilling;
    },

    bShowDashboard() {
      return this.$store.state.bShowDashboard;
    },
    bAdminDashboard() {
      return this.$store.state.bAdminDashboard;
    },

    //#ENDREGION GLOBAL PERMISSIONS/
  },
};
