<template>
  <div>
    <layout-sims-add-header />
    <div class="separator-line mt-5 mb-9" />
    <layout-sims-add-form />
  </div>
</template>

<script>
import LayoutSimsAddHeader from "@/layouts/AddSims/Header.vue";
import LayoutSimsAddForm from "@/layouts/AddSims/Form.vue";

export default {
  name: "AddSimsView",
  components: {
    LayoutSimsAddHeader,
    LayoutSimsAddForm,
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>