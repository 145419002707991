<template>
    <div>
        <div class="content-title-section title-section-dashboard mb-2">Pre facturación</div>
        <div class="content-dashboard content-dashboard-prebilling ">
            <div class="card-subtotal">
                <div class="content-all-subtotal">
                    <div class="content-left">
                        <span>Subtotal del período</span>
                    </div>
                    <v-spacer />
                    <div class="content-right">
                        <span>{{oSubtotal.sDate}}</span>
                    </div>
                </div>
                <div class="divider-horizontal" />
                <div class="content-all-subtotal">
                    <div class="content-left">
                        <span>Consumo</span>
                    </div>
                    <v-spacer />
                    <div class="content-right">
                        <span>${{ oSubtotal ? formatMoneyGlobal(oSubtotal.dItems) : 0 }} MXN</span>
                    </div>
                </div>
                <div class="content-all-subtotal">
                    <div class="content-left">
                        <span>Productos adicionales</span>
                    </div>
                    <v-spacer />
                    <div class="content-right">
                        <span>${{ oSubtotal ? formatMoneyGlobal(oSubtotal.dProducts) : 0 }} MXN</span>
                    </div>
                </div>
                <div class="content-all-subtotal">
                    <div class="content-left">
                        <span>Descuentos</span>
                    </div>
                    <v-spacer />
                    <div class="content-right">
                        <span>${{ oSubtotal ? formatMoneyGlobal(oSubtotal.dDiscount) : 0 }} MXN</span>
                    </div>
                </div>
                <div class="content-all-subtotal">
                    <div class="content-left">
                        <span>Impuestos</span>
                    </div>
                    <v-spacer />
                    <div class="content-right">
                        <span>${{ oSubtotal ? formatMoneyGlobal(oSubtotal.dImpuestos?oSubtotal.dImpuestos:0) : 0 }} MXN</span>
                    </div>
                </div>
                <div class="content-total">
                    <div class="content-left-total">
                        <span>TOTAL</span>
                    </div>
                    <v-spacer />
                    <div class="content-right-total font-bold">
                        <span>${{ oSubtotal ? formatMoneyGlobal(oSubtotal.dTotalAmount) : 0 }} MXN</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    props: {
        oSubtotal: Object,
        oPeriod:Object
    },
    data() {
        return {
            iHeight: 0,
            iHeightBody: 0,
            // oSubtotal: {
            //     sDate: "4/Jul/21 - 4/ago/21",
            //     dItems: 0,
            //     dProducts: 0,
            //     dDiscount: 0,
            //     dImpuestos: 0,
            //     dTotalAmount: 0
            // }
        }
    },
    updated() {
        this.onResize()
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize() {

            this.iHeight = window.innerHeight - this.iContentPlansDashboard - this.iContentPromotionsDashboard - 234;
            this.iHeightBody = window.innerHeight - this.iContentPlansDashboard - this.iContentPromotionsDashboard - 365;

        },
    },
    computed: {
        iContentPromotionsDashboard() {
            return this.$store.state.iContentPromotionsDashboard;
        },
        iContentPlansDashboard() {
            return this.$store.state.iContentPlansDashboard;
        }
    }
}
</script>
<style>
.content-dashboard-prebilling {
    overflow: auto;
}
</style>

<style scoped>
.content-subtotal-overflow {
    overflow: auto;
}

.divider-horizontal {
    height: 1px;
    width: 100%;
    background-color: #949494;
}

.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 170px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.card-subtotal {
    height: 339px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 0px transparent
}

.content-all-subtotal {
    display: flex;
    padding: 17px 20px 17px 20px;
}

.content-total {
    display: flex;
    padding: 15px 10px 15px 10px;
    margin: 5px;
    border-radius: 15px;
    background-color: #D8E5FF;

}

.content-left {
    font-size: 13px;
    font-weight: bold;
}

.content-right {
    font-size: 13px;
}

.content-left-total {
    font-size: 15px;
    font-weight: bold;
}

.content-right-total {
    font-size: 15px;

}

.content-message {
    display: flex;
    align-items: center;
    background: #ED9135 0% 0% no-repeat padding-box;
    border: 1px solid #ED9135;
    border-left: 15px solid #F2AD69;
    border-radius: 5px;
    margin-top: 15px;
    opacity: 1;
    padding: 10px;
    text-align: left;
    font-size: 12px;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.icon-warning {
    color: #FFFFFF;
    margin-right: 10px;
    font-size: 25px;
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .card-subtotal {
        height: 365px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }


    .card-subtotal {
        height: 365px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
        margin-left: 10 !important;
        padding-left: 10 !important;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>