
<template>
    <v-dialog v-model="bDialogAssign" :width="screenWidth" persistent>
        <div class="content-card-dialog">
            <v-btn class="content-card-button-close" @click="closeDialog" icon>
                <v-icon color="#000" size="16px"> mdi-close </v-icon>
            </v-btn>
            <p class="content-card-txt-title poppins mb-5">{{bDetailSimCard? oCustomerEdit.sId === 'N/A'?
            'Asignar SIM':'Desasignar SIM' :sTitle}}</p>
            <div class="content-card-txt-description poppins mt-5 mb-5" v-html="sQuestion" />
            <div v-if="bAssign" class="mt-9">
                <v-select v-model="sClients" :loading="bLoadingSelect" :items="aClients" item-text="sName"
                    item-value="sFinalCustomerId" label="Seleccionar cliente" placeholder="Elegir cliente" dense
                    color="#2759A2" class="global-auth-inputs poppins" clearable persistent-placeholder
                    no-data-text="No hay datos disponibles">
                </v-select>
            </div>
            <div class="mt-5">
                <v-checkbox v-model="bDoubleConfirm" hide-details color="#2759a2" class="mb-1 mt-0">
                    <template v-slot:label>
                        <div class="txt-label-chb-assign mb-0" v-html="sDoubleConfirm" />
                    </template>
                </v-checkbox>
            </div>
            <div class="content-buttons">
                <v-container class="pa-0" fluid>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <div class=" display-flex align-items-center justify-content-flex-start">
                                <v-btn @click="closeDialog" class="button-secondary poppins" elevation="0" width="100%">
                                    Cancelar
                                </v-btn>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="12" sm="5">
                            <div class="display-flex align-items-center justify-content-flex-end">
                                <v-btn @click="send" :loading="bLoading" :disabled="!validationForm"
                                    class="button-primary poppins" elevation="0" width="100%">
                                    {{ bDetailSimCard? sClients? 'Asignar':'Desasignar' :bAssign?
                                    'Asignar':'Desasignar'}}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
        </div>
    </v-dialog>
</template>
  
<script>
export default {
    name: "SendSmsComponent",
    props: {
        bDialogAssignOrigin: Boolean,
        oAssignOrigin: Object,
        oCustomerEdit: Object,
        bDetailSimCard: Boolean
    },
    data() {
        return {
            bDialogAssign: false,
            bAssign: false,
            sTitle: "",
            sQuestion: "",
            bDoubleConfirm: false,
            sDoubleConfirm: "",
            sClients: null,
            aClients: [],
            aICC: [],
            screenWidth: 0,
            bLoading: false,
            bLoadingSelect: true,
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        send: function () {
            this.bLoading = true;
            this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
                if (this.getPermissionsActionsGlobal()) {
                    this.bLoading = false;
                    if (this.bAssign) {
                        if (this.bDetailSimCard) {
                            this.aICC = [];
                            this.aICC.push(this.oCustomerEdit.sICC)
                            if (this.sClients) {
                                this.setAssign();
                            } else {
                                this.setUnassign();
                            }
                        } else {
                            this.setAssign();
                        }
                    } else {
                        this.setUnassign();
                    }

                } else {
                    this.mixError(this.$store.state.sMessageErrorAccess);
                    this.closeDialog();
                    this.$store.commit("refresher", true);


                }
            }).catch((error) => {
                this.mixError(error.message, error.code);
            });
        },
        setAssign() {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
            }, payload = {
                aICC: this.aICC,
                sEnterpriseId: this.sEnterpriseId,
                sFinalCustomerId: this.sClients
            };
            DB.patch(`${URI}/sim-cards`, payload, config)
                .then((response) => {
                    this.bLoading = false;
                    this.mixSuccess(response.data.message);
                    this.closeDialog();
                    this.$store.commit("refresher", true);
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message);
                    this.closeDialog();

                });
        },
        setUnassign() {
            DB.delete(`${URI}/sim-cards`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                data: {
                    aICC: this.aICC,
                    sEnterpriseId: this.sEnterpriseId,
                }
            })
                .then((response) => {
                    this.bLoading = false;
                    this.mixSuccess(response.data.message);
                    this.closeDialog();
                    this.$store.commit("refresher", true);
                })
                .catch((error) => {
                    this.bLoading = false;
                    this.mixError(error.response.data.message);
                    this.closeDialog();

                });
        },
        getClients: function () {
            this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
                if (this.getPermissionsActionsGlobal()) {
                    DB.get(
                        `${URI}/enterprises/${this.$store.state.sEnterpriseId}/final-customers`,
                        {
                            headers: {
                                Authorization: `Bearer ${this.$store.state.sToken}`,
                            },
                            params: {

                            },
                        }
                    )
                        .then((response) => {
                            this.aClients = response.data.results
                            if (this.bDetailSimCard) {
                                this.aClients.unshift({
                                    sName: "Sin asignación",
                                    sFinalCustomerId: null,
                                });
                                if (this.oCustomerEdit.sId == 'N/A') {
                                    this.sClients = null
                                } else {
                                    this.sClients = this.oCustomerEdit.sId
                                }
                            }
                            this.bLoadingSelect = false;
                        })
                        .catch((error) => {
                            this.mixError(error.response.data.message, error.response.status);
                        });
                } else {
                    this.mixError(this.$store.state.sMessageErrorAccess);
                    this.closeDialog();
                    this.$store.commit("refresher", true);


                }
            }).catch((error) => {
                this.mixError(error.message, error.code);
            });
        },
        handleResize: function () {
            if (window.innerWidth > 600) {
                this.screenWidth = 420 + "px";
            } else {
                this.screenWidth = 100 + "%";
            }
        },
        closeDialog: function () {
            this.$emit("setDialogAssign");
            this.bDialogAssign = false;
            this.sTitle = "";
            this.sQuestion = "";
            this.sDoubleConfirm = "";
            this.bDoubleConfirm = "";
            this.sClients = null;
        },
    },
    computed: {
        validationForm: function () {
            if (this.bAssign) {
                if (this.bDetailSimCard) {
                    return this.bDoubleConfirm;
                } else {
                    return this.sClients !== null && this.bDoubleConfirm;
                }
            } else {
                return this.bDoubleConfirm;
            }
        },
        sEnterpriseId() {
            return this.$store.state.sEnterpriseId;
        }
    },
    watch: {
        bDialogAssignOrigin: function () {
            if (this.bDialogAssignOrigin) {
                this.getClients();
                this.bDialogAssign = this.bDialogAssignOrigin;
                this.bAssign = this.oAssignOrigin.bAssign;
                this.sTitle = this.oAssignOrigin.sTitle;
                this.sQuestion = this.oAssignOrigin.sQuestion;
                this.sDoubleConfirm = this.oAssignOrigin.sDoubleConfirm;
                this.aICC = this.oAssignOrigin.aICC;
            }
        },
    },
};
</script>
  
<style scoped>
.content-inputs {
    margin-top: 50px;
}

.content-buttons {
    margin-top: 25px;
}

.content-card-txt-description {
    font-size: 13px;
}

.txt-label-chb-assign {
    font-size: 11px;
    color: #A1ACC3 !important;
}
</style>