<template>
  <div>
    <layout-detail-sims-header :itemsSims="itemsSims" />
    <div class="separator-line mt-5 mb-9" />
    <layout-detail-sims-form />

    <v-overlay class="content-overlay-global" :value="bLoadingSims">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutDetailSimsHeader from "@/layouts/DetailSims/Header.vue";
import LayoutDetailSimsForm from "@/layouts/DetailSims/Form.vue";

export default {
  name: "DetailSimsView",
  data() {
    return {
      itemsSims: {},
      bLoadingSims: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData: function () {
      this.bLoadingSims = true;

      DB.get(`${URI}/sim-requests/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.bLoadingSims = false;
          this.itemsSims = response.data.results;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoadingSims = false;
          this.mixError(error.response.data.message);
        });
    },
  },
  components: {
    LayoutDetailSimsHeader,
    LayoutDetailSimsForm,
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>