var reroute = {
  /**
   *
   * @param {UserPerrmisionArray} permissions
   *
   */
  redirectUser: function (permissions) {

    var permission = permissions.filter((e) => e.sCustomerModuleId !== "32f0edaa-be6b-415b-a8c2-94820fe1a347" || e.sCustomerModuleId !== "8a4ba6de-a39b-4aeb-b8ad-00ca06f486f6");
    var firstRoute = this.resolveRoutes(permission[0].sCustomerModuleId);
    return firstRoute;
  },
  resolveRoutes: function (permission) {
    switch (permission) {
      case "1":
        return "inicio";
      case "a6882b11-6811-4c71-9ffa-449968c18118":
        return "sim-cards";
      case "7ca28590-682d-4359-91fa-0c05d7efc5b4":
        return "sims";
      case "29c9c299-ff00-4498-afd4-2e069cc4b7c9":
        return "employee";
      case "4":
        return "profile";
      case "5":
        return "notifications";
      case "56411fa1-20a9-4980-b315-1123e624afa9":
        return "customer";
      case "957abd40-832d-47e0-a066-4d2fd2c82fde":
        return "audit";
      case "1929e76c-8789-4324-881b-32587b7966bf":
        return "prebilling";
      default:
        return "inicio";
    }
  },
};

export default reroute;
