<template>
  <div class="content-tabs-cards">
    <v-tabs-items v-model="tab" :touchless="true">
      <v-tab-item class="mt-5" v-for="(item, index) in tabs" :key="index">
        <KeepAlive>
          <component
            @setFixedInfo="setFixedInfo"
            @setLteEnabled="setLteEnabled"
            @setCustomer="setCustomer"
            :tab="stab"
            :is="item.component"
          />
        </KeepAlive>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import LayoutTabsAuditoria from "./Tabs/auditoria.vue";
import LayoutTabsCicloVida from "./Tabs/cicloVida.vue";
import LayoutTabsControlConsumo from "./Tabs/controlConsumo.vue";
import LayoutTabsInformacion from "./Tabs/infromacion.vue";
import LayoutTabsLocalizacion from "./Tabs/localizacion.vue";
import LayoutTabsPresencia from "./Tabs/presencia.vue";

export default {
  name: "LayoutDetailSimCardsTabs",
  props: {
    tab: Number,
  },
  data() {
    return {
      tabs: [
        { text: "Información de la SIM", component: "LayoutTabsInformacion" },
        { text: "Estado de ciclo de vida", component: "LayoutTabsCicloVida" },
        { text: "Presencia", component: "LayoutTabsPresencia" },
        {
          text: "Control de consumo de tráfico",
          component: "LayoutTabsControlConsumo",
        },
        { text: "Localización", component: "LayoutTabsLocalizacion" },
        // { text: "LOG de auditoría", component: "LayoutTabsAuditoria" },
      ],
      stab: null,
    };
  },
  methods: {
    setFixedInfo(oFixedInfo) {
      this.$emit("setFixedInfoDetail", oFixedInfo);
    },
    setLteEnabled(bLteEnabled) {
      this.$emit("setLteEnabledDetail", bLteEnabled);
    },
    setCustomer(oCustomer){
      this.$emit("setCustomer",oCustomer)
    }
  },
  components: {
    // LayoutTabsAuditoria,
    LayoutTabsCicloVida,
    LayoutTabsControlConsumo,
    LayoutTabsInformacion,
    LayoutTabsLocalizacion,
    LayoutTabsPresencia,
  },
  watch: {
    tab() {
      this.stab = this.tab;
    },
  },
};
</script>

<style>
.content-tabs-cards .v-tabs-slider-wrapper {
  height: 5px !important;
}

.content-tabs-cards .v-tab {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a1acc3 !important;
  opacity: 1;
  text-transform: initial;
}

.content-tabs-cards .v-tab--active {
  color: #2759a2 !important;
}

.content-tabs-cards .theme--light.v-tabs-items {
  background-color: transparent;
}
</style>

<style scoped>
.px-slider {
  background: transparent linear-gradient(268deg, #79defc 0%, #2759a2 100%) 0%
    0% no-repeat padding-box;
}
</style>