<template>
  <div class="content-option-buttons">
    <div @click="onReturn" class="content-header">
      <p class="txt-title poppins mb-0">
        <v-icon color="#283c4d"> mdi-arrow-left-thick </v-icon>
        Detalle de cliente
      </p>
    </div>

  </div>
</template>

<script>

export default {
  name: "LayoutDetailSimCardsHeader",
  props: {
    bLteEnabled: Boolean,
    oFixedInfo: Object,
    bTab: Number,
  },
  data() {
    return {
      bDialogResetSim: false,
      bLoading: false,
      bLoadingTest: false,
      bLoadingActive: false,
      bLoadingDesactived: false,
      screenWidth: 0,
      chb4g: false,
      chb3g2g: false,
      bDialogLaunchTest: false,
      screenWidthDialogLaunchTest: 0,
      itemTest: {},
      oFixedInfoEdit: {},
      oChangeStatusOptions: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 600) {
        this.screenWidth = 420 + "px";
        this.screenWidthDialogLaunchTest = 420 + "px";
      } else {
        this.screenWidth = 100 + "%";
        this.screenWidthDialogLaunchTest = 100 + "%";
      }
    },
    onReturn: function () {
      this.$router.push({ name: "customer" });
    },
  },
};
</script>

<style scoped>
.content-return:hover {
  opacity: 0.8;
  cursor: pointer;
  width: fit-content;
}

.sizeIcon {
  font-size: 35px;
}

.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-name {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  color: #2759a2;
}

.content-checkboxes-4g-3g2g {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 60px;
  padding-right: 60px;
}

.txt-label-chb-4g-3g2g {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-label-test {
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
  margin-left: 10px;
}

.text-value-test {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0px;
  color: #000000;
  opacity: 1;
  letter-spacing: 0px;
}

.content-value-test {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.content-buttons {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
}

.content-btn-close {
  width: 200px;
}

.content-option-buttons {
  display: flex !important;
  align-items: center;
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-option-buttons {
    display: block !important;
    align-items: center;
    width: 100%;
  }

  .content-buttons {
    display: block;
    justify-content: center;
    text-align: -webkit-center;
  }

  .global-btn-mobile {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-option-buttons {
    display: block !important;
    align-items: center;
    width: 100%;
  }

  .content-buttons {
    display: block;
    justify-content: center;
    text-align-last: center;
    text-align: -webkit-center;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>