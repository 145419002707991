<template>
  <div class="content-chart-global">
    <apexchart
      :id="idChartDinamyc"
      class="chart-component"
      :type="typeChart"
      height="250"
      :locale="en"
      :options="apexChartOptions"
      :series="apexChartSeries"
    ></apexchart>
  </div>
</template>
<script>
export default {
  props: {
    idChartDinamyc: String,
    typeChart: String,
    apexChartOptions: Object,
    apexChartSeries: Array,
  },
  data() {
    return {
      en: [
        {
          name: "sp",
          options: {
            months: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            shortMonths: [
              "Ene",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Oct",
              "Nov",
              "Dic",
            ],
            days: [
              "Lunes",
              "Martes",
              "Miércoles",
              "Jueves",
              "Viernes",
              "Sabado",
              "Domingo",
            ],
            shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
          },
        },
      ],
    };
  },
  mounted() {
    this.chartConstructor();
  },
  methods: {
    chartConstructor() {
      //   var chart = new ApexCharts(
      //     document.querySelector("#" + this.idChartDinamyc),
      //     this.options
      //   );
      //   chart.render();
    },
  },
};
</script>
<style >
.content-chart-global {
  padding: 30px 10px 0px 10px !important;
}
</style>