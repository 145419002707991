import { render, staticRenderFns } from "./cicloVida.vue?vue&type=template&id=f6bb4de6&scoped=true&"
import script from "./cicloVida.vue?vue&type=script&lang=js&"
export * from "./cicloVida.vue?vue&type=script&lang=js&"
import style0 from "./cicloVida.vue?vue&type=style&index=0&id=f6bb4de6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6bb4de6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VCombobox,VContainer,VDatePicker,VMenu,VRow,VSpacer})
