<template>
  <div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <div class="content-card-dialog d-flex align-center card-plan">
            <div class="ml-3">
              <p class="txt-number mb-0">{{oFixedInfo.sICC}}</p>
              <p v-if="oCustomer.sName !== 'N/A'" class="txt-client-assign">{{oCustomer.sName}}</p>
              <p v-else class="txt-client-assign-empty txt-empty-global">Tarjeta no asignada</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="2">
          <div class="content-card-dialog card-plan">
            <div class="width-100">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mb-2
                ">
                <img width="40px" src="@/assets/images/tarjetasSim/activo.svg" />
              </div>
              <p class="txt-title-plan">Estado</p>
              <p class="txt-description-plan">{{oFixedInfo.sStatus}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="2">
          <div class="content-card-dialog card-plan">
            <div class="width-100">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mb-2
                ">
                <img width="40px" src="@/assets/images/tarjetasSim/nacional.svg" />
              </div>
              <p class="txt-title-plan">Plan</p>
              <p class="txt-description-plan">{{oFixedInfo.sDataPlan}}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="2">
          <div class="content-card-dialog card-plan">
            <div class="width-100">
              <div class="
                  display-flex
                  align-items-center
                  justify-content-center
                  mb-2
                ">
                <img width="40px" src="@/assets/images/tarjetasSim/mb.svg" />
              </div>
              <p class="txt-title-plan">Consumo mensual</p>
              <p class="txt-description-plan">{{oFixedInfo.iConsumptionMonthly}} MB</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailSimCardsDashboard",
  props: {
    oFixedInfo: Object,
    oCustomer:Object
  },
  data() {
    return {};
  },
  watch: {
    oFixedInfo() {
    },
  },
};
</script>

<style scoped>
.card-plan {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 10px 5px 10px 5px !important;
}

.txt-title-plan {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a1acc3;
  opacity: 1;
  margin-bottom: 0;
}

.txt-description-plan {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  margin-bottom: 0;
  font-weight: 600;
}

.txt-number {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
}

.txt-name {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-client-assign {
  margin-bottom: 0px;
  font-size: 14px;
  color: #000000;
}

@media (max-width: 600px) {
  .txt-number {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .txt-name {
    font-size: 14px;
  }
}
</style>