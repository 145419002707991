<template>
  <div class="content-buttons mb-5">
    <v-btn
      @click="onReturn"
      class="button-secondary"
      elevation="0"
    >
      Regresar
    </v-btn>
    <v-btn
      @click="add"
      :disabled="!validationForm"
      class="button-primary"
      elevation="0"
      :loading="bLoading"
    >
      Añadir
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutAddEmployeeButtons",
  props: {
    aPermissions: {
      type: Array,
    },
    sPublicName: {
      type: String,
    },
    sEmail: {
      type: String,
    },
    sContactFullName: {
      type: String,
    },
    sCountryCallingCode: {
      type: String,
    },
    sAreaCallingCode: {
      type: String,
    },
    sPhoneNumber: {
      type: String,
    },
    sPhoneExtension: {
      type: String,
    },
  },
  data() {
    return {
      bLoading: false,
    };
  },
  methods: {
    onReturn: function () {
      this.$router.push({
        name: "customer",
      });
    },
    add: function () {
      this.bLoading = true;

      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {
          sPublicName: this.sPublicName,
          sEmail: this.sEmail,
          sContactFullName: this.sContactFullName,

          sCountryCallingCode: this.sCountryCallingCode,
          sAreaCallingCode: this.sAreaCallingCode,
          sPhoneNumber:this.sPhoneNumber,
          sPhoneExtension: this.sPhoneExtension,
          // aCustomerPermissions: [
          //   {
          //     sCustomerModuleId: this.aPermissions[0].sCustomerModuleId,
          //     aPermissions: [
          //       {
          //         sPermissionId:
          //           this.aPermissions[0].aPermissions[0].sPermissionId,
          //         bBoolean: this.aPermissions[0].aPermissions[0].bBoolean,
          //       },
          //       {
          //         sPermissionId:
          //           this.aPermissions[0].aPermissions[1].sPermissionId,
          //         bBoolean: this.aPermissions[0].aPermissions[1].bBoolean,
          //       },
          //     ],
          //   },
          //   {
          //     sCustomerModuleId: this.aPermissions[1].sCustomerModuleId,
          //     aPermissions: [
          //       {
          //         sPermissionId:
          //           this.aPermissions[1].aPermissions[0].sPermissionId,
          //         bBoolean: this.aPermissions[1].aPermissions[0].bBoolean,
          //       },
          //       {
          //         sPermissionId:
          //           this.aPermissions[1].aPermissions[1].sPermissionId,
          //         bBoolean: this.aPermissions[1].aPermissions[1].bBoolean,
          //       },
          //     ],
          //   },
          //   {
          //     sCustomerModuleId: this.aPermissions[2].sCustomerModuleId,
          //     aPermissions: [
          //       {
          //         sPermissionId:
          //           this.aPermissions[2].aPermissions[0].sPermissionId,
          //         bBoolean: this.aPermissions[2].aPermissions[0].bBoolean,
          //       },
          //       {
          //         sPermissionId:
          //           this.aPermissions[2].aPermissions[1].sPermissionId,
          //         bBoolean: this.aPermissions[2].aPermissions[1].bBoolean,
          //       },
          //     ],
          //   },
          //   {
          //     sCustomerModuleId: this.aPermissions[3].sCustomerModuleId,
          //     aPermissions: [
          //       {
          //         sPermissionId:
          //           this.aPermissions[3].aPermissions[0].sPermissionId,
          //         bBoolean: this.aPermissions[3].aPermissions[0].bBoolean,
          //       },
          //       {
          //         sPermissionId:
          //           this.aPermissions[3].aPermissions[1].sPermissionId,
          //         bBoolean: this.aPermissions[3].aPermissions[1].bBoolean,
          //       },
          //     ],
          //   },
          // ],
        };

      DB.post(
        `${URI}/enterprises/${this.$store.state.sEnterpriseId}/final-customers`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.onReturn();
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message);
        });
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.sPublicName !== "" &&
        this.sEmail !== "" &&
        this.sContactFullName !== "" &&
        this.sCountryCallingCode !== "" &&
        this.sAreaCallingCode !== "" &&
        this.sPhoneNumber !== "" 
      );
    },
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-buttons .button-primary,
.content-buttons .button-secondary {
  height: 40px;
  font-size: 16px !important;
  padding: 0px 30px;
}

.content-buttons .button-primary {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .content-buttons .button-primary,
  .content-buttons .button-secondary {
    width: 100%;
  }

  .content-buttons .button-primary {
    margin-left: 0px;
    margin-top: 20px;
  }
}
</style>