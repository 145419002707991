<template>
  <div
    class="mt-9"
    :style="{height: `${screenHeight}px`}"
  >
    <div
      class="content-image display-flex align-items-center justify-content-flex-end"
      :style="{ backgroundImage: backgroundImage }"
    >
      <div class="content-text-welcome">
        <p class="txt-title poppins">La sección principal se encuentra deshabilitado <br> por el momento</p>
        <p class="txt-description poppins">Te invitamos a ver tus solicitudes de tarjetas sim <br> para mantenerte al día con tus clientes</p>
        <div class="display-flex align-items-center justify-content-flex-end mb-5">
          <v-btn
            class="button-primary poppins"
            elevation="0"
            @click="$router.push({ name: 'sims' }).catch((err)=>{})"
          >
            Ver solicitudes de tarjetas SIM
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutDashboardContent",
  data() {
    return {
      backgroundImage: `url(${require("@/assets/images/dashboard-placeholder.svg")})`,
      screenHeight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.handleResize();
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 180;
    },
  },
};
</script>

<style scoped>
.txt-title {
  text-align: right;
  font-size: 25px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}

.txt-description {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a5aec3;
  opacity: 1;
}

.content-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 960px) {
  .txt-title {
    font-size: 20px;
  }

  .txt-description {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .content-text-welcome {
    margin-top: -150px;
  }
}
</style>