<template>
  <div class="sidebar-content height-100">
    <div v-for="(item, index) in filteredItems" :key="index">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="!bMenu ? attrs : ''"
            v-on="!bMenu ? on : ''"
            :class="
              selected == item.link
                ? 'content-sidebar-option-active display-flex align-items-center'
                : 'content-sidebar-option-inactive display-flex align-items-center'
            "
            :style="!bMenu ? { justifyContent: 'center' } : {}"
            @click="toGoView(item.link)"
            v-if="checkAdmin(item)"
          >
            <img
              v-if="item.title === 'Solicitudes de tarjetas SIM'"
              class="img-icon-customer"
              src="@/assets/images/clientes.svg"
            />
            <span
              v-else-if="item.title === 'Clientes'"
              class="icon-customer-sidebar-global"
              :class="item.icon"
            />
            <span v-else class="material-icons icons-menu-global">
              {{ item.icon }}
            </span>

            <p v-if="bMenu" class="poppins mb-0 ml-1">
              {{ item.title }}
            </p>
          </div>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",
  data() {
    return {
      filteredItems: [],
      selected: "inicio",
    };
  },
  beforeMount() {
    this.selected = this.$route.path.split("/")[2];
    this.changePermissions();
  },
  methods: {
    checkAdmin(item) {
      if (this.$store.state.bManager == true) {
        return true;
      } else if (!item.bManager) {
        return true;
      } else if (item.bManager) {
        return false;
      }
    },
    toGoView(sName) {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (sName === this.$route.name) {
            this.$store.commit("refresher", !this.refresh);
          } else {
            this.$router.push({ name: sName }).catch((err) => {});
          }
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    changePermissions() {
      this.$store
        .dispatch("setPermissionsBtnGlobal", this.userPermissions)
        .then((resp) => {
          this.filteredItems = this.aMenuGlobal.filter((routeOption) => {
            if (this.userPermissions === null) {
              return routeOption;
            } else {
              var exp = this.userPermissions;
              let arr = "";
              exp.forEach((e) => {
                if (e.sCustomerModuleId === routeOption.sCustomerModuleId) {
                  arr = e;
                }
              });
              return arr;
            }
          });
        })
        .catch((err) => {
          this.mixError(err);
        });
    },
  },
  computed: {
    aMenuGlobal() {
      return this.$store.state.aMenuGlobal;
    },
    bMenu: function () {
      return this.$store.state.bMenu;
    },
    bManager: function () {
      return this.$store.state.bManager;
    },
    userPermissions() {
      return this.$store.state.aPermissions;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    $route: function () {
      this.selected = this.$route.path.split("/")[2];
    },
    userPermissions() {
      this.changePermissions();
    },
    aMenuGlobal: {
      handler(newVal) {
        localStorage.setItem("aMenuGlobal", JSON.stringify(newVal));
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.img-icon-customer {
  width: 22px;
}
.content-sidebar-option-active {
  background: #ffffff40 0% 0% no-repeat padding-box;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  height: 50px !important;
}

.content-sidebar-option-inactive {
  background-color: transparent;
  border-radius: 14px;
  padding: 10px;
  margin-bottom: 15px;
  height: 50px !important;
}

.content-sidebar-option-inactive:hover {
  background: #ffffff40 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.content-sidebar-option-active p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.content-sidebar-option-inactive p {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.sidebar-content {
  /* background: radial-gradient(at right top, #142456, #4697cf); */
  background: url("../assets/images/background-sidebar.png");
  border-radius: 20px;
  padding: 15px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 9;
  position: relative;
}
</style>
