<template>
    <div>
        <v-row>
            <v-col cols="12" class="ml-0 mr-0 mt-3">
                <div class="information-title">
                    Productos adicionales
                </div>
            </v-col>
            <v-col cols="12" class="ml-0 mr-0">

                <v-data-table v-if="!bMobile" :mobile-breakpoint="0" :items-per-page="-1" :headers="aHeader" :items="aAdditionalProduct"
                    :hide-default-footer="true" no-data-text="No hay información" class="elevation-1">
                    <template v-slot:[`item.dQuantity`]="{ item }">
                        <span>
                            {{formatMoneyGlobal(item.dQuantity)}}
                        </span>
                    </template>
                    <template v-slot:[`item.dUnitPrice`]="{ item }">
                        <span>
                            ${{formatMoneyGlobal(item.dUnitPrice)}} MXN
                        </span>
                    </template>
                    <template v-slot:[`item.sTotal`]="{ item }">
                        <span>
                            ${{formatMoneyGlobal(item.sTotal)}} MXN
                        </span>
                    </template>
                </v-data-table>
                <div v-else>
                    <v-container class="pa-0" fluid>
                        <v-row>
                            <v-col v-for="(item, index) in aAdditionalProduct" :key="index" cols="12" sm="6">
                                <div class="content-card-responsive  position-relative">
                                    <div class="height-100">
                                        <div>
                                            <div class="content-first-row-card">
                                                <div class="content-text-title">
                                                    <p class="txt-item-table-card-title poppins mb-3">
                                                        {{ item.sFolio }}
                                                    </p>
                                                </div>
                                                <div class="content-menu-actions">
                                                    <v-menu offset-y content-class="content-menu-options" attach left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <div class="content-actions">

                                                                <div v-bind="attrs" v-on="on"
                                                                    class="content-menu-options-dots">
                                                                    <v-icon color="#000" size="20px">
                                                                        mdi-dots-vertical
                                                                    </v-icon>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div class="circle-content-options">
                                                            <div>
                                                                <v-list dense>
                                                                    <v-list-item link
                                                                        @click="fillItemEdit(item), setDialogAddOrEditAdditionalProduct(false)">
                                                                        <v-list-item-icon>
                                                                            <v-icon color="#000" size="14px">
                                                                                mdi-pencil
                                                                            </v-icon>
                                                                        </v-list-item-icon>
                                                                        <v-list-item-content class="poppins">
                                                                            Editar
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </div>
                                                        </div>
                                                    </v-menu>
                                                </div>
                                            </div>

                                            <div class="flex-wrap display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Cantidad</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0">{{
                                                            formatMoneyGlobal(item.dQuantity)
                                                    }}</p>
                                                </div>

                                            </div>
                                            <div class="flex-wrap display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Precio</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0"> $ {{
                                                            formatMoneyGlobal(item.dUnitPrice)
                                                    }} MXN</p>
                                                </div>
                                            </div>
                                            <div class="flex-wrap display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Importe</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0">${{
                                                            formatMoneyGlobal(item.sTotal)
                                                    }} MXN</p>
                                                </div>
                                            </div>
                                            <div class=" display-flex align-items-center">
                                                <div class="content-left">
                                                    <p class="px-txt-card-title mb-0 mr-2">Descripción</p>
                                                </div>
                                                <div class="content-right">
                                                    <p class="px-txt-card-item mb-0">
                                                        {{ item.sDescription }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <!--#region CONTENIDO DE BOTONES -->
                                <div class="content-btns-global">
                                    <div class="content-btn-edit">
                                        <v-btn class="button-secondary" width="100%"
                                            @click="setDialogAddOrEditAdditionalProduct(true)">
                                            Añadir producto
                                        </v-btn>

                                    </div>
                                </div>
                                <!--#endregion CONTENIDO DE BOTONES -->
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
export default {
    props: {
        aAdditionalProduct: Array
    },
    data() {
        return {
            aHeader: [
                {
                    text: "Folio",
                    align: "start",
                    sortable: false,
                    value: "sFolio",
                    class: "txt-color-black-global"
                },
                {
                    text: "Descripción",
                    sortable: false,
                    value: "sDescription",
                    class: "txt-color-black-global"
                },
                {
                    text: "Cantidad",
                    sortable: false,
                    value: "dQuantity",
                    class: "txt-color-black-global"
                },
                {
                    text: "Precio",
                    sortable: false,
                    value: "dUnitPrice",
                    class: "txt-color-black-global"
                },
                {
                    text: "Importe",
                    sortable: false,
                    value: "sTotal",
                    class: "txt-color-black-global"
                },
                // {
                //     text: "Acciones",
                //     sortable: false,
                //     value: "sActions",
                //     class: "w-120 text-center txt-color-black-global"
                // },
            ],
            sDescription: "",
            sDescriptionEdit: "",
            dQuantity: 0,
            dQuantityEdit: 0,
            iPrice: 0,
            iPriceEdit: 0,
            bAddAdditionalProducts: false,
            bMobile: false,
            bDialogAddOrEditAdditionalProduct: false,
            bAddOrEdit: false,
            oItem: {},
        };
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            if (window.innerWidth > 959.99) {
                this.bMobile = false;
                this.bDialogAddOrEditAdditionalProduct = false;

            }
            else {
                this.bMobile = true;
                this.bAddAdditionalProducts = false;
                for (let i = 0; i < this.aAdditionalProduct.length; i++) {
                    this.aAdditionalProduct[i].bEdit = false;
                }
                this.iPrice = 0;
                this.dQuantity = 0;
            }
        },
        changeQuantity(value) {
            if (value !== "") {
                this.dQuantity = parseFloat(value);
            }
            else {
                this.dQuantity = 0;
            }
        },
        changeQuantityEdit(value) {
            if (value !== "") {
                this.dQuantityEdit = parseFloat(value);
            }
            else {
                this.dQuantityEdit = 0;
            }
        },
        changePrice(value) {
            if (value !== "") {
                this.iPrice = parseFloat(value);
            }
            else {
                this.iPrice = 0;
            }
        },
        changePriceEdit(value) {
            if (value !== "") {
                this.iPriceEdit = parseFloat(value);
            }
            else {
                this.iPriceEdit = 0;
            }

        },
        setEditItem(item) {
            this.bAddAdditionalProducts = false;
            for (let i = 0; i < this.aAdditionalProduct.length; i++) {
                this.aAdditionalProduct[i].bEdit = false;
            }
            item.bEdit = true;
            this.sDescriptionEdit = item.sDescription;
            this.dQuantityEdit = item.dQuantity;
            this.iPriceEdit = item.iPrice;

        },
        editAdditionalProducts(item) {
            for (let i = 0; i < this.aAdditionalProduct.length; i++) {
                const element = this.aAdditionalProduct[i];
            }
        },
        setAddItem() {
            for (let i = 0; i < this.aAdditionalProduct.length; i++) {
                this.aAdditionalProduct[i].bEdit = false;
            }
            this.bAddAdditionalProducts = true;
        },
        addAdditionalProducts() {
        },
        fillItemEdit(item) {
            this.oItem = item;
        },
        setDialogAddOrEditAdditionalProduct(bAddOrEdit) {
            this.bAddOrEdit = bAddOrEdit;
            this.bDialogAddOrEditAdditionalProduct = !this.bDialogAddOrEditAdditionalProduct;
        }
    },
    computed: {
        validationForm: function () {
            return (this.sDescription !== "" &&
                this.dQuantity > 0 &&
                this.iPrice > 0);
        },
        validationFormEdit: function () {
            return (this.sDescriptionEdit !== "" &&
                this.dQuantityEdit > 0 &&
                this.iPriceEdit > 0);
        },
    },
}
</script>

<style scoped>
.information-title {
    background: #ddebff 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #2759a2;
    padding: 2px;
    width: 200px;
}

.content-add-consumption {
    padding: 10px;
}

.btn-add-additional-product {
    width: 150px;
}

/* styles cards */
.txt-item-table-card-title {
    font-size: 16px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: 600;
    overflow-wrap: break-word !important;

}

.txt-item-table-card-item {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.txt-item-table-card-item-empty {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #818181;
    opacity: 1;
    font-style: italic;
}

.content-text-title {
    width: 90%;
    max-width: 90%;
    min-width: auto;
}

.content-actions {
    display: flex;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
    margin-left: 10px;
}

.content-first-row-card {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}



.content-status-mobile-card {
    width: 70px;
    display: flex;
    /* position: absolute; */
    right: 30px;
    top: 10px;
}


.px-card-test-select-all {
    width: fit-content;
}

.px-card-text-mb {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
}

.flex-wrap {
    flex-wrap: wrap;
}

.px-txt-card-title {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 600;
}

.px-txt-card-item {
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 5px;
}

.content-left {
    width: 90px;
    display: flex;
    height: 100%;
    align-self: start
}
</style>