<template>
  <div>
    <v-menu offset-y content-class="circle-profile-content">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="display-flex align-items-center">
          <div class="
              circle-content
              display-flex
              align-items-center
              justify-content-center
              poppins
            ">
            {{ name }}
          </div>
          <v-icon>mdi-menu-down</v-icon>
        </div>
      </template>
      <div class="circle-content-options">
        <!-- Start sidebar elements -->
        <div>
          <v-list dense>
            <!-- list item -->
            <v-list-item @click="redirectProfile" link>
              <v-list-item-icon>
                <v-icon color="#000" size="18px"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="poppins">
                Perfil
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="closeSesion" link>
              <v-list-item-icon>
                <v-icon color="#000" size="18px"> mdi-power </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="poppins">
                Cerrar sesión
              </v-list-item-content>
            </v-list-item>
            <!-- list item -->
          </v-list>
        </div>
        <!-- End sidebar elements -->
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "IconProfileComponent",
  data() {
    return {
      name: "",
    };
  },
  beforeMount() { this.vocalsName(); },
  methods: {
    vocalsName() {
      // var text = "";
      // if (this.sFullName.split(" ").length == 4) {
      //   text = this.sFullName.split(" ")[0].charAt(0) + this.sFullName.split(" ")[2].charAt(0);
      // } else if (this.sFullName.split(" ").length == 3) {
      //   text = this.sFullName.split(" ")[0].charAt(0) + this.sFullName.split(" ")[1].charAt(0);
      // } else if (this.sFullName.split(" ").length == 2) {
      //   text = this.sFullName.split(" ")[0].charAt(0) + this.sFullName.split(" ")[1].charAt(0);
      // }
      this.name = this.sName.substr(0, 1) + this.sLastname.substr(0, 1);

    },
    redirectProfile: function () {
      this.$router.push({ name: "profile" }).catch((err) => { });
    },
    closeSesion: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.delete(`${URI}/sessions/`, config)
        .then((response) => {
          // remove local store
          this.$store.commit("setToken", "");
          this.$store.commit("setTypeUser", "");
          this.$store.commit("setUserId", "");
          this.$store.commit("setStatus", "");
          this.$store.commit("setManager", null);
          // remove local storage
          localStorage.removeItem("bManager");
          localStorage.removeItem("bMenu");
          localStorage.removeItem("bMenuResponsive");
          localStorage.removeItem("sStatus");
          localStorage.removeItem("sToken");
          localStorage.removeItem("sTypeUser");
          localStorage.removeItem("sUserId");

          this.$router.push("/login");
          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message);
        });
    },
  },
  computed: {
    sName() {
      return this.$store.state.sName
    },
    sLastname() {
      return this.$store.state.sLastname
    }
  },
  watch: {
    sName() {
      this.vocalsName();
    },
    sLastname() {
      this.vocalsName();
    }
  }
};
</script>

<style scoped>
  .circle-content-options{
    min-width: 200px !important;
  }
.circle-content-options .v-list-item__content {
  font-size: 14px;
}

.circle-content-options .v-list-item__icon {
  margin-right: 2px !important;
}

.circle-profile-content {
  border-radius: 14px !important;
  box-shadow: 0px 20px 40px #3a79bd19 !important;
  margin-top: 10px;
  width: fit-content;
}

.circle-content {
  width: 40px;
  height: 40px;
  background: #2759a2 0% 0% no-repeat padding-box;
  border: 1px solid #79defc;
  opacity: 1;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 100%;
  text-transform: uppercase;
}
</style>