<template>
  <div class="display-flex align-items-center">
    <p class="txt-title poppins mb-0">Empleados</p>
    <v-spacer />
    <v-btn v-if="bAdminEmployees" @click="addEmployee" class="button-primary poppins" elevation="0">
      <span v-if="!responsive">+ Añadir empleado</span>
      <v-icon v-else color="#fff" size="18px">
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutEmployeeHeader",
  data() {
    return {
      responsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.responsive = false;
      } else {
        this.responsive = true;
      }
    },
    addEmployee: function () {
      this.$router.push({ name: "AddEmployee" });
    },
  },
  computed: {
    bAdminEmployees() {
      return this.$store.state.bAdminEmployees
    }
  }
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
  }
}
</style>