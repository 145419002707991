<template>
  <div>
    <layout-dashboard-content />
  </div>
</template>

<script>
import LayoutDashboardContent from "@/layouts/Dashboard/Content.vue";

export default {
  name: "DashboardView",
  components: {
    LayoutDashboardContent,
  },
};
</script>