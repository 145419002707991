<template>
  <div class="display-flex align-items-center">
    <p class="txt-title poppins mb-0">Notificaciones</p>
  </div>
</template>

<script>
export default {
  name: "LayoutNotificationsHeader",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 25px;
  }
}
</style>