<template>
  <div>
    <div class="content-title-section">Información de ciclo de vida</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">Fecha del último cambio</p>
            <p class="txt-value-global">{{ oHistory.tLastChange }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">Usuario</p>
            <p class="txt-value-global">{{ oHistory.sUserName }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <p class="txt-label-global">Estado actual del ciclo de vida</p>
            <p class="txt-value-global">{{ oHistory.sLifeCycleStatus }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-3 mb-5" />
    <div class="content-history-searchbar mb-5">
      <div class="content-title-section">
        <p class="mb-0">Histórico del estado de ciclo de vida</p>
      </div>
      <v-spacer></v-spacer>
      <div class="content-date-picker-range">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="rangoFechas"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-combobox v-model="rangoFechas" v-bind="attrs" v-on="on" label="Rango de fechas"
              placeholder="Seleccionar fechas" persistent-placeholder readonly class="global-auth-inputs" dense
              color="#2759A2" append-icon="mdi-calendar" chips small-chips multiple clearable></v-combobox>
          </template>
          <v-date-picker v-model="rangoFechas" :max="nowDate" no-title scrollable multiple range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(rangoFechas)">
              Aceptar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>
    <div>
      <div class="content-card-dialog">
        <chart-global idChartDinamyc="ChartCicloVida" :apexChartOptions="optionsCicloVida"
          :apexChartSeries="seriesCicloVida" typeChart="rangeBar" />
      </div>
    </div>
    <br /><br /><br />
  </div>
</template>

<script>
import ChartGlobal from "@/components/ChartGlobal.vue";
export default {
  name: "layoutTabsCicloVida",
  components: {
    ChartGlobal,
  },
  props: {
    tab: Number,
  },
  data() {
    return {
      rangoFechas: [],
      nowDate: new Date().toISOString().slice(0, 10),
      menu: false,
      optionsCicloVida: {
        chart: {
          type: "rangeBar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        colors: "#79DEFC",
      },
      seriesCicloVida: [],
      aHistory: [],
      oHistory: {},
      oFixedInfo: {},
    };
  },
  activated() {
    if (this.tab === 1) {
      this.getHistory();
    }
  },
  methods: {
    getHistory() {
      DB.get(`${URL_KITE}/${this.$route.params.idSimCard}/status`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          tStart: this.sDateStart,
          tEnd: this.sDateEnd,
        },
      })
        .then((response) => {
          this.oHistory = response.data.results.oHistory;
          this.aHistory = response.data.results.aHistory;
          this.oFixedInfo = response.data.results.oFixedInfo;
          this.$emit("setFixedInfo", this.oFixedInfo);

          let aHistoryTemp = [];

          for (let i = 0; i < this.aHistory.length; i++) {
            const element = this.aHistory[i];
            let sDateEnd =
              i + 1 < this.aHistory.length
                ? this.formatDate(this.aHistory[i + 1].tDate)
                : this.formatDate(this.formatDateToday());

            let oDate = {
              x: element.sState,
              y: [
                new Date(this.formatDate(element.tDate)).getTime(),
                new Date(sDateEnd).getTime(),
              ],
            };
            aHistoryTemp.push(oDate);
          }
          const newSeries = [
            {
              data: aHistoryTemp,
            },
          ];

          this.seriesCicloVida = newSeries;

          //#region PRUEBA FIJA DE DATOS DE GRAFICA //
          // const newSeries = [
          //   {
          //     data: [
          //       {
          //         x: "Inactiva nueva",
          //         y: [
          //           new Date("2019-03-01").getTime(),
          //           new Date("2019-03-04").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Test",
          //         y: [
          //           new Date("2019-03-04").getTime(),
          //           new Date("2019-03-08").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Test",
          //         y: [
          //           new Date("2019-03-14").getTime(),
          //           new Date("2019-03-18").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Lista para activación",
          //         y: [
          //           new Date("2019-03-08").getTime(),
          //           new Date("2019-03-12").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Activada",
          //         y: [
          //           new Date("2019-03-13").getTime(),
          //           new Date("2019-03-15").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Desactivada",
          //         y: [
          //           new Date("2019-03-16").getTime(),
          //           new Date("2019-03-18").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Suspendida",
          //         y: [
          //           new Date("2019-03-19").getTime(),
          //           new Date("2019-03-23").getTime(),
          //         ],
          //       },
          //       {
          //         x: "Retraida",
          //         y: [
          //           new Date("2019-03-23").getTime(),
          //           new Date("2019-03-26").getTime(),
          //         ],
          //       },
          //     ],
          //   },
          // ];
          // this.seriesCicloVida = newSeries;
          //#endregion PRUEBA FIJA DE DATOS DE GRAFICA //

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDate(date) {
      let aDate = date.split("-");
      return aDate[2] + "-" + aDate[0] + "-" + aDate[1];
    },
    formatDateToday() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      today = mm + "-" + dd + "-" + yyyy;
      return today;
    },
    saveDate() {
      this.sDateStart = this.rangoFechas[0];
      this.sDateEnd = this.rangoFechas[1];
      this.getHistory();
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.getHistory();
      }
    },
    refresh() {
      this.getHistory();
    },
    rangoFechas: function () {
      if (this.rangoFechas.length == 2) {
        if (this.rangoFechas[0] > this.rangoFechas[1]) {
          let fecha_inicial = this.rangoFechas[1];
          this.rangoFechas = [];
          this.rangoFechas.push(fecha_inicial);
        }
      }

      if (this.rangoFechas.length == 0) {
        // this.$emit("setDates", []);
      }
    },
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.content-history-searchbar {
  display: flex;
  width: 100%;
}

.content-title-section {
  display: flex;
  align-items: center;
  align-content: center;
}

.content-date-picker-range {
  width: 30%;
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-history-searchbar {
    display: block;
    width: 100%;
  }

  .content-title-section {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .content-date-picker-range {
    width: 100%;
    margin-top: 30px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-date-picker-range {
    width: 45%;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>