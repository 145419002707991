<template>
    <div class="content-tabs-cards">
        <div class="sticky-content">
            <layout-detail-customer-sim-card-header :bLteEnabled="bLteEnabled" :oFixedInfo="oFixedInfo" :oCustomer="oCustomer" />
            <layout-detail-customer-sim-card-dashboard :oFixedInfo="oFixedInfo" :oCustomer="oCustomer" />
            <v-tabs v-model="tab" background-color="transparent" color="#2759A2" align-with-title :touchless="true"
                center-active show-arrows>
                <v-tabs-slider class="px-slider"></v-tabs-slider>
                <v-tab v-for="(item, index) in tabs" :key="index">
                    {{ item.text }}
                </v-tab>
            </v-tabs>
        </div>
        <layout-detail-customer-sim-card-tabs :tab="tab" @setFixedInfoDetail="setFixedInfo"
            @setLteEnabledDetail="setLteEnabled" @setCustomer="setCustomer" />
    </div>
</template>
  
<script>
import LayoutDetailCustomerSimCardHeader from "@/layouts/DetailCustomer/Tabs/SimCards/DetailSimCards/Header.vue";
import LayoutDetailCustomerSimCardDashboard from "@/layouts/DetailCustomer/Tabs/SimCards/DetailSimCards/Dashboard.vue";
import LayoutDetailCustomerSimCardTabs from "@/layouts/DetailCustomer/Tabs/SimCards/DetailSimCards/Tabs.vue";

export default {
    name: "DetailCustomerSimCardView",
    data() {
        return {
            tab: 0,
            tabs: [
                { text: "Información de la SIM", component: "LayoutTabsInformacion" },
                { text: "Estado de ciclo de vida", component: "LayoutTabsCicloVida" },
                { text: "Presencia", component: "LayoutTabsPresencia" },
                {
                    text: "Control de consumo de tráfico",
                    component: "LayoutTabsControlConsumo",
                },
                { text: "Localización", component: "LayoutTabsLocalizacion" },
            ],
            oFixedInfo: {},
            bLteEnabled: null,
            oCustomer:{}
        };
    },
    mounted() {
        this.$nextTick(() => {
            // setup click event for next icon
            document
                .getElementsByClassName("v-slide-group__next")[0]
                .addEventListener("click", () => {
                    this.tab = this.tab + 1;
                });
            // setup click event for previous icon
            document
                .getElementsByClassName("v-slide-group__prev")[0]
                .addEventListener("click", () => {
                    this.tab = this.tab - 1;
                });
        });
    },
    components: {
        LayoutDetailCustomerSimCardHeader,
        LayoutDetailCustomerSimCardDashboard,
        LayoutDetailCustomerSimCardTabs,
    },
    methods: {
        setFixedInfo(oFixedInfo) {
            this.oFixedInfo = oFixedInfo;
        },
        setLteEnabled(bLteEnabled) {
            this.bLteEnabled = bLteEnabled;
        },
        setCustomer(oCustomer){
            this.oCustomer = oCustomer
        }
    },
};
</script>
  
<style>
.content-tabs-cards .v-tabs-slider-wrapper {
    height: 5px !important;
}

.content-tabs-cards .v-tab {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #a1acc3 !important;
    opacity: 1;
    text-transform: initial;
}

.content-tabs-cards .v-tab--active {
    color: #2759a2 !important;
}

.content-tabs-cards .theme--light.v-tabs-items {
    background-color: transparent;
}

.v-slide-group__wrapper {
    border-bottom: 1px solid #a1acc3 !important
}
</style>
  
<style scoped>
.px-slider {
    background: transparent linear-gradient(268deg, #79defc 0%, #2759a2 100%) 0% 0% no-repeat padding-box;
}

.sticky-content {
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #f2f6fe;
}

@media (max-width: 960px) {
    .sticky-content {
        position: inherit !important;
    }
}
</style>