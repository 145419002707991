<template>
    <div>
        <v-menu offset-y content-class="content-menu-notification-all" max-width="330" min-width="330">
            <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="icon-notification display-flex align-items-center mr-2">
                    <v-badge :value="bNotification" color="red accent-4" overlap dot transition="slide-x-transition">
                        <v-icon color="#2759A2">mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <div class="content-menu-notification">
                <div class="content-title">
                    <p class="txt-title-notification">Notificaciones</p>
                    <v-spacer></v-spacer>
                    <p @click="setShowAllNotification" class="txt-show-all">Ver todo</p>
                </div>
                <v-divider></v-divider>
                <div class="content-notification">
                    <div class="content-notification-new">
                        <!-- aNotificationGlobal -->
                        <div class="notification-new"
                            v-for="(item, i) in aNotificationGlobal" :key="i">
                            <div class="content-info-txt" @click="setNotificationReadGlobal(item)" >
                                <p class="txt-title-notification-new" :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">
                                    {{
                                            item.sCustomTitle
                                    }}</p>
                                <p class="txt-description-notification-new"
                                    :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">{{ item.sCustomMessage }}</p>
                                <p class="txt-date-notification-new" :class="item.oRead.bRead ? 'txt-f-r' : 'txt-f-b'">
                                    {{
                                            item.tCreatedAt
                                    }}</p>
                            </div>
                            <div class="content-view" @click="setNotificationReadGlobal(item)" >
                                <div class="point-view" v-if="!item.oRead.bRead"></div>
                            </div>
                            <div class="content-view">
                                <v-btn @click="deleteItem(item.sNotificationId)" height="20" width="20" size="10" icon>
                                    <v-icon size="18">
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="content-empty-notifications" v-if="aNotificationGlobal.length === 0">
                            Sin notificaciones
                        </div>
                    </div>
                </div>
                <!-- Start sidebar elements -->
                <!-- End sidebar elements -->
            </div>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "IconNotificationComponent",
    data() {
        return {
            name: "",
            iNotificationLocalLength: 0,
            bNotification: false
        };
    },
    beforeMount() {
        this.iNotificationLocalLength = this.aNotificationGlobal.length;
        this.vocalsName();
    },
    methods: {
        vocalsName() {
            this.name = this.sName.substr(0, 1) + this.sLastname.substr(0, 1);
            // this.name = this.sName
        },
        redirectProfile: function () {
            this.$router.push({ name: "profile" }).catch((err) => { });
        },
        closeSesion: function () {
            const config = {
                headers: {
                    Authorization: `Bearer ${this.$store.state.sToken}`,
                },
            };

            DB.delete(`${URI}/sessions/`, config)
                .then((response) => {
                    // remove local store
                    this.$store.commit("setToken", "");
                    this.$store.commit("setUserId", "");
                    this.$store.commit("setStatus", "");
                    this.$store.commit("setManager", null);
                    // remove local storage
                    localStorage.removeItem("bManager");
                    localStorage.removeItem("bMenu");
                    localStorage.removeItem("bMenuResponsive");
                    localStorage.removeItem("sStatus");
                    localStorage.removeItem("sToken");
                    localStorage.removeItem("sUserId");

                    this.$router.push("/login");
                    this.mixSuccess(response.data.message);
                })
                .catch((error) => {
                    this.mixError(error.response.data.message, error.response.status);
                });
        },
        setShowAllNotification() {
            this.$router.push({ name: "notifications" }).catch((e) => { })
        },
        setNotificationReadGlobal(item) {
            this.$store
                .dispatch("setNotificationReadGlobal", item)
                .then((resp) => {
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        deleteItem: function (id) {
            let options = {
                active: true,
                id,
                api: `/notifications/${id}`,
                title: "Eliminar notificación",
                description:
                    "¿Estás seguro de eliminar la notificación? <br/> Al realizar esta <br/> acción no podrá revertirse",
            };
            this.$emit("setOptions", options);
        },
    },
    computed: {
        sName() {
            return this.$store.state.sName
        },
        sLastname() {
            return this.$store.state.sLastname
        },
        aNotificationGlobal() {
            return this.$store.state.aNotificationGlobal;
        }
    },
    watch: {
        sName() {
            this.vocalsName();
        },
        sLastname() {
            this.vocalsName();
        },
        aNotificationGlobal() {
            this.bNotification = this.aNotificationGlobal.filter(e => e.oRead.bRead === false).length > 0
        }
    }
};
</script>

<style scoped>
.content-menu-notification-all {
    background-color: #FFFFFF !important;
    /* max-width: 80% !important;
    min-width: 80% !important; */
}

.content-menu-notification {
    background-color: #FFFFFF;
    width: 330px !important;
    /* padding: 25px 20px 25px 20px; */
}

.content-title {
    margin-bottom: 10px;
    padding: 20px 20px 0px 20px;
}

.notification-new {
    display: flex;
}

.content-view {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.point-view {
    height: 7px;
    width: 7px;
    background-color: #2759A2;
    border-radius: 100%;
}

.txt-title-notification {
    font-weight: 550;
    color: #283C4D;
    margin-bottom: 0px;
}

.txt-show-all {
    font-weight: 500;
    color: #2759A2;
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 13px;
}

.txt-show-all:hover {
    color: #2759A2;
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 13px;
    text-decoration: underline;
}

.content-notification {
    height: 80vh !important;
    overflow: auto;
}

.content-notification-new,
.content-notification-old {
    /* padding: 10px; */
}

.txt-new-notification,
.txt-old-notification {
    font-weight: 550;
    font-size: 14px;
    color: #283C4D;
    margin-bottom: 5px;
}

.notification-new,
.notification-old {
    /* background-color: #E5E5E5; */
    padding: 10px;
    /* border-radius: 5px; */
    cursor: pointer;
}

.notification-new:hover,
.notification-old:hover {
    background-color: #e5e5e545;
    /* border-radius: 5px; */
    cursor: pointer;
}

.txt-title-notification-new,
.txt-title-notification-old {
    font-size: 14px;
    margin-bottom: 0px;
    overflow-wrap: break-word;
    color: #2759A2;
}

.txt-description-notification-new,
.txt-description-notification-old {
    font-size: 12px;
    margin-bottom: 0px;
    overflow-wrap: break-word;
    color: #283C4D;
}

.txt-f-b {
    font-weight: 600;
}

.txt-date-notification-new,
.txt-date-notification-old {
    font-size: 10px;
    margin-bottom: 0px;
    color: #A1ACC3;
}

.content-title {
    display: flex;
    width: 100%;
}

.icon-notification {
    background-color: transparent !important;
    width: 35px !important;
    max-width: 35px !important;
    min-width: 35px !important;
    border-radius: 10px;
}

.icon-notification:hover {
    transform: scale(1.1);
    background-color: #E6EFFF !important;
}

.content-empty-notifications {
    text-align: center;
    margin-top: 15px;
}
</style>