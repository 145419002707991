<template>
  <div>
    <layout-prebilling-header @setDialogAdd="setDialogAdd" 
    @setDates="setDates"
     />
    <!-- <layout-prebilling-searchbar
      @setSearch="setSearch"
      @setNameOrder="setNameOrder"
      @setRegistredAction="setRegistredAction"
      @setDates="setDates"
    /> -->
    <layout-prebilling-content
      :sSearch="sSearch"
      :sNameOrder="sNameOrder"
      :sRegistredAction="sRegistredAction"
      @setOptions="setOptions"
      :arrDates="arrDates"
    />
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutPrebillingContent from "@/layouts/Prebilling/Content.vue";
import LayoutPrebillingSearchbar from "@/layouts/Prebilling/Searchbar.vue";
import LayoutPrebillingHeader from "@/layouts/Prebilling/Header.vue";

export default {
  name: "PrebillingView",
  data() {
    return {
      sSearch: "",
      sNameOrder: undefined,
      sRegistredAction: undefined,
      bDialogAdd: false,
      oOptions: {},
      clientId: "",
      arrDates: "",
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setNameOrder: function (val) {
      this.sNameOrder = val;
    },
    setRegistredAction: function (val) {
      this.sRegistredAction = val;
    },
    setDates: function (val) {
      this.arrDates = val;
    },
  },
  components: {
    LayoutPrebillingContent,
    LayoutPrebillingSearchbar,
    LayoutPrebillingHeader,
  },
};
</script>