<template>
  <div>
    <v-row>
      <v-col cols="12" class="ml-0 mr-0 mt-3">
        <div class="information-title">Consumos</div>
      </v-col>
      <v-col cols="12" class="ml-0 mr-0">
        <v-data-table
          v-if="!bMobile"
          :mobile-breakpoint="0"
          :items-per-page="-1"
          :headers="aHeader"
          :items="aConsumption"
          :hide-default-footer="true"
          no-data-text="No hay información"
          class="elevation-1 table-overflow"
        >
          <template v-slot:[`item.sFolio`]="{ item }">
            <span v-if="item.sFolio">
              {{ item.sFolio }}
            </span>
            <span v-else class="txt-empty-global-table">
              Será generado el : {{ tFinalDate }}
            </span>
          </template>
          <template v-slot:[`item.dQuantity`]="{ item }">
            <span>
              {{ item.dQuantity }}
            </span>
          </template>
          <template v-slot:[`item.dUnitPrice`]="{ item }">
            <span> ${{ formatMoneyGlobal(item.dUnitPrice) }} MXN </span>
          </template>
          <template v-slot:[`item.dPricePerDay`]="{ item }">
            <span> ${{ formatMoneyGlobal(item.dPricePerDay) }} MXN </span>
          </template>
          <template v-slot:[`item.dTotalPrice`]="{ item }">
            <span> ${{ formatMoneyGlobal(item.dTotalPrice) }} MXN </span>
          </template>
        </v-data-table>
        <div v-else>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col
                v-for="(item, index) in aConsumption"
                :key="index"
                cols="12"
                sm="6"
              >
                <div class="content-card-responsive position-relative">
                  <div class="height-100">
                    <div>
                      <div class="content-first-row-card">
                        <div class="content-text-title">
                          <p class="txt-item-table-card-title poppins mb-3">
                            <span v-if="item.sFolio">
                              {{ item.sFolio }}
                            </span>
                            <span v-else class="txt-empty-global-table">
                              Será generado el {{ tFinalDate }}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div class="flex-wrap display-flex align-items-center">
                        <div class="content-left">
                          <p class="px-txt-card-title mb-0 mr-2">Cantidad</p>
                        </div>
                        <div class="content-right">
                          <p class="px-txt-card-item mb-0">
                            {{ item.dQuantity }}
                          </p>
                        </div>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <div class="content-left">
                          <p class="px-txt-card-title mb-0 mr-2">Precio</p>
                        </div>
                        <div class="content-right">
                          <p class="px-txt-card-item mb-0">
                            {{ item.dUnitPrice }}
                          </p>
                        </div>
                      </div>
                      <div class="flex-wrap display-flex align-items-center">
                        <div class="content-left">
                          <p class="px-txt-card-title mb-0 mr-2">Importe</p>
                        </div>
                        <div class="content-right">
                          <p class="px-txt-card-item mb-0">
                            {{ item.dTotalPrice }}
                          </p>
                        </div>
                      </div>
                      <div class="display-flex align-items-center">
                        <div class="content-left">
                          <p class="px-txt-card-title mb-0 mr-2">Descripción</p>
                        </div>
                        <div class="content-right">
                          <p class="px-txt-card-item mb-0">
                            {{ item.sDescription }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    aConsumption: Array,
    tFinalDate: String,
  },
  data() {
    return {
      aHeader: [
        {
          text: "Folio",
          align: "start",
          sortable: false,
          value: "sFolio",
          class: "txt-color-black-global",
        },
        {
          text: "Descripción",
          sortable: false,
          value: "sDescription",
          class: "txt-color-black-global",
        },
        {
          text: "Proporcional días",
          align: "center",
          sortable: false,
          value: "iProportionalDays",
          class: "txt-color-black-global",
        },
        {
          text: "Precio unitario",
          // align: "center",
          sortable: false,
          value: "dUnitPrice",
          class: "txt-color-black-global",
        },
        {
          text: "Precio proporcional",
          sortable: false,
          value: "dPricePerDay",
          class: "txt-color-black-global",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "dQuantity",
          class: "txt-color-black-global",
        },
        {
          text: "Importe",
          sortable: false,
          value: "dTotalPrice",
          class: "txt-color-black-global",
        },
      ],
      bMobile: false,
    };
  },
  beforeMount() {},
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 959.99) {
        this.bMobile = false;
      } else {
        this.bMobile = true;
      }
    },
  },
};
</script>

<style scoped>
.information-title {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 2px;
  width: 200px;
}

.txt-item-table {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  overflow-wrap: break-word !important;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.content-text-title {
  width: 90%;
  max-width: 90%;
  min-width: auto;
}

.content-actions {
  display: flex;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  margin-left: 10px;
}

.content-first-row-card {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.content-status-mobile-card {
  width: 70px;
  display: flex;
  /* position: absolute; */
  right: 30px;
  top: 10px;
}

.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}

.content-left {
  width: 90px;
  display: flex;
  height: 100%;
  align-self: start;
}
</style>

<style>
.table-overflow {
  overflow-x: auto;
}
</style>
