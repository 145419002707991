<template>
  <div>
    <div v-if="Object.keys(items).length > 0">
      <layout-detail-employee-header :items="items" />
      <div class="separator-line mt-5 mb-9" />
      <layout-detail-employee-form :items="items" :editData="editData" @setDataForm="setDataForm" />
      <div class="separator-line mt-5 mb-9" />
      <!-- <layout-detail-employee-permissions :items="items.aCustomerPermissions" :editData="editData"
        @setDataPermissions="setDataPermissions" /> -->
      <layout-detail-employee-permissions :bAddOrEdit="false" @setPermissions="setPermissions" :editData="editData"
        @setValdatePermissions="setValdatePermissions" :aPermissionsDynamic="items.aCustomerPermissions" />
      <div class="separator-line mt-5 mb-9" :items="items" />
    </div>
    <layout-detail-employee-buttons @setDialogForgotPassword="setDialogForgotPassword" @setEditData="setEditData"
      @setOptions="setOptions" :editData="editData" :items="items" :dataForm="dataForm"
      :dataPermissions="dataPermissions" :bPermission="bPermission" />
    <layout-detail-employee-dialog-forgot-password @setDialogForgotPassword="setDialogForgotPassword"
      :bDialogForgotPassword="bDialogForgotPassword" :sEmail="sEmail" />
    <delete-component :oOptions="oOptions" @setOptions="setOptions" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutDetailEmployeeHeader from "@/layouts/DetailEmployee/Header.vue";
import LayoutDetailEmployeeForm from "@/layouts/DetailEmployee/Form.vue";
import LayoutDetailEmployeePermissions from "@/components/Permissions.vue";
import LayoutDetailEmployeeButtons from "@/layouts/DetailEmployee/Buttons.vue";
import LayoutDetailEmployeeDialogForgotPassword from "@/layouts/DetailEmployee/DialogForgotPassword.vue";

export default {
  name: "DetailEmployeeView",
  data() {
    return {
      bDialogForgotPassword: false,
      bLoading: false,
      items: {},
      editData: false,
      dataForm: {},
      oOptions: {},
      dataPermissions: [],
      bPermission: false,
      sEmail:""
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    setValdatePermissions(val) {
      this.bPermission = val
    },
    setDataForm: function (val) {
      this.dataForm = val;
    },
    setPermissions: function (val) {
      this.dataPermissions = val;
    },
    setEditData: function (val) {
      this.editData = val;
    },
    setDialogForgotPassword: function (val) {
      this.bDialogForgotPassword = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    getData: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
        DB.get(
          `${URI}/enterprises/${this.$store.state.sEnterpriseId}/customers/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
            params: {

            },
          }
        )
          .then((response) => {
            this.bLoading = false;
            this.sEmail = response.data.results.oGeneralInfo.sEmail;
            this.items = response.data.results;
            this.$store.commit("refresher", false);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      }).catch((error) => {
        this.mixError(error.message, error.code);
      });

    },
  },
  components: {
    LayoutDetailEmployeeHeader,
    LayoutDetailEmployeeForm,
    LayoutDetailEmployeePermissions,
    LayoutDetailEmployeeButtons,
    LayoutDetailEmployeeDialogForgotPassword,
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh() {
      this.getData();
      this.editData = false;
    },
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>