<template>
  <v-dialog
    v-model="bDialogAdd"
    persistent
    :width="`${screenWidth}%`"
  >
    <div class="content-card-dialog">
      <v-btn
        class="content-card-button-close"
        @click="closeDialog"
        icon
      >
        <v-icon
          color="#000"
          size="16px"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins">Solicitud de tarjetas SIM</p>
      <!-- content -->
      <div>

      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutSimsAdd",
  props: {
    bDialogAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      screenWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$emit("setDialogAdd", false);
    },
  },
};
</script>

<style scoped>
</style>