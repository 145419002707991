<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <div class="content-title-section mb-5">Información general</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-model="nombre"
              type="text"
              label="Nombres"
              placeholder="Nombres"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
            >
            <template slot="label">
              <span>Nombres <span class="color-red">*</span></span>
            </template>
          </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-model="apellido"
              type="text"
              label="Apellidos"
              placeholder="Apellidos"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
            >
            <template slot="label">
              <span>Apellidos <span class="color-red">*</span></span>
            </template>
          </v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-model="cargoLaboral"
              type="text"
              label="Cargo laboral"
              placeholder="Cargo laboral"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
            >
            <!-- <template slot="label">
              <span>Cargo laboral <span class="color-red">*</span></span>
            </template> -->
          </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-model="correoElectronico"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
            >
            <template slot="label">
              <span>Correo electrónico <span class="color-red">*</span></span>
            </template>
          </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="6">
          <div>
            <!-- Start: phone component -->
            <country-code-input-component
              :bObligatoryField="true"
              class=""
              @setCodigoPais="setCodigoPais"
              @setCodigoArea="setCodigoArea"
              @setNumeroTelefono="setNumeroTelefono"
              @setExtension="setExtension"
              :_bDialogAdd="false"
              :_codigoPais="codigoPais"
              :_codigoArea="codigoArea"
              :_numeroTelefono="numeroTelefono"
              :_extension="extension"
              :_type="false"
            />
            <!-- End: phone component -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutAddEmployeeForm",
  data() {
    return {
      nombre: "",
      apellido: "",
      cargoLaboral: "",
      correoElectronico: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
    };
  },
  methods: {
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[_A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    // Reepresentante
    setCodigoPais: function (e) {
      this.codigoPais = e;
    },
    setCodigoArea: function (e) {
      this.codigoArea = e;
    },
    setNumeroTelefono: function (e) {
      this.numeroTelefono = e;
    },
    setExtension: function (e) {
      this.extension = e;
    },
    // Empresa
    setCodigoPaisEmpresa: function (e) {
      this.sCountryCallingCode = e;
    },
    setCodigoAreaEmpresa: function (e) {
      this.sAreaCallingCode = e;
    },
    setNumeroTelefonoEmpresa: function (e) {
      this.sPhoneNumber = e;
    },
    setExtensionEmpresa: function (e) {
      this.sPhoneExtension = e;
    },
  },
  watch: {
    nombre: function () {
      this.$emit("setNombre", this.nombre);
    },
    apellido: function () {
      this.$emit("setApellido", this.apellido);
    },
    cargoLaboral: function () {
      this.$emit("setCargoLaboral", this.cargoLaboral);
    },
    correoElectronico: function () {
      this.$emit("setCorreoElectronico", this.correoElectronico);
    },
    codigoPais: function () {
      this.$emit("setCodigoPais", this.codigoPais);
    },
    codigoArea: function () {
      this.$emit("setCodigoArea", this.codigoArea);
    },
    numeroTelefono: function () {
      this.$emit("setNumeroTelefono", this.numeroTelefono);
    },
    extension: function () {
      this.$emit("setExtension", this.extension);
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 0px 15px;
  width: fit-content;
}
</style>