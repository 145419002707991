<template>
  <div class="content-buttons-actions mt-5">
    <p class="txt-actions poppins mb-2">Acciones globales:</p>
    <v-btn
      elevation="0"
      @click="setDialogAssign(false)"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
    >
      Desasignar SIM
    </v-btn>
    <v-btn
      elevation="0"
      @click="setDialogAssign(true)"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
    >
      Asignar SIM
    </v-btn>
    <v-btn
      elevation="0"
      @click="setDialogConfirmActionGlobal(1)"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
    >
      Reiniciar tarjeta
    </v-btn>
    <v-btn
      elevation="0"
      @click="sendSms()"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
      >Enviar SMS
    </v-btn>
    <v-btn
      v-if="bCanActivateSIM"
      elevation="0"
      @click="changeStatus(true)"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
      >Activar
    </v-btn>
    <v-btn
      v-if="bCanActivateSIM"
      elevation="0"
      @click="changeStatus(false)"
      :disabled="selectedItems.length == 0"
      class="button-secondary ml-2 mb-2 poppins"
      >Desactivar
    </v-btn>
    <dialog-assign
      :bDialogAssignOrigin="bDialogAssignOrigin"
      :oAssignOrigin="oAssignOrigin"
      @setDialogAssign="setDialogAssign"
    />

    <confirm-reset-sim
      @setDialogConfirmActionGlobal="setDialogConfirmActionGlobal"
      :bDialogConfirmResetSIM="bDialogConfirmResetSIM"
      :oItem="oItem"
      :selectedItems="selectedItems"
      sType="local"
    />

    <confirm-change-status-component
      :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus"
    />
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";
import DialogAssign from "../../components/DialogAssign.vue";

export default {
  name: "LayoutSimsCardsOptionsDesktop",
  props: {
    selectedItems: Array,
  },
  components: {
    ConfirmChangeStatusComponent,
    DialogAssign,
  },
  data() {
    return {
      bDialogAssignOrigin: false,
      oAssignOrigin: {},
      bDialogConfirmResetSIM: false,
      oItem: {},
      oChangeStatusOptions: {},
      bCanActivateSIM: false,
    };
  },
  beforeMount() {
    this.getPermissionClient();
  },
  methods: {
    setDialogAssign(bAssign) {
      this.bDialogAssignOrigin = !this.bDialogAssignOrigin;
      if (bAssign) {
        this.oAssignOrigin = {
          bAssign: bAssign,
          sTitle: "Asignar SIM",
          sQuestion: `<span>Selecciona al cliente que deseas asignarle la(s) tarjeta(s) SIM seleccionada(s)</span>`,
          sDoubleConfirm: `<span>Acepto la responsabilidad y deseo proseguir con la asignación de la(s) tarjeta(s) SIM.</span>`,
          aICC: this.selectedItems,
        };
      } else {
        this.oAssignOrigin = {
          bAssign: bAssign,
          sTitle: "Desasignar SIM",
          sQuestion: `<span>¿Estás seguro de designar la(s) tarjeta(s) seleccionada(s)? <br> Al realizar esta acción no podrá revertirse</span>`,
          sDoubleConfirm: `<span>Acepto la responsabilidad y deseo proseguir con la asignación de la(s) tarjeta(s) SIM</span>`,
          aICC: this.selectedItems,
        };
      }
    },
    sendSms: function () {
      this.$store.commit("setDialogSendSms", true);
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
        sTextBtn: "",
      };
    },
    changeStatus: function (bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URI}/sim-cards/local/${bAction ? "activate" : "deactivate"}`,
        payload: { aICC: this.selectedItems },
        bInputs: true,
        title:
          bAction == true
            ? "Activación de tarjeta SIM"
            : "Desactivación de tarjeta SIM",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción activara la tarjeta SIM.</br>¿Desea continuar?"
            : "La siguiente acción desactivara la tarjeta SIM.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Activar" : "Desactivar",
        iMethod: 1,
      };
    },
    setDialogConfirmActionGlobal(iActionGlobal) {
      this.bDialogConfirmResetSIM = !this.bDialogConfirmResetSIM;
      if (this.bDialogConfirmResetSIM) {
        this.oItem = this.setItemDynamic(iActionGlobal);
      }
    },
    setItemDynamic(iActionGlobal) {
      switch (iActionGlobal) {
        case 1:
          return {
            sTitle: "Reiniciar tarjeta",
            sDescription: "Selecciona la red(es) que deseas reiniciar",
            iActionGlobal: iActionGlobal,
          };
        case 2:
          return {
            sTitle: "Enviar SMS",
            sDescription: "Descripción de enviar SMS",
            iActionGlobal: iActionGlobal,
          };
        case 3:
          return {
            sTitle: "Activar",
            sDescription: "Descripción de activar",
            iActionGlobal: iActionGlobal,
          };
        case 4:
          return {
            sTitle: "Desactivar",
            sDescription: "Descripción de desactivar",
            iActionGlobal: iActionGlobal,
          };

        default:
          return {
            sTitle: "",
            sDescription: "",
            iActionGlobal: 0,
          };
      }
    },
    getPermissionClient() {
      this.$store
        .dispatch("getPermissionClientGlobal")
        .then((resp) => {
          this.bCanActivateSIM = resp.data.results.oEnterpriseInfo.bCanActivateSIM;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
.txt-actions {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.content-buttons-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
</style>
