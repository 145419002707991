<template>
  <div class=" content-header ">
    <p class="txt-title poppins mb-0">Prefacturación</p>
    <v-spacer />
    <div class="position-relative content-date-filter" @click="menu = true">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="sDate"
        transition="scale-transition" offset-y min-width="auto" attach>
        <template v-slot:activator="{ on, attrs }">
          <div class="content-input-date-range">
            <v-text-field v-model="sDate" v-bind="attrs" v-on="on" label="Período" placeholder="Seleccionar período"
              persistent-placeholder readonly class="global-auth-inputs" dense color="#2759A2"
              append-icon="mdi-calendar" clearable></v-text-field>
          </div>
        </template>
        <v-date-picker v-model="sDate" type="month" :max="nowDate" no-title scrollable locale="es">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false; $refs.menu.save((dates = '')); sDate = '';">
            Cancelar
          </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(sDate); saveDates();" :disabled="sDate === ''">
            Aceptar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutPrebillingHeader",
  data() {
    return {
      responsive: false,
      sSearch: "",
      menu: false,
      sDate: "",
      date: new Date(),
      picker: new Date().toISOString().substr(0, 10),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.responsive = false;
      } else {
        this.responsive = true;
      }
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    saveDates: function () {
      this.$emit("setDates", this.sDate);
    },
  },
  computed: {
    nowDate() {
      let fecha = new Date();
      let data = fecha.setMonth(fecha.getMonth() + 1)
      return new Date(data).toISOString().slice(0, 10)
    }
  },
  watch: {
    sDate: function () {
      if (this.sDate === "" || this.sDate === null || this.sDate === undefined) {
        this.$emit("setDates", "");
      } else {
        // this.$emit("setDates", this.sDate);
      }
    },
  }
};
</script>

<style scoped>
.content-header {
  display: flex;
  align-items: center;
}

.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-date-filter {
  width: 300px;
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-header {
    display: block;
    align-items: center;
  }

  .content-date-filter {
    width: 100%;
    margin-top: 20px;
  }

}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>