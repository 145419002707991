<template>
  <div>
    <div class="content-title-section">Información general</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col v-if="!bModify" cols="12" sm="6" md="3" lg="3" xl="3">
          <div>
            <p class="txt-label-global">Folio</p>
            <p class="txt-value-global">
              {{ oGeneralInformation.sFolio }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <div v-if="bModify">
            <v-text-field
              v-model="sPublicName"
              type="text"
              label="Nombre público"
              placeholder="Ingrese nombre público"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
              @keyup="validationForm()"
            >
              <template slot="label">
                <span>Nombre público <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
          <div v-else>
            <p class="txt-label-global">Nombre público</p>
            <p class="txt-value-global">
              {{ oGeneralInformation.sPublicName }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <div v-if="bModify">
            <v-text-field
              v-model="sEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
              @keyup="validationForm()"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
          <div v-else>
            <p class="txt-label-global">Correo electrónico</p>
            <p class="txt-value-global">
              {{ oGeneralInformation.sEmail }}
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          :sm="bModify ? 6 : 4"
          :md="bModify ? 6 : 3"
          :lg="bModify ? 6 : 3"
          :xl="bModify ? 6 : 3"
        >
          <div v-if="bModify">
            <!-- Start: phone component -->
            <country-code-input-component
              class=""
              :bObligatoryField="true"
              @setCodigoPais="setCodigoPais"
              @setCodigoArea="setCodigoArea"
              @setNumeroTelefono="setNumeroTelefono"
              @setExtension="setExtension"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="true"
            />
            <!-- End: phone component -->
          </div>
          <div v-else>
            <p class="txt-label-global">Teléfono</p>
            <p class="txt-value-global">
              {{ oPhoneNumber.sFullPhoneNumber }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <div class="content-title-section">Representante</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="4" xl="4">
          <div v-if="bModify">
            <v-text-field
              v-model="sContactFullName"
              type="text"
              label="Nombre del representante"
              placeholder="Ingrese el nombre del representante"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
              @keyup="validationForm()"
            >
              <template slot="label">
                <span>Nombre del representante <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
          <div v-else>
            <p class="txt-label-global">Representante</p>
            <p class="txt-value-global">
              {{ oGeneralInformation.sContactFullName }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" v-if="!bModify" />
    <div class="content-title-section" v-if="!bModify">
      Registro de actividad
    </div>
    <v-container v-if="!bModify" fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <div>
            <p class="txt-label-global">Fecha y hora de creación</p>
            <p class="txt-value-global">
              {{ oGeneralInformation.tCreatedAt }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
          <div>
            <p class="txt-label-global">
              Fecha y hora de la última modificación
            </p>
            <p class="txt-value-global">
              {{ oGeneralInformation.tUpdatedAt }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="content-buttons">
      <v-container class="pa-0" fluid>
        <div class="content-btns">
          <div class="btn-discard" v-if="bModify">
            <div class="btn-left">
              <v-btn
                @click="setDeleteDialog"
                elevation="0"
                class="button-secondary-delete button4 button-delete"
              >
                Eliminar
              </v-btn>
            </div>
            <v-spacer />
            <div class="btn-right-two">
              <v-btn
                @click="discardChange"
                class="button-secondary poppins btn-edit"
                elevation="0"
                style="margin-right: 10px"
              >
                Descartar cambios
              </v-btn>
              <v-btn
                @click="editCustomer"
                :loading="bLoading"
                :disabled="bModifyBtn"
                class="button-primary poppins btn-changes"
                elevation="0"
              >
                Guardar cambios
              </v-btn>
            </div>
          </div>
          <div class="btn-add" v-else>
            <div class="btn-left">
              <v-btn
                v-if="bAdminEndCustomer"
                @click="setDeleteDialog"
                elevation="0"
                class="button-secondary-delete button4 button-delete"
              >
                Eliminar
              </v-btn>
            </div>
            <div class="btn-right">
              <v-btn
                v-if="bAdminEndCustomer"
                @click="bModify = true"
                class="button-primary poppins button-modify"
                elevation="0"
              >
                Modificar
              </v-btn>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "layoutTabsInformacion",
  props: {
    tab: Number,
  },
  data() {
    return {
      bLoading: false,
      oGeneralInformation: {},
      oPhoneNumber: {},
      sPublicName: "",
      sEmail: "",
      sContactFullName: "",

      sCountryCallingCode: "52",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",

      oEnterprise: {},
      oPhoneNumber: {},
      bModify: false,
      bModifyBtn: true,
    };
  },
  beforeMount() {
    this.getInformation();
  },
  activated() {
    if (this.tab == 0) {
      this.getInformation();
    }
  },
  methods: {
    setDeleteDialog() {
      let options = {
        active: true,
        id: this.$route.params.id,
        api: `/enterprises/${this.$store.state.sEnterpriseId}/final-customers/${this.$route.params.id}`,
        title: "Eliminar cliente",
        description:
          "Eliminar un cliente es una acción irreversible </br>  ¿Desea continuar?",
        bReturnView: true,
      };
      this.$emit("setOptions", options);
    },
    getInformation() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(
            `${URI}/enterprises/${this.$store.state.sEnterpriseId}/final-customers/${this.$route.params.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.sToken}`,
              },
              params: {},
            }
          )
            .then((response) => {
              this.fillData(response.data.results);
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.mixError(error.response.data.message, error.response.status);
            });
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    discardChange() {
      this.bModify = false;
      this.oEnterprise = this.oGeneralInformation.oEnterprise;
      this.oPhoneNumber = this.oGeneralInformation.oPhoneNumber;

      this.sPublicName = this.oGeneralInformation.sPublicName;
      this.sEmail = this.oGeneralInformation.sEmail;
      this.sContactFullName = this.oGeneralInformation.sContactFullName;

      this.sCountryCallingCode =
        this.oGeneralInformation.oPhoneNumber.sCountryCallingCode;
      this.sAreaCallingCode =
        this.oGeneralInformation.oPhoneNumber.sAreaCallingCode;
      this.sPhoneNumber = this.oGeneralInformation.oPhoneNumber.sPhoneNumber;
      this.sPhoneExtension =
        this.oGeneralInformation.oPhoneNumber.sPhoneExtension;
    },
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[_A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    withTrim(val) {
      return val !== null ? val.trim() : val;
    },
    fillData(results) {
      this.bModify = false;
      this.oGeneralInformation = results;
      this.oEnterprise = results.oEnterprise;
      this.oPhoneNumber = results.oPhoneNumber;

      this.sPublicName = results.sPublicName;
      this.sEmail = results.sEmail;
      this.sContactFullName = results.sContactFullName;

      this.sCountryCallingCode = results.oPhoneNumber.sCountryCallingCode;
      this.sAreaCallingCode = results.oPhoneNumber.sAreaCallingCode;
      this.sPhoneNumber = results.oPhoneNumber.sPhoneNumber;
      this.sPhoneExtension = results.oPhoneNumber.sPhoneExtension;
    },
    editCustomer() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          if (this.getPermissionsActionsGlobal()) {
            const config = {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
              },
              payload = {
                sPublicName: this.sPublicName,
                // sEmail: this.sEmail,
                sContactFullName: this.sContactFullName,

                sCountryCallingCode: this.sCountryCallingCode,
                sAreaCallingCode: this.sAreaCallingCode,
                sPhoneNumber: this.sPhoneNumber,
                sPhoneExtension: this.sPhoneExtension,
              };

            DB.put(
              `${URI}/enterprises/${this.$store.state.sEnterpriseId}/final-customers/${this.$route.params.id}`,
              payload,
              config
            )
              .then((response) => {
                this.bLoading = false;
                this.getInformation();
                this.mixSuccess(response.data.message);
                // this.onReturn();
                // this.$store.commit("refresher", true);
              })
              .catch((error) => {
                this.bLoading = false;

                this.mixError(error.response.data.message);
              });
          } else {
            this.mixError(this.$store.state.sMessageErrorAccess);
            this.bLoading = false;
            this.getInformation();
          }
        })
        .catch((error) => {
          this.mixError(error.message, error.code);
        });
    },
    // Reepresentante
    setCodigoPais: function (e) {
      this.sCountryCallingCode = e;
      this.validationForm();
    },
    setCodigoArea: function (e) {
      this.sAreaCallingCode = e;
      this.validationForm();
    },
    setNumeroTelefono: function (e) {
      this.sPhoneNumber = e;
      this.validationForm();
    },
    setExtension: function (e) {
      this.sPhoneExtension = e;
      this.validationForm();
    },

    validationForm: function () {
      this.bModifyBtn =
        (this.withTrim(this.sPublicName) === "" ||
          this.sPublicName === this.oGeneralInformation.sPublicName) &&
        (this.withTrim(this.sEmail) === "" ||
          this.sEmail === this.oGeneralInformation.sEmail) &&
        this.sCountryCallingCode === this.oPhoneNumber.sCountryCallingCode &&
        (this.withTrim(this.sAreaCallingCode) === "" ||
          this.sAreaCallingCode === this.oPhoneNumber.sAreaCallingCode) &&
        (this.withTrim(this.sPhoneNumber) === "" ||
          this.sPhoneNumber === this.oPhoneNumber.sPhoneNumber) &&
        this.sPhoneExtension === this.oPhoneNumber.sPhoneExtension &&
        (this.withTrim(this.sContactFullName) === "" ||
          this.sContactFullName === this.oGeneralInformation.sContactFullName);
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
    bAdminEndCustomer() {
      return this.$store.state.bAdminEndCustomer;
    },
  },
  watch: {
    tab() {
      if (this.tab === 0) {
        this.getInformation();
      }
    },
    refresh() {
      this.getInformation();
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.content-btns {
  display: flex;
  margin-top: 25px;
}

.btn-discard {
  width: 100%;
  display: flex;
}

.btn-left {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}

.btn-right {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}

.btn-right-two {
  display: flex;
  justify-content: flex-end;
  width: 200px;
}

.btn-add {
  width: 100%;
  display: flex;
}

.button-delete,
.button-modify {
  width: 200px;
}

@media (max-width: 599.99px) {
  .content-btns {
    display: block;
  }

  .btn-discard {
    margin-right: 0px;
    margin-bottom: 20px;
    display: block;
  }

  .btn-add {
    width: 100%;
    display: block;
  }

  .btn-left {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .btn-right {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 15px;
  }

  .button-modify {
    width: 100%;
  }

  .button-delete {
    width: 100%;
  }

  .btn-right-two {
    display: block;
    justify-content: flex-start;
    width: 100%;
  }

  .btn-edit,
  .btn-changes {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
