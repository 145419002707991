<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <div class="content-title-section mb-5">Información general</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-if="editData"
              v-model="nombre"
              type="text"
              label="Nombres"
              placeholder="Nombres"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
            >
          <template slot="label">
            <span>Nombres <span class="color-red">*</span></span>
          </template>
          </v-text-field>
            <div v-else>
              <p class="txt-label-global">Nombres</p>
              <p class="txt-value-global">{{ nombre }}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-if="editData"
              v-model="apellido"
              type="text"
              label="Apellidos"
              placeholder="Apellidos"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
            >
        <template slot="label">
          <span>Apellidos <span class="color-red">*</span></span>
        </template>
          </v-text-field>
            <div v-else>
              <p class="txt-label-global">Apellidos</p>
              <p class="txt-value-global">{{ apellido }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-if="editData"
              v-model="cargoLaboral"
              type="text"
              label="Cargo laboral"
              placeholder="Cargo laboral"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
            >
          <!-- <template slot="label">
            <span>Cargo laboral <span class="color-red">*</span></span>
          </template> -->
          </v-text-field>
            <div v-else>
              <p class="txt-label-global">Cargo laboral</p>
              <p class="txt-value-global">{{ cargoLaboral }}</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-if="editData"
              v-model="correoElectronico"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              readonly
              disabled
              @keypress="formatEmail"
            >
          <template slot="label">
            <span>Correo electrónico <span class="color-red">*</span></span>
          </template>
          </v-text-field>
            <div v-else>
              <p class="txt-label-global">Correo electrónico</p>
              <p class="txt-value-global">{{ correoElectronico }}</p>
            </div>
          </div>
        </v-col>
        <v-col v-if="editData" cols="12" sm="8" md="8" lg="6">
          <!-- Start: phone component -->
          <country-code-input-component
            v-if="editData"
            class=""
            :bObligatoryField="true"
            @setCodigoPais="setCodigoPais"
            @setCodigoArea="setCodigoArea"
            @setNumeroTelefono="setNumeroTelefono"
            @setExtension="setExtension"
            :_bDialogAdd="false"
            :_codigoPais="codigoPais"
            :_codigoArea="codigoArea"
            :_numeroTelefono="numeroTelefono"
            :_extension="extension"
            :_type="true"
          />
          <!-- End: phone component -->
        </v-col>
        <v-col v-else cols="12" class="pa-0">
          <div>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <div>
                    <p class="txt-label-global">Código de país</p>
                    <p class="txt-value-global">+{{ codigoPais }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div>
                    <p class="txt-label-global">Código de área</p>
                    <p class="txt-value-global">{{ codigoArea }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div>
                    <p class="txt-label-global">Número de teléfono</p>
                    <p class="txt-value-global">{{ numeroTelefono }}</p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <div>
                    <p class="txt-label-global">Extensión</p>
                    <p class="txt-value-global">{{ extension }}</p>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";
import lodash from "lodash";

export default {
  name: "LayoutDetailEmployeeForm",
  props: {
    items: {
      type: Object,
    },
    editData: {
      type: Boolean,
    },
  },
  data() {
    return {
      itemsOriginal: {},
      nombre: "",
      apellido: "",
      cargoLaboral: "",
      correoElectronico: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
    };
  },
  beforeMount() {
    this.fillContent();
  },
  methods: {
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[_A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    setCodigoPais: function (val) {
      this.codigoPais = val;
    },
    setCodigoArea: function (val) {
      this.codigoArea = val;
    },
    setNumeroTelefono: function (val) {
      this.numeroTelefono = val;
    },
    setExtension: function (val) {
      this.extension = val;
    },
    fillContent: function () {
      this.itemsOriginal = this.items;
      this.nombre = this.items.sName;
      this.apellido = this.items.sLastname;
      this.cargoLaboral = this.items.oEnterpriseInfo.sJobTitle;
      this.correoElectronico = this.items.oGeneralInfo.sEmail;
      this.codigoPais = this.items.oContactInfo.sCountryCallingCode;
      this.codigoArea = this.items.oContactInfo.sAreaCallingCode;
      this.numeroTelefono = this.items.oContactInfo.sPhoneNumber;
      this.extension = this.items.oContactInfo.sPhoneExtension;
    },
    newArrData: function () {
      let new_arr_data = {
        nombre: this.nombre,
        apellido: this.apellido,
        cargoLaboral: this.cargoLaboral,
        correoElectronico: this.correoElectronico,
        codigoPais: this.codigoPais,
        codigoArea: this.codigoArea,
        numeroTelefono: this.numeroTelefono,
        extension: this.extension,
      };
      this.$emit("setDataForm", new_arr_data);
    },
    discardChanges: function () {
      this.nombre = this.itemsOriginal.sName;
      this.apellido = this.itemsOriginal.sLastname;
      this.cargoLaboral = this.itemsOriginal.oEnterpriseInfo.sJobTitle;
      this.correoElectronico = this.itemsOriginal.oGeneralInfo.sEmail;
      this.codigoPais = this.itemsOriginal.oContactInfo.sCountryCallingCode;
      this.codigoArea = this.itemsOriginal.oContactInfo.sAreaCallingCode;
      this.numeroTelefono = this.itemsOriginal.oContactInfo.sPhoneNumber;
      this.extension = this.itemsOriginal.oContactInfo.sPhoneExtension;
    },
  },
  watch: {
    editData: function () {
      if (!this.editData) this.discardChanges();
    },
    nombre: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    apellido: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    cargoLaboral: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    correoElectronico: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    codigoPais: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    codigoArea: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    numeroTelefono: lodash.debounce(function () {
      this.newArrData();
    }, 500),
    extension: lodash.debounce(function () {
      this.newArrData();
    }, 500),
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 0px 15px;
  width: fit-content;
}

.font-text-label {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
  font-weight: 500;
}

.font-text-value {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}
</style>