<template>
  <div class="display-flex align-items-center">
    <div @click="onReturn" class="content-header">
      <p class="txt-title poppins mb-0">
        <v-icon class="icon" color="#283c4d"> mdi-arrow-left-thick </v-icon>
        Detalle de empleado
        <span class="txt-name mb-0">{{ name }}</span>
      </p>
    </div>
    <v-spacer />
    <div @click="bAdminEmployees? changeStatus($route.params.id, !bPlatformAccess):''"
      :class="!bPlatformAccess ? 'active-platform' : 'inactive-platform'">
      {{ bPlatformAccess ? "Bloquear" : "Desbloquear" }}
      <v-icon v-if="!bPlatformAccess" :color="!bPlatformAccess ? '#2759A2' : '#E33F3F'" size="14px">
        mdi-lock-open-outline
      </v-icon>
      <v-icon v-else :color="!bPlatformAccess ? '#2759A2' : '#E33F3F'" size="14px">
        mdi-lock-outline
      </v-icon>
    </div>
    <confirm-change-status-component :oChangeStatusOptions="oChangeStatusOptions"
      @closeChangeStatus="closeChangeStatus" />
  </div>
</template>

<script>
import ConfirmChangeStatusComponent from "@/components/ConfirmChangeStatus.vue";

export default {
  name: "LayoutDetailEmployeeHeader",
  props: {
    items: {
      type: Object,
    },
  },
  data() {
    return {
      name: "",
      bPlatformAccess: false,
      oChangeStatusOptions: {},
    };
  },
  beforeMount() {
    this.bPlatformAccess = this.items.oGeneralInfo.bPlatformAccess;
    this.name = this.items.sName + " " + this.items.sLastname;
  },
  methods: {
    onReturn: function () {
      this.$router.push({ name: "employee" });
    },
    closeChangeStatus: function () {
      this.oChangeStatusOptions = {
        active: false,
        api: "",
        title: "",
        action: "",
        description: "",
      };
    },
    changeStatus: function (sEnterpriseId, bAction) {
      this.oChangeStatusOptions = {
        active: true,
        api: `${URI}/enterprises/${this.$store.state.sEnterpriseId}/customers/${this.items.sCustomerId}`,
        payload: { bPlatformAccess: bAction },
        bInputs: false,
        title: bAction == true ? "Desbloqueo de empleado" : "Bloqueo de empleado",
        action: bAction,
        description:
          bAction == true
            ? "La siguiente acción restablecerá su acceso a la plataforma.</br>¿Desea continuar?"
            : "La siguiente acción restringirá su acceso a la plataforma.</br> ¿Desea continuar?",
        sTextBtn: bAction == true ? "Desbloquear" : "Bloquear",
      };
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
    bAdminEmployees() {
      return this.$store.state.bAdminEmployees
    }
  },
  watch: {
    refresh() {
      this.bPlatformAccess = this.items.oGeneralInfo.bPlatformAccess;
    },
  },
  components: {
    ConfirmChangeStatusComponent,
  },
};
</script>

<style scoped>
.content-return:hover {
  opacity: 0.8;
  cursor: pointer;
  width: fit-content;
}

.txt-title {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.txt-name {
  text-align: left;
  font-size: 32px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  color: #2759a2;
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  font-weight: bold;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 10px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  font-weight: bold;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 10px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 600px) {

  .return-text,
  .txt-name {
    font-size: 25px;
  }
}
</style>