<template>
  <v-dialog
    v-model="bDialogAdd"
    persistent
    :width="`${screenWidth}%`"
  >
    <div class="content-card-dialog">
      <v-btn
        class="content-card-button-close"
        @click="closeDialog"
        icon
      >
        <v-icon
          color="#000"
          size="16px"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <p class="content-card-txt-title poppins">Añadir empleado</p>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="nombre"
                type="text"
                label="Nombres"
                placeholder="Nombres"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="apellido"
                type="text"
                label="Apellidos"
                placeholder="Apellidos"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="cargoLaboral"
                type="text"
                label="Cargo laboral"
                placeholder="Cargo laboral"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div>
              <v-text-field
                v-model="correoElectronico"
                type="text"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <div>
              <v-text-field
                v-model="codigoPais"
                type="text"
                label="Código de país"
                placeholder="+00"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <div>
              <v-text-field
                v-model="codigoArea"
                type="text"
                label="Código de área"
                placeholder="00"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <div>
              <v-text-field
                v-model="numeroTelefono"
                type="text"
                label="Número de teléfono"
                placeholder="0000 0000"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <div>
              <v-text-field
                v-model="extension"
                type="text"
                label="Extensión"
                placeholder="0000"
                dense
                color="#2759A2"
                class="global-inputs poppins mb-9"
                maxlength="85"
                persistent-placeholder
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="content-buttons">
        <v-container
          class="pa-0"
          fluid
        >
          <v-row>
            <v-col
              cols="12"
              sm="5"
            >
              <div class="display-flex align-items-center justify-content-flex-start">
                <v-btn
                  @click="closeDialog"
                  class="button-secondary poppins"
                  elevation="0"
                  width="100%"
                >
                  Regresar
                </v-btn>
              </div>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="5"
            >
              <div class="display-flex align-items-center justify-content-flex-end">
                <v-btn
                  :loading="bLoading"
                  :disabled="!validationForm"
                  class="button-primary poppins"
                  elevation="0"
                  width="100%"
                >
                  Añadir
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "LayoutSimCardsAdd",
  props: {
    bDialogAdd: {
      type: Boolean,
    },
  },
  data() {
    return {
      bLoading: false,
      screenWidth: 0,
      nombre: "",
      apellido: "",
      cargoLaboral: "",
      correoElectronico: "",
      codigoPais: "",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.screenWidth = 60;
      } else {
        this.screenWidth = 100;
      }
    },
    closeDialog: function () {
      this.$emit("setDialogAdd", false);
      this.nombre = "";
      this.apellido = "";
      this.cargoLaboral = "";
      this.correoElectronico = "";
      this.codigoPais = "";
      this.codigoArea = "";
      this.numeroTelefono = "";
      this.extension = "";
    },
  },
  computed: {
    validationForm: function () {
      return (
        this.nombre !== "" &&
        this.apellido !== "" &&
        this.cargoLaboral !== "" &&
        this.correoElectronico !== "" &&
        this.codigoPais !== "" &&
        this.codigoArea !== "" &&
        this.numeroTelefono !== "" &&
        this.extension !== ""
      );
    },
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .content-buttons button:nth-child(1) {
    width: 100%;
  }

  .content-buttons button:nth-child(2) {
    width: 100%;
    margin-left: 0px !important;
    margin-top: 20px;
  }
}
</style>