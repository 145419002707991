<template>
  <div class="content-buttons mb-5">
    <v-btn @click="onReturn" class="button-secondary" elevation="0">
      Regresar
    </v-btn>
    <v-btn @click="add" :disabled="!validationForm" class="button-primary" elevation="0" :loading="bLoading">
      Añadir
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutAddEmployeeButtons",
  props: {
    aPermissions: {
      type: Array,
    },
    nombre: {
      type: String,
    },
    apellido: {
      type: String,
    },
    cargoLaboral: {
      type: String,
    },
    correoElectronico: {
      type: String,
    },
    codigoPais: {
      type: String,
    },
    codigoArea: {
      type: String,
    },
    numeroTelefono: {
      type: String,
    },
    extension: {
      type: String,
    },
    bPermission: {
      type: Boolean
    }
  },
  data() {
    return {
      bLoading: false,
    };
  },
  methods: {
    onReturn: function () {
      this.$router.push({
        name: "employee",
      });
    },
    add: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
        if (this.getPermissionsActionsGlobal()) {
          const config = {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
          },
            payload = {
              sName: this.nombre,
              sLastname: this.apellido,
              sEmail: this.correoElectronico,
              sJobTitle: this.cargoLaboral,
              sPhoneNumber: this.numeroTelefono,
              sCountryCallingCode: this.codigoPais,
              sAreaCallingCode: this.codigoArea,
              sPhoneExtension: this.extension,
              aCustomerPermissions: this.aPermissions.map((e) => {
                return {
                  sCustomerModuleId: e.sCustomerModuleId,
                  aPermissions: [
                    {
                      sPermissionId: e.sShowPermissionId,
                      bBoolean: e.bShow
                    },
                    {
                      sPermissionId: e.sAdminPermissionId,
                      bBoolean: e.bAdmin
                    }
                  ]

                }
              })

            };
          DB.post(
            `${URI}/enterprises/${this.$store.state.sEnterpriseId}/customers`,
            payload,
            config
          )
            .then((response) => {
              this.bLoading = false;

              this.mixSuccess(response.data.message);
              this.onReturn();
              this.$store.commit("refresher", true);
            })
            .catch((error) => {
              this.bLoading = false;

              this.mixError(error.response.data.message);
            });

        } else {
          this.mixError(this.$store.state.sMessageErrorAccess);
          this.onReturn();
          this.$store.commit("refresher", true);
        }
      }).catch((error) => {
        this.mixError(error.message, error.code);
      });

    },
  },
  computed: {
    validationForm: function () {
      return (
        this.nombre !== "" &&
        this.apellido !== "" &&
        // this.cargoLaboral !== "" &&
        this.correoElectronico !== "" &&
        this.codigoPais !== "" &&
        this.codigoArea !== "" &&
        this.numeroTelefono !== "" &&
        this.bPermission
      );
    },
  },
};
</script>

<style scoped>
.content-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.content-buttons .button-primary,
.content-buttons .button-secondary {
  height: 40px;
  font-size: 16px !important;
  padding: 0px 30px;
}

.content-buttons .button-primary {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .content-buttons {
    display: block;
  }

  .content-buttons .button-primary,
  .content-buttons .button-secondary {
    width: 100%;
  }

  .content-buttons .button-primary {
    margin-left: 0px;
    margin-top: 20px;
  }
}
</style>