import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import OutsideContent from "@/views/OutsideContent.vue";
import InsideContent from "@/views/InsideContent.vue";
import Dashboard from "@/views/Dashboard.vue";
import Sims from "@/views/Sims.vue";
import AddSims from "@/views/AddSims.vue";
import DetailSims from "@/views/DetailSims.vue";
import Profile from "@/views/Profile.vue";
import Employee from "@/views/Employee.vue";
import SimCards from "@/views/SimCards.vue";
import DetailSimCards from "@/views/DetailSimCards.vue";
import SimCardsLocal from "@/views/SimCardsLocal.vue";
import DetailSimCardsLocal from "@/views/DetailSimCardsLocal.vue";

import DetailEmployee from "@/views/DetailEmployee.vue";
import AddEmployee from "@/views/AddEmployee.vue";
import Customer from "@/views/Customer.vue";
import DetailCustomer from "@/views/DetailCustomer.vue";
import DetailCustomerSimCard from "@/views/DetailCustomerSimCard.vue";
import AddCustomer from "@/views/AddCustomer.vue";
import Api from "@/views/Apis.vue";
import reroute from "../reroute";
import Notifications from "@/views/Notifications.vue";
import Audit from "@/views/Audit.vue";
import Prebilling from "@/views/Prebilling.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      authentication: false,
    },
    children: [
      {
        path: "/login",
        name: "login",
        meta: {
          authentication: false,
        },
        // route level code-splitting
        component: Login,
      },
      {
        path: "/forgot-password",
        name: "forgotPassword",
        meta: {
          authentication: false,
        },
        // route level code-splitting
        component: ForgotPassword,
      },
    ],
    component: OutsideContent,
    redirect: "/login",
  },
  {
    path: "*",
    meta: {
      authentication: false,
    },
    // route level code-splitting
    redirect: "/login",
  },
  {
    path: "/client",
    name: "client",
    meta: {
      authentication: true,
    },
    // route level code-splitting
    component: InsideContent,
    redirect: "/client/dashboard",
    // routes that are accessible to clients.
    children: [
      {
        path: "/client/inicio",
        name: "inicio",
        meta: {
          authentication: true,
          permission: "1",
        },
        // route level code-splitting
        component: Dashboard,
      },
      {
        path: "/client/sims",
        name: "sims",
        meta: {
          authentication: true,
          permission: "7ca28590-682d-4359-91fa-0c05d7efc5b4",
        },
        // route level code-splitting
        component: Sims,
      },
      {
        path: "/client/sims/add",
        name: "addSims",
        meta: {
          authentication: true,
          permission: "7ca28590-682d-4359-91fa-0c05d7efc5b4",
        },
        // route level code-splitting
        component: AddSims,
      },
      {
        path: "/client/sims/:id",
        name: "detailSims",
        meta: {
          authentication: true,
          permission: "7ca28590-682d-4359-91fa-0c05d7efc5b4",
        },
        // route level code-splitting
        component: DetailSims,
      },
      {
        path: "/client/profile",
        name: "profile",
        meta: {
          authentication: true,
          permission: "4",
        },
        // route level code-splitting
        component: Profile,
      },
      {
        path: "/client/employee",
        name: "employee",
        meta: {
          authentication: true,
          permission: "29c9c299-ff00-4498-afd4-2e069cc4b7c9",
        },
        // route level code-splitting
        component: Employee,
      },
      {
        path: "/client/employee/detail/:id",
        name: "detailEmployee",
        meta: {
          authentication: true,
          permission: "29c9c299-ff00-4498-afd4-2e069cc4b7c9",
        },
        // route level code-splitting
        component: DetailEmployee,
      },
      {
        path: "/client/employee/add",
        name: "AddEmployee",
        meta: {
          authentication: true,
          permission: "29c9c299-ff00-4498-afd4-2e069cc4b7c9",
        },
        // route level code-splitting
        component: AddEmployee,
      },
      {
        path: "/client/sim-cards",
        name: "sim-cards",
        meta: {
          authentication: true,
          permission: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        // route level code-splitting
        component: SimCards,
      },
      {
        path: "/client/sim-cards/:id",
        name: "detailSimCards",
        meta: {
          authentication: true,
          permission: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        // route level code-splitting
        component: DetailSimCards,
      },
      {
        path: "/client/sim-cards-local",
        name: "sim-cards-local",
        meta: {
          authentication: true,
          permission: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        // route level code-splitting
        component: SimCardsLocal,
      },
      {
        path: "/client/sim-cards-local/:id",
        name: "detailSimCardsLocal",
        meta: {
          authentication: true,
          permission: "a6882b11-6811-4c71-9ffa-449968c18118",
        },
        // route level code-splitting
        component: DetailSimCardsLocal,
      },
      {
        path: "apis",
        name: "apis",
        meta: {
          authentication: true,
          permission: "1",
        },
        // route level code-splitting
        component: Api,
      },
      {
        path: "/client/customer",
        name: "customer",
        meta: {
          authentication: true,
          permission: "56411fa1-20a9-4980-b315-1123e624afa9",
        },
        // route level code-splitting
        component: Customer,
      },
      {
        path: "/client/customer/add",
        name: "AddCustomer",
        meta: {
          authentication: true,
          permission: "56411fa1-20a9-4980-b315-1123e624afa9",
        },
        // route level code-splitting
        component: AddCustomer,
      },
      {
        path: "/client/customer/detail/:id",
        name: "detailCustomer",
        meta: {
          authentication: true,
          permission: "56411fa1-20a9-4980-b315-1123e624afa9",
        },
        // route level code-splitting
        component: DetailCustomer,
      },
      {
        path: "/client/customer/detail/:id/sim-card/:idSimCard",
        name: "detailCustomerSimCard",
        meta: {
          authentication: true,
          permission: "56411fa1-20a9-4980-b315-1123e624afa9",
        },
        // route level code-splitting
        component: DetailCustomerSimCard,
      },
      {
        path: "/admin/notifications",
        name: "notifications",
        meta: {
          authentication: true,
          permission: "5",
        },
        // route level code-splitting
        component: Notifications,
      },
      {
        path: "/admin/audit",
        name: "audit",
        meta: {
          authentication: true,
          permission: "957abd40-832d-47e0-a066-4d2fd2c82fde",
        },
        // route level code-splitting
        component: Audit,
      },
      {
        path: "/admin/prebilling",
        name: "prebilling",
        meta: {
          authentication: true,
          permission: "1929e76c-8789-4324-881b-32587b7966bf",
        },
        // route level code-splitting
        component: Prebilling,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// control router authentication
router.beforeEach((to, from, next) => {
  store.commit("setMenuGlobal");
  store.commit(
    "setShowBtnWhatsapp",
    !store.state.aDontShowBtnWhatsapp.filter((e) => e === to.name).length > 0
  );

  if (to.name !== "sim-cards" && to.name !== "detailSimCards") {
    store.commit("setFilterTypeGlobal", null);
    store.commit("setSearchGlobal", "");
    store.commit("setCurrentPageGlobal", 1);
  }
  if (to.name !== "sim-cards-local" && to.name !== "detailSimCardsLocal") {
    store.commit("setFilterTypeLocal", null);
    store.commit("setSearchLocal", "");
    store.commit("setCurrentPageLocal", 1);
  }
  if (to.name == "login" || to.name == "forgotPassword") {
    if (store.state.sToken !== "") {
      next({
        name: "inicio",
      });
    } else {
      next();
    }
  } else {
    store
      .dispatch("getNotificationGlobal")
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
    if (store.state.sToken !== "") {
      if (store.state.aPermissions === null) {
        next();
      } else {
        if (
          store.state.aPermissions
            .map((e) => e.sCustomerModuleId)
            .includes(to.meta.permission, 0)
        ) {
          next();
        } else {
          next({
            name: reroute.redirectUser(store.state.aPermissions),
          });
        }
      }
    } else {
      next({
        path: "/",
      });
    }
  }
});
// router.beforeEach((to, from, next) => {
//   var bAuthenticated = (store.state.sToken !== '');
//   if (to.meta.authentication) {
//     if (bAuthenticated) {
//       next()
//     } else {
//       next('/login')
//     }
//   } else {
//     if ((to.name == "login" || to.name == 'forgot-password') && bAuthenticated) {
//       next('/client/inicio');
//     } else {
//       next()
//     }
//   }
// })

export default router;
