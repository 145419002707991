<template>
  <div>
    <layout-profile-content />
  </div>
</template>

<script>
import LayoutProfileContent from "@/layouts/Profile/Content.vue";

export default {
  name: "ProfileView",
  components: {
    LayoutProfileContent,
  },
};
</script>