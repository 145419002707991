<template>
  <div>
    <!-- <layout-sim-cards-detail-customer-header @setDialogAdd="setDialogAdd" /> -->
    <layout-sim-cards-detail-customer-searchbar
      @setSearch="setSearch"
      @setPlanId="setPlanId"
      @setPayload="setPayload"
      :bTab="tab"
    />
    <layout-sim-cards-detail-customer-content
      :sSearch="sSearch"
      @setOptions="setOptions"
      :sPlanId="sPlanId"
      :oPayload="oPayload"
    />
    <!-- general component  -->
    <delete-component :oOptions="oOptions" @setOptions="setOptions" />
  </div>
</template>

<script>
import LayoutSimCardsDetailCustomerHeader from "@/layouts/DetailCustomer/Tabs/SimCards/Header.vue";
import LayoutSimCardsDetailCustomerContent from "@/layouts/DetailCustomer/Tabs/SimCards/Content.vue";
import LayoutSimCardsDetailCustomerSearchbar from "@/layouts/DetailCustomer/Tabs/SimCards/Searchbar.vue";

export default {
  name: "SimCardsDetailCustomer",
  props:{
    tab:Number
  },
  data() {
    return {
      sSearch: "",
      bDialogAdd: false,
      oOptions: {},
      sPlanId: undefined,
      oPayload: {},
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setPlanId: function (val) {
      this.sPlanId = val;
    },
    setPayload(oPayload) {
      this.oPayload = oPayload;
    },
  },
  components: {
    LayoutSimCardsDetailCustomerHeader,
    LayoutSimCardsDetailCustomerContent,
    LayoutSimCardsDetailCustomerSearchbar,
  },
};
</script>