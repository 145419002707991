<template>
  <div
    @click="$router.push({ name: 'customer' })"
    class="content-header"
  >
    <p class="txt-title poppins mb-0">
      <v-icon color="#000">
        mdi-arrow-left-thick
      </v-icon>
      Añadir cliente
    </p>
  </div>
</template>

<script>
export default {
  name: "LayoutAddEmployeeHeader",
};
</script>

<style scoped>
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 600;
}

.content-header {
  width: fit-content;
}

.content-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media (max-width: 600px) {
  .txt-title {
    font-size: 20px;
  }
}
</style>