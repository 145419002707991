<template>
  <div>
    <layout-apis-content />
  </div>
</template>

<script>
import LayoutApisContent from "@/layouts/Apis/Content.vue";

export default {
  data() {
    return {

    }
  },
  components: {
    LayoutApisContent
  }
}
</script>

<style>
</style>