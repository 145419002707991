<template>
  <div class="display-flex align-items-center">
    <div
      @click="onReturn"
      class="display-flex align-items-center content-return return-text"
    >
      <v-icon
        class="icon"
        color="#000"
      >
        mdi-arrow-left-thick
      </v-icon>
      Crear solicitud de tarjetas SIM
    </div>
    <v-spacer />
  </div>
</template>

<script>
export default {
  name: "LayoutSimsAddHeader",
  data() {
    return {};
  },
  methods: {
    onReturn: function () {
      this.$router.push({ name: "sims" });
    },
  },
};
</script>

<style scoped>
.content-return:hover {
  opacity: 0.8;
  cursor: pointer;
  width: fit-content;
}

.return-text {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  display: block;
}

.icon {
  font-size: 35px;
}

.txt-name {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
  color: #2759a2;
}

@media (max-width: 600px) {
  .return-text,
  .icon {
    font-size: 25px;
  }
}
</style>