<template>
  <div class="content-navbar display-flex align-items-center">
    <div class="display-flex align-items-center">
      <v-btn v-if="bResponsive" icon @click="toggleMenu">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <v-btn @click="toogleMenuResponsive" v-else icon>
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <img class="logo-navbar" src="@/assets/images/logo-color.svg" alt=""
        @click="$router.push({ name: 'inicio' }).catch((e) => { })">
    </div>
    <v-spacer />
    <icon-notification-component @setOptions="deleteItem"/>
    <icon-profile-component />
  </div>
</template>

<script>
import IconProfileComponent from "@/components/IconProfile.vue";
import IconNotificationComponent from "@/components/IconNotification.vue";

export default {
  name: "NavbarComponent",
  data() {
    return {
      bResponsive: null,
    };
  },
  beforeMount() {
    this.selected = this.$route.name;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 960) {
        this.bResponsive = true;
      } else {
        this.bResponsive = false;
        this.$store.commit("setMenuResponsive", false);
      }
    },
    toggleMenu: function () {
      this.$store.commit("setMenu", !this.$store.state.bMenu);
    },
    toogleMenuResponsive: function () {
      this.$store.commit(
        "setMenuResponsive",
        !this.$store.state.bMenuResponsive
      );
    },
    deleteItem(options) {
      this.$emit("setOptions", options);
    }
  },
  components: {
    IconProfileComponent,
    IconNotificationComponent
  },
};
</script>

<style scoped>
.logo-navbar {
  width: 200px;
  margin-bottom: 5px;

}

.logo-navbar:hover {
  cursor: pointer;
}

.content-navbar {
  background-color: #f2f6fe;
  height: 70px;
  padding: 0px 20px;
}
</style>