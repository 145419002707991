<template>
  <div class="content-global" :style="{'height': iHeight + 'px','min-height': iHeight + 'px' }">
    <content-header-dashboard :bDashboardWithoutPermissions="bDashboardWithoutPermissions" :tLastUpdate="tLastUpdate" />
    <!-- <v-divider></v-divider> -->
    <div v-if="bDashboardWithoutPermissions" class="content-dashboard-without-permissions">
      <p class="txt-dashboard-without-permissions-title">No cuentas con los permisos necesarios para visualizar las
        estadísticas</p>
      <p class="txt-dashboard-without-permissions-information">Contacta a tu administrador en caso de ser necesario.</p>
      <img :style="{'height': iHeightImgEmptyDashboard + 'px','min-height': iHeightImgEmptyDashboard + 'px' }"
        class="content-img-placeholder-dashboard" src="@/assets/images/placeholder-dashboard.svg" alt="" />
    </div>
    <div v-else>
      <content-promotions-component @setExistPromotion="setExistPromotion" />
      <div class="content-dashboard-all">
        <div class="content-dashboard-left-all">
          <content-plans-dashboard class="content-dashboard-left-plans" refs="ContentPlansDashboard" :aPlans="aPlans" />
          <div class="content-dashboard-left-customer-prebilling" :class="bChangeSize?'d-block':''">
            <content-customer-dashboard class="content-dashboard-left-customer" :aCustomers="aCustomers" />
            <content-prebilling-dashboard class="content-dashboard-left-prebilling" :oSubtotal="oSubtotal"
              :oPeriod="oPeriod" />
          </div>
        </div>
        <div class="content-dashboard-right-all">
          <content-sim-cards-top-dashboard :aSimCardsTop="aSimCardsTop" />
        </div>
      </div>
    </div>

    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import ContentHeaderDashboard from "./Header.vue";
import ContentPlaceholderEmpty from "./ContentPlaceholderEmpty.vue";
import ContentPlansDashboard from "./Plans/Content.vue";
import ContentCustomerDashboard from "./Customers/Content.vue";
import ContentPrebillingDashboard from "./Prebilling/Content.vue";
import ContentSimCardsTopDashboard from "./SimCardsTop/Content.vue";
export default {
  data() {
    return {
      bExistPromotion: false,
      bLoading: true,
      iHeight: 0,
      iHeightImgEmptyDashboard: 0,

      bChangeSize: false,
      aPlans: [],
      aCustomers: [],
      oSubtotal: {},
      aSimCardsTop: [],
      oPeriod: {},
      bDashboardWithoutPermissions: false,
      tLastUpdate: 'dd/mm/yyyy a las 00:00'
    };
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeMount() {
    this.getStats();
  },
  methods: {
    setExistPromotion(bExistPromotion) {
      this.bExistPromotion = bExistPromotion;
    },
    setLoading(bLoading) {
      this.bLoading = bLoading;
    },
    onResize() {

      if (window.innerWidth > 599.99) {
        this.iHeight = window.innerHeight - 90;
        this.iHeightImgEmptyDashboard = window.innerHeight - 160;
      } else {
        this.iHeight = window.innerHeight - 90;
        this.iHeightImgEmptyDashboard = window.innerHeight - 355;

      }
      if (this.bMenu) {
        if (window.innerWidth > 959.99 && window.innerWidth < 1560) {
          this.bChangeSize = true;
        } else {
          this.bChangeSize = false;
        }
      } else {
        if (window.innerWidth > 959.99 && window.innerWidth < 1345) {
          this.bChangeSize = true;

        } else {
          this.bChangeSize = false;
        }
      }

    },
    getStats() {
      this.bLoading = true;
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {

          if (this.getPermissionsActionsGlobal()) {
            DB.get(
              `${URI}/enterprises/${this.$store.state.sEnterpriseId}/stats`,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.sToken}`,
                },
                params: {

                },
              }
            )
              .then((response) => {

                this.oPeriod = response.data.results.oPeriod;
                this.aPlans = response.data.results.aCustomPlans.map((e) => {
                  return {
                    ...e,
                    iActive: e.oTotalSIMCards.iActiveSIM,
                    iInactive: e.oTotalSIMCards.iDeactiveSIM,
                    iReadyToActivate: e.oTotalSIMCards.iActivationReady,
                    iTest: e.oTotalSIMCards.iTestSIM,
                    sDataOfPool: e.oDataConsumption.dPool,
                    dRentalPrice: e.oDataConsumption.dUnitPrice,
                    dCurrent: e.oDataConsumption.dCurrent,
                    dProcessBar: (e.oDataConsumption.dCurrent / e.oDataConsumption.dPool) * 100
                  }
                });
                this.aCustomers = response.data.results.aClients.map((e) => {
                  return {
                    sName: e.sPublicName,
                    iActive: e.oTotalSIMCards.iActiveSIM,
                    iInactive: e.oTotalSIMCards.iDeactiveSIM
                  }
                });
                this.oSubtotal = response.data.results.oSumary;
                this.oSubtotal['sDate'] = this.oPeriod.tInitialDate + ' - ' + this.oPeriod.tFinalDate
                this.aSimCardsTop = response.data.results.aTopSIMCards.map((e, i) => {
                  return {
                    sID: e.sICC,
                    sName: e.oEnterprise.sName,
                    sPlan: e.oCustomPlan.sName,
                    dCurrent: e.oDataConsumption.dCurrent,
                    dLimit: e.oDataConsumption.dLimit,
                    sCurrentAndLimit: e.oDataConsumption.dCurrent + 'MB' + '/' + e.oDataConsumption.dLimit + 'MB',

                    dProcessBar: (e.oDataConsumption.dCurrent / e.oDataConsumption.dLimit) * 100
                  }
                });
                this.tLastUpdate = response.data.results.tLastUpdate ? response.data.results.tLastUpdate : this.tLastUpdate

                this.bLoading = false;
                this.bDashboardWithoutPermissions = false;

                this.$store.commit("refresher", false);


              })
              .catch((error) => {
                this.bLoading = false;
                this.bDashboardWithoutPermissions = false;
                this.mixError(error.response.data.message, error.response.status);

              });
          } else {
            this.bLoading = false;
            this.bDashboardWithoutPermissions = true
          }
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.message, error.code);
        });
    },
    formatDateDashboardHeader(sDate) {
      // let aDate = 
    }
  },
  computed: {
    bMenu() {
      return this.$store.state.bMenu
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    bMenu() {
      this.onResize()
    },
    refresh: function () {
      this.getStats();
    },
  },
  components: { ContentHeaderDashboard, ContentPlaceholderEmpty, ContentCustomerDashboard, ContentPlansDashboard, ContentSimCardsTopDashboard, ContentPrebillingDashboard }
}
</script>


<style>
/*#region scroll content-dashboard */
.content-dashboard {
  overflow: auto !important;
  margin-bottom: 10px;

}

.content-dashboard::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

.content-dashboard::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e6e6e6 !important;
  border-radius: 10px !important;
}

.content-dashboard::-webkit-scrollbar-thumb {
  background: #a1acc3 !important;

  border-radius: 10px !important;
}

.content-dashboard::-webkit-scrollbar-thumb:hover {
  background: #a1acc3 !important;
}

/*#endregion scroll content-dashboard */

/* #region style default */


/* #region empty dashboard style */
.content-dashboard-without-permissions {
  position: relative;
}

.content-img-placeholder-dashboard {
  width: 100%;
}

.txt-dashboard-without-permissions-title {
  position: absolute;
  bottom: 30vh;
  right: 5%;
  width: 550px;
  text-align: end;
  color: #283C4D;
  font-size: 25px;
}

.txt-dashboard-without-permissions-information {
  position: absolute;
  bottom: 25vh;
  right: 5%;
  width: 350px;
  text-align: end;
  color: #A5AEC3;
  font-size: 16px;
}

/* #endregion empty dashboard style */


.content-sim-cards-top {
  overflow: auto;
}

.content-dashboard {
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  background-color: white;
  border-radius: 14px;
  padding: 14px;
}

.content-dashboard-card {
  display: flex;
  margin: 5px;
  height: 100%;
  border-radius: 14px;
  border: 1px solid #D8E5FF;
}

.content-dashboard-left {
  /* width: 75px; */
  /* max-width: 75px; */
  min-width: 75px;
  margin-right: 1px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.divider-dashboard-card {
  width: 1px;
  display: flex;
  align-self: center;
  height: 85%;
  background-color: #D8E5FF;
}

.content-dashboard-right {
  width: 100%;
}

.content-title-result-dashboard {
  display: flex;
  align-items: center;
  padding: 5px 5px 0px 5px;
}

/* #endregion style default */

/* #region style texts */
.txt-title-dashboard-card {
  font-size: 11px;
  color: #000000;
}

.txt-result-dashboard-card {
  font-size: 11px;

  margin-left: 1px;
}

.txt-result-number-dashboard-card {
  color: #2759A2;
  font-size: 35px;
  font-weight: 500;
}

.color-green {
  color: #55AA65
}

.color-red {
  color: #E01600;
}

.color-yellow {
  color: #FFB600;
}

.color-grey{
  color: #848484;
}

.font-bold {
  font-weight: bold;
}

/* #endregion style texts */


/*#region modo-responsivo */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .title-section-dashboard {
    margin-left: 15px !important;
  }

  /* #region empty dashboard style */
  .content-dashboard-without-permissions {
    position: relative;
  }

  .content-img-placeholder-dashboard {
    width: 100%;
  }

  .txt-dashboard-without-permissions-title {
    position: initial;
    bottom: 30vh;
    right: 5%;
    width: 100%;
    text-align: center;
    color: #283C4D;
    font-size: 18px;
  }

  .txt-dashboard-without-permissions-information {
    position: initial;
    bottom: 24vh;
    right: 0%;
    width: 100%;
    text-align: center;
    color: #A5AEC3;
    font-size: 15px;
  }

  /* #endregion empty dashboard style */

  /* #region style default */
  .content-sim-cards-top {
    overflow: auto;
  }

  .content-dashboard {
    width: 100%;
    margin-bottom: 25px;
    box-shadow: 0px 0px 0px 0px transparent !important;
    background-color: transparent;
    border-radius: 14px;
    padding: 14px;
  }

  .content-dashboard-card {
    display: flex;
    margin: 5px 0px 5px 0px;
    height: 100%;
    border-radius: 14px;
    border: 1px solid #D8E5FF;
    background-color: white;
  }

  .content-dashboard-left {
    /* width: 75px; */
    /* max-width: 75px; */
    min-width: 75px;
    margin-right: 1px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .divider-dashboard-card {
    width: 1px;
    display: flex;
    align-self: center;
    height: 85%;
    background-color: #D8E5FF;
  }

  .content-dashboard-right {
    width: 100%;
  }

  .content-title-result-dashboard {
    display: flex;
    align-items: center;
    padding: 5px 5px 0px 5px;
  }

  /* #endregion style default */

  /* #region style texts */
  .txt-title-dashboard-card {
    font-size: 11px;
    color: #000000;
  }

  .txt-result-dashboard-card {
    font-size: 11px;

    margin-left: 1px;
  }

  .txt-result-number-dashboard-card {
    color: #2759A2;
    font-size: 35px;
    font-weight: 500;
  }

  .color-green {
    color: #55AA65
  }

  .color-red {
    color: #E01600;
  }

  .color-yellow {
    color: #FFB600;
  }

  .font-bold {
    font-weight: bold;
  }

  /* #endregion style texts */



}

/*#endregion XS*/
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .title-section-dashboard {
    margin-left: 15px !important;
  }

  /* #region empty dashboard style */
  .content-dashboard-without-permissions {
    position: relative;
  }

  .content-img-placeholder-dashboard {
    width: 100%;
  }

  .txt-dashboard-without-permissions-title {
    position: absolute;
    bottom: 30vh;
    right: 5%;
    width: 300px;
    text-align: end;
    color: #283C4D;
    font-size: 20px;
  }

  .txt-dashboard-without-permissions-information {
    position: absolute;
    bottom: 24vh;
    right: 5%;
    width: 250px;
    text-align: end;
    color: #A5AEC3;
    font-size: 15px;
  }

  /* #endregion empty dashboard style */


  /* #region style default */
  .content-sim-cards-top {
    overflow: auto;
  }

  .content-dashboard {
    width: 100%;
    margin-bottom: 25px;
    box-shadow: 0px 0px 0px 0px transparent !important;
    background-color: transparent;
    border-radius: 14px;
    padding: 14px;
  }

  .content-dashboard-card {
    display: flex;
    margin: 5px 0px 5px 0px;
    height: 100%;
    border-radius: 14px;
    border: 1px solid #D8E5FF;
    background-color: white;
  }

  .content-dashboard-left {
    /* width: 75px; */
    /* max-width: 75px; */
    min-width: 75px;
    margin-right: 1px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .divider-dashboard-card {
    width: 1px;
    display: flex;
    align-self: center;
    height: 85%;
    background-color: #D8E5FF;
  }

  .content-dashboard-right {
    width: 100%;
  }

  .content-title-result-dashboard {
    display: flex;
    align-items: center;
    padding: 5px 5px 0px 5px;
  }

  /* #endregion style default */

  /* #region style texts */
  .txt-title-dashboard-card {
    font-size: 11px;
    color: #000000;
  }

  .txt-result-dashboard-card {
    font-size: 11px;

    margin-left: 1px;
  }

  .txt-result-number-dashboard-card {
    color: #2759A2;
    font-size: 35px;
    font-weight: 500;
  }

  .color-green {
    color: #55AA65
  }

  .color-red {
    color: #E01600;
  }

  .color-yellow {
    color: #FFB600;
  }

  .font-bold {
    font-weight: bold;
  }

  /* #endregion style texts */



}

/*#endregion SM*/
/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

    /* #region empty dashboard style */
    .content-dashboard-without-permissions {
    position: relative;
  }

  .content-img-placeholder-dashboard {
    width: 100%;
  }

  .txt-dashboard-without-permissions-title {
    position: absolute;
    bottom: 30vh;
    right: 5%;
    width: 450px;
    text-align: end;
    color: #283C4D;
    font-size: 20px;
  }

  .txt-dashboard-without-permissions-information {
    position: absolute;
    bottom: 24vh;
    right: 5%;
    width: 250px;
    text-align: end;
    color: #A5AEC3;
    font-size: 15px;
  }

  /* #endregion empty dashboard style */

}

/*#endregion MD*/
/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(158, 204, 109);
  }
}

/*#endregion LG*/
/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL*/
/*#endregion modo-responsivo */
</style>

<style scoped >
.content-dashboard-all {
  display: flex;
  width: 100%;

}

.content-dashboard-left-all {
  width: 100%;
  margin-right: 20px;
}

.content-dashboard-left-plans {}

.content-dashboard-left-customer-prebilling {
  display: flex;
  margin-top: 20px;
  width: 100%;
}


.content-dashboard-left-customer {
  width: 100%;
  margin-right: 20px;
}

.content-dashboard-left-prebilling {
  width: 100%;

}

.content-dashboard-right-all {
  width: 550px;
}

/*#region modo-responsivo */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-dashboard-all {
    display: block;
    width: 100%;

  }

  .content-dashboard-left-all {
    width: 100%;
    margin-right: 20px;
  }

  .content-dashboard-left-plans {}

  .content-dashboard-left-customer-prebilling {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }

  .content-dashboard-left-customer {
    width: 100%;
    margin-right: 20px;
  }

  .content-dashboard-left-prebilling {
    width: 100%;

  }

  .content-dashboard-right-all {
    width: 100%;
  }

}

/*#endregion XS*/
/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-dashboard-all {
    display: block;
    width: 100%;

  }

  .content-dashboard-left-all {
    width: 100%;
    margin-right: 20px;
  }

  .content-dashboard-left-plans {}

  .content-dashboard-left-customer-prebilling {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
  }

  .content-dashboard-left-customer {
    width: 100%;
    margin-right: 20px;
  }

  .content-dashboard-left-prebilling {
    width: 100%;

  }

  .content-dashboard-right-all {
    width: 100%;
  }

}

/*#endregion SM*/
/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD*/
/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG*/
/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL*/
/*#endregion modo-responsivo */
</style>


