<template>
  <div>
    <layout-add-customer-header />
    <div class="separator-line mt-5 mb-9" />
    <layout-add-customer-form @setPublicName="setPublicName" @setEmail="setEmail"
      @setContactFullName="setContactFullName" @setCountryCallingCode="setCountryCallingCode"
      @setAreaCallingCode="setAreaCallingCode" @setPhoneNumber="setPhoneNumber"
      @setPhoneExtension="setPhoneExtension" />
    <!-- <div class="separator-line mt-7 mb-9" /> -->
    <!-- <layout-add-customer-permissions @setPermissions="setPermissions" /> -->
    <div class="separator-line mt-7 mb-9" />
    <layout-add-customer-buttons :aPermissions="aPermissions" :sPublicName="sPublicName" :sEmail="sEmail"
      :sContactFullName="sContactFullName" :sCountryCallingCode="sCountryCallingCode"
      :sAreaCallingCode="sAreaCallingCode" :sPhoneNumber="sPhoneNumber" :sPhoneExtension="sPhoneExtension" />
  </div>
</template>

<script>
import LayoutAddCustomerHeader from "@/layouts/AddCustomer/Header.vue";
import LayoutAddCustomerForm from "@/layouts/AddCustomer/Form.vue";
import LayoutAddCustomerPermissions from "@/layouts/AddCustomer/Permissions.vue";
import LayoutAddCustomerButtons from "@/layouts/AddCustomer/Buttons.vue";

export default {
  name: "AddCustomerView",
  data() {
    return {
      aPermissions: [],

      sPublicName: "",
      sEmail: "",
      sContactFullName: "",

      sCountryCallingCode: "52",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",
    };
  },
  methods: {
    // setPermissions: function (val) {
    //   this.aPermissions = val;
    // },
    setPublicName: function (val) {
      this.sPublicName = val;
    },
    setEmail: function (val) {
      this.sEmail = val;
    },
    setContactFullName: function (val) {
      this.sContactFullName = val;
    },
    setCountryCallingCode: function (val) {
      this.sCountryCallingCode = val;
    },
    setAreaCallingCode: function (val) {
      this.sAreaCallingCode = val;
    },
    setPhoneNumber: function (val) {
      this.sPhoneNumber = val;
    },
    setPhoneExtension: function (val) {
      this.sPhoneExtension = val;
    },
  },
  components: {
    LayoutAddCustomerHeader,
    LayoutAddCustomerForm,
    LayoutAddCustomerPermissions,
    LayoutAddCustomerButtons,
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>