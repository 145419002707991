<template>
  <div class="content-tabs-cards">
    <div class="sticky-content">
      <layout-detail-customer-header class="mb-5 mt-1" :bLteEnabled="bLteEnabled"  :oFixedInfo="oFixedInfo" :bTab="tab" />
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="#2759A2"
        align-with-title
        :touchless="true"
        center-active
        show-arrows
      >
        <v-tabs-slider class="px-slider"></v-tabs-slider>
        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </div>
    <layout-detail-customer-tabs
      :tab="tab"
      @setFixedInfoDetail="setFixedInfo"
      @setLteEnabledDetail="setLteEnabled"
      @setOptions="setOptions"
      :bTab="tab"
    />
     <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />

  </div>
</template>

<script>
import LayoutDetailCustomerHeader from "@/layouts/DetailCustomer/Header.vue";
import LayoutDetailCustomerTabs from "@/layouts/DetailCustomer/Tabs.vue";

export default {
  name: "DetailCustomerView",
  data() {
    return {
      tab: 0,
      tabs: [
        { text: "Listado de tar jetas SIM", component: "LayoutTabsSimCards" },
        { text: "Información general", component: "LayoutTabsInformacion" },
      ],
      oFixedInfo: {},
      bLteEnabled: null,
      oOptions: {}
    };
  },
  mounted() {
    this.$nextTick(() => {
      // setup click event for next icon
      document
        .getElementsByClassName("v-slide-group__next")[0]
        .addEventListener("click", () => {
          this.tab = this.tab + 1;
        });
      // setup click event for previous icon
      document
        .getElementsByClassName("v-slide-group__prev")[0]
        .addEventListener("click", () => {
          this.tab = this.tab - 1;
        });
    });
  },
  components: {
    LayoutDetailCustomerHeader,
    LayoutDetailCustomerTabs,
  },
  methods: {
    setFixedInfo(oFixedInfo) {
      this.oFixedInfo = oFixedInfo;
    },
    setLteEnabled(bLteEnabled) {
      this.bLteEnabled = bLteEnabled;
    },
    setOptions(oOptions){
      this.oOptions = oOptions;
    }
  },
};
</script>

<style>
.content-tabs-cards .v-tabs-slider-wrapper {
  height: 5px !important;
}

.content-tabs-cards .v-tab {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #a1acc3 !important;
  opacity: 1;
  text-transform: initial;
}

.content-tabs-cards .v-tab--active {
  color: #2759a2 !important;
}

.content-tabs-cards .theme--light.v-tabs-items {
  background-color: transparent;
}

.v-slide-group__wrapper {
    border-bottom:1px solid #a1acc3 !important
}
</style>

<style scoped>
.px-slider {
  background: transparent linear-gradient(268deg, #79defc 0%, #2759a2 100%) 0%
    0% no-repeat padding-box;
}

.sticky-content {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #f2f6fe;
}

@media (max-width: 960px) {
  .sticky-content {
    position: inherit !important;
  }
}
</style>