

<template>
  <div>
    <div class="content-title-section">Información de presencia</div>
    <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <div>
            <p class="txt-label-global">APN actual</p>
            <p class="txt-value-global">{{ oPresence.sAPN }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <div>
            <p class="txt-label-global">IP actual</p>
            <p class="txt-value-global">{{ oPresence.sCurrentIP }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <div>
            <p class="txt-label-global">País</p>
            <p class="txt-value-global">{{ oCountry.sName }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="3" xl="3">
          <div>
            <p class="txt-label-global">Última tecnologia acceso detectada</p>
            <p class="txt-value-global">{{ oPresence.sLastUsedTechnology }}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="separator-line mt-2 mb-5" />
    <!-- <v-container fluid class="pl-0 pr-0">
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="4">
          <div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="rangoFechas"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="rangoFechas"
                  label="Rango de fechas"
                  placeholder="Seleccionar fechas"
                  persistent-placeholder
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="global-auth-inputs poppins mb-9"
                  dense
                  color="#2759A2"
                >
                  <template slot="prepend">
                    <v-icon class="icon-calendar-date">mdi-calendar</v-icon>
                  </template></v-text-field
                >
              </template>
              <v-date-picker v-model="rangoFechas" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(rangoFechas)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </v-container> -->
    <!-- <div class="separator-line mb-5" />
    <div class="content-title-section mb-5">Histórico contexto GPRS</div>
    <div>
      <div class="content-card-dialog">
        <chart-global
          idChartDinamyc="ChartHistoricoContexto"
          :apexChartOptions="optionContextoGPRS"
          :apexChartSeries="seriesContextGPRS"
        />
      </div>
    </div>

    <div class="separator-line mt-3 mb-5" />
    <div class="content-title-section mb-5">Histórico pruebas de presencia</div>
    <div>
      <div class="content-card-dialog">
        <chart-global
          idChartDinamyc="ChartHistoricoPrueba"
          :apexChartOptions="optionPruebasPresencia"
          :apexChartSeries="seriesPruebasPresencia"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import ChartGlobal from "../../../components/ChartGlobal.vue";
export default {
  components: { ChartGlobal },
  props: {
    tab: Number,
  },
  name: "layoutTabsPresencia",
  data() {
    return {
      rangoFechas: null,
      menu: false,
      optionContextoGPRS: {
        chart: {
          type: "rangeBar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: "dd MMM",
          },
        },
        colors: "#79DEFC",
      },
      seriesContextGPRS: [],
      optionPruebasPresencia: {
        chart: {
          type: "rangeBar",
          defaultLocale: "sp",
          locales: [
            {
              name: "sp",
              options: {
                months: [
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre",
                ],
                shortMonths: [
                  "Ene",
                  "Feb",
                  "Mar",
                  "Abr",
                  "May",
                  "Jun",
                  "Jul",
                  "Ago",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dic",
                ],
                days: [
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sabado",
                  "Domingo",
                ],
                shortDays: ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"],
              },
            },
          ],
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        colors: "#79DEFC",
      },
      seriesPruebasPresencia: [],
      oPresence: {},
      oCountry: {},
      oFixedInfo: {},
    };
  },
  activated() {
    if (this.tab == 2) {
      this.getHistoryGPRS();
      this.getHistoryPresencia();
    }
  },
  methods: {
    getHistoryGPRS() {
      const newSeries = [
        {
          data: [
            {
              x: "Contexto",
              y: [
                new Date("2019-01-01").getTime(),
                new Date("2019-02-24").getTime(),
              ],
            },
          ],
        },
      ];
      this.seriesContextGPRS = newSeries;
    },
    getHistoryPresencia() {
      DB.get(`${URL_KITE_LOCAL}/${this.$route.params.id}/presence`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.oPresence = response.data.results.oPresence;
          this.oCountry = this.oPresence.oCountry;
          this.oFixedInfo = response.data.results.oFixedInfo;
          this.$emit("setFixedInfo", this.oFixedInfo);

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
      // const newSeries = [
      //   {
      //     data: [
      //       {
      //         x: "GSM",
      //         y: [
      //           new Date("2019-01-01").getTime(),
      //           new Date("2019-02-24").getTime(),
      //         ],
      //       },
      //       {
      //         x: "IP",
      //         y: [
      //           new Date("2019-01-01").getTime(),
      //           new Date("2019-01-01").getTime(),
      //         ],
      //       },
      //     ],
      //   },
      // ];
      // this.seriesPruebasPresencia = newSeries;
    },
  },
  computed: {
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    tab() {
      if (this.tab == 2) {
        this.getHistoryGPRS();
        this.getHistoryPresencia();
      }
    },
    refresh() {
      this.getHistoryGPRS();
      this.getHistoryPresencia();
    },
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.txt-label {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.txt-value {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

.icon-calendar-date {
  margin-top: -10px;
  color: #000000;
}
</style>