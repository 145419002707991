import Vue from 'vue';

/** global Components **/
Vue.component('DeleteComponent', () => import('@/components/Delete.vue'));
Vue.component('EmptyContentComponent', () => import('@/components/EmptyContent.vue'));
Vue.component('HeaderComponentGlobal', () => import('@/components-global/headers-globals/HeaderComponentGlobal.vue'));
Vue.component('ConfirmResetSim', () => import('@/components/ConfirmResetSIM.vue'));
Vue.component('VTextFiledCurrency', () => import('@/components/v-text-field-currency.vue'));




//#region dashboard components //
Vue.component('ContentPromotionsComponent', () => import('@/layouts/Dashboard/Promotions/Content.vue'));
//#endregion dashboard components //




