<template>
  <div>
    <layout-customer-header @setDialogAdd="setDialogAdd" />
    <layout-customer-searchbar
      @setSearch="setSearch"
      @setNameOrder="setNameOrder"
      @setPlatformAccess="setPlatformAccess"
    />
    <layout-customer-content
      :sSearch="sSearch"
      @setOptions="setOptions"
      :sNameOrder="sNameOrder"
      :bPlatformAccess="bPlatformAccess"
    />
    <layout-customer-add
      :bDialogAdd="bDialogAdd"
      @setDialogAdd="setDialogAdd"
    />
    <!-- general component  -->
    <delete-component
      :oOptions="oOptions"
      @setOptions="setOptions"
    />
  </div>
</template>

<script>
import LayoutCustomerHeader from "@/layouts/Customer/Header.vue";
import LayoutCustomerContent from "@/layouts/Customer/Content.vue";
import LayoutCustomerSearchbar from "@/layouts/Customer/Searchbar.vue";
import LayoutCustomerAdd from "@/layouts/Customer/Add.vue";

export default {
  name: "CustomerView",
  data() {
    return {
      sSearch: "",
      bDialogAdd: false,
      oOptions: {},
      sNameOrder: undefined,
      bPlatformAccess: undefined,
    };
  },
  methods: {
    setSearch: function (val) {
      this.sSearch = val;
    },
    setDialogAdd: function (val) {
      this.bDialogAdd = val;
    },
    setOptions: function (val) {
      this.oOptions = val;
    },
    setNameOrder: function (val) {
      this.sNameOrder = val;
    },
    setPlatformAccess: function (val) {
      this.bPlatformAccess = val;
    },
  },
  components: {
    LayoutCustomerHeader,
    LayoutCustomerContent,
    LayoutCustomerSearchbar,
    LayoutCustomerAdd,
  },
};
</script>