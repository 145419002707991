<template>
  <div class="content-search mt-9 mb-9">
    <!-- <v-container class="pa-0" fluid> -->
    <!-- <v-row> -->
    <!-- <v-col cols="12" sm="4" md="4" lg="4"> -->
    <div class="content-input-search">
      <v-text-field
        v-model="sSearch"
        type="text"
        label="Buscar por"
        placeholder="Buscar por empresa o plan de datos"
        dense
        color="#2759A2"
        class="global-auth-inputs poppins mb-4"
        maxlength="85"
        append-icon="mdi-magnify"
        persistent-placeholder
        @input="setSearch"
      ></v-text-field>
      <div
        v-show="bMobile"
        class="
          display-flex
          align-items-center
          justify-content-flex-end
          position-relative
          
        "
      >
        <v-menu
          offset-y
          content-class="circle-profile-content"
          :close-on-content-click="false"
          attach
          left
          :min-width="widthMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="button-secondary ml-9"
              style="width: 50px; margin-top: 0px"
              elevation="0"
            >
              <v-icon>mdi-tune-vertical</v-icon>
            </v-btn>
          </template>
          <div class="circle-content-options">
            <div>
              <div class="content-down-menu">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div>
                        <p class="txt-title-down-menu poppins mb-1">
                          Plan de datos
                        </p>
                        <div>
                          <v-checkbox
                            v-for="(item, index) in itemsPlans"
                            :key="index"
                            v-model="resultPlanDatos"
                            :value="item.sPlanId"
                            label=""
                            hide-details=""
                            color="#2759a2"
                            class="mb-1"
                          >
                            <template v-slot:label>
                              <p class="txt-description-down-menu poppins mb-0">
                                {{ item.sName }}
                              </p>
                            </template>
                          </v-checkbox>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p class="txt-title-down-menu poppins mb-1">Estado</p>
                      <div>
                        <v-checkbox
                          v-for="(item, index) in itemsStatus"
                          :key="index"
                          v-model="resultStatus"
                          :value="item.sSIMRequestStatusId"
                          label=""
                          hide-details=""
                          color="#2759a2"
                          class="mb-1"
                        >
                          <template v-slot:label>
                            <p class="txt-description-down-menu poppins mb-0">
                              {{ item.sName }}
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <!-- otro filtro aqui -->
              </div>
            </div>
          </div>
        </v-menu>
      </div>
    </div>
    <!-- </v-col> -->
    <!-- <v-col cols="12" sm="5" md="5" lg="5"> -->
    <div class="position-relative" @click="menu = true">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="rangoFechas"
        transition="scale-transition"
        offset-y
        min-width="auto"
        attach
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="content-input-date-range">
            <v-combobox
              v-model="rangoFechas"
              v-bind="attrs"
              v-on="on"
              label="Rango de fechas"
              placeholder="Seleccionar fechas"
              persistent-placeholder
              readonly
              class="global-auth-inputs"
              dense
              color="#2759A2"
              append-icon="mdi-calendar"
              chips
              small-chips
              multiple
              clearable
            ></v-combobox>
          </div>
        </template>
        <v-date-picker v-model="rangoFechas" :max="nowDate" no-title scrollable multiple range>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              menu = false;
              $refs.menu.save((dates = []));
              rangoFechas = [];
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="
              $refs.menu.save(rangoFechas);
              saveDates();
            "
            :disabled="rangoFechas.length < 2"
          >
            Aceptar
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <!-- </v-col> -->
    <v-spacer />
    <!-- <v-col cols="12" sm="3" md="3" lg="3"> -->
    <div
      v-show="!bMobile"
      class="
        display-flex
        align-items-center
        justify-content-flex-end
        position-relative
      "
    >
      <v-menu
        offset-y
        content-class="circle-profile-content"
        :close-on-content-click="false"
        attach
        left
        :min-width="widthMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="button-secondary"
            style="width: 50px; margin-top: -5px"
            elevation="0"
          >
            <v-icon>mdi-tune-vertical</v-icon>
          </v-btn>
        </template>
        <div class="circle-content-options">
          <div>
            <div class="content-down-menu">
              <v-container fluid>
                <v-row>
                  <v-col cols="12" sm="6">
                    <div>
                      <p class="txt-title-down-menu poppins mb-1">
                        Plan de datos
                      </p>
                      <div>
                        <v-checkbox
                          v-for="(item, index) in itemsPlans"
                          :key="index"
                          v-model="resultPlanDatos"
                          :value="item.sCustomPlanId"
                          label=""
                          hide-details=""
                          color="#2759a2"
                          class="mb-1"
                        >
                          <template v-slot:label>
                            <p class="txt-description-down-menu poppins mb-0">
                              {{ item.sName }}
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p class="txt-title-down-menu poppins mb-1">Estado</p>
                    <div>
                      <v-checkbox
                        v-for="(item, index) in itemsStatus"
                        :key="index"
                        v-model="resultStatus"
                        :value="item.sSIMRequestStatusId"
                        label=""
                        hide-details=""
                        color="#2759a2"
                        class="mb-1"
                      >
                        <template v-slot:label>
                          <p class="txt-description-down-menu poppins mb-0">
                            {{ item.sName }}
                          </p>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <!-- otro filtro aqui -->
            </div>
          </div>
        </div>
      </v-menu>
    </div>
    <!-- </v-col> -->
    <!-- </v-row> -->
    <!-- </v-container> -->
  </div>
</template>

<script>
export default {
  name: "LayoutSimsSearchbar",
  data() {
    return {
      sSearch: "",
      resultPlanDatos: undefined,
      resultStatus: undefined,
      itemsPlans: [],
      rangoFechas: [],
      itemsStatus: [],
      menu: false,
      widthMenu: "",
      nowDate: new Date().toISOString().slice(0, 10),
      date: new Date(),
      picker: new Date().toISOString().substr(0, 10),
      landscape: false,
      reactive: false,
      bMobile: false,
    };
  },
  beforeMount() {
    this.getPlans();
    this.getStatus();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 959.99) {
        this.widthMenu = "250px";
        this.bMobile = true;
      } else {
        this.widthMenu = "350px";
        this.bMobile = false;
      }
    },
    setSearch: function (e) {
      this.$emit("setSearch", e);
    },
    getPlans: function () {
      DB.get(
        `${URI}/enterprises/${this.$store.state.sEnterpriseId}/custom-plans/dropdown`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.itemsPlans = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    getStatus: function () {
      DB.get(`${URI}/status`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          this.itemsStatus = response.data.results;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    saveDates: function () {
      this.$emit("setDates", this.rangoFechas);
    },
  },
  watch: {
    rangoFechas: function () {
      if (this.rangoFechas.length == 2) {
        if (this.rangoFechas[0] > this.rangoFechas[1]) {
          let fecha_inicial = this.rangoFechas[1];
          this.rangoFechas = [];
          this.rangoFechas.push(fecha_inicial);
        }
      }

      if (this.rangoFechas.length == 0) {
        this.$emit("setDates", []);
      }
    },
    resultPlanDatos: function () {
      this.$emit("setPlanId", this.resultPlanDatos);
    },
    resultStatus: function () {
      this.$emit("setStatusId", this.resultStatus);
    },
  },
};
</script>

<style scoped>
.content-search {
  display: flex;
  width: 100%;
}
.content-input-search {
  width: 250px;
  max-width: 250px;
  min-width: 250px;
}
.content-input-date-range {
  width: 280px;
  max-width: 280px;
  min-width: 280px;
  margin-left: 25px;
}
.txt-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-weight: 600;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-search {
    display: block;
    width: 100%;
  }
  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .content-search {
    display: block;
    width: 100%;
  }
  .content-input-search {
    display: flex;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
  .content-input-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>