<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12">
          <div class="content-title-section mb-5">Datos informativos</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="3" md="3" lg="3">
          <div>
            <v-text-field
              v-model="sPublicName"
              type="text"
              label="Nombre público"
              placeholder="Ingrese nombre público"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLetters"
            >
              <template slot="label">
                <span>Nombre público <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3">
          <div>
            <v-text-field
              v-model="sEmail"
              type="text"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="formatEmail"
            >
              <template slot="label">
                <span>Correo electrónico <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <!-- Start: phone component -->
            <country-code-input-component
              class=""
              :bObligatoryField="true"
              @setCodigoPais="setCodigoPais"
              @setCodigoArea="setCodigoArea"
              @setNumeroTelefono="setNumeroTelefono"
              @setExtension="setExtension"
              :_bDialogAdd="false"
              :_codigoPais="sCountryCallingCode"
              :_codigoArea="sAreaCallingCode"
              :_numeroTelefono="sPhoneNumber"
              :_extension="sPhoneExtension"
              :_type="false"
            />
            <!-- End: phone component -->
          </div>
        </v-col>
      </v-row>
      <div class="separator-line mt-7 mb-9" />
      <v-row>
        <v-col cols="12">
          <div class="content-title-section mb-5">Representante</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div>
            <v-text-field
              v-model="sContactFullName"
              type="text"
              label="Nombre del representante"
              placeholder="Ingrese el nombre del representante"
              dense
              color="#2759A2"
              class="global-auth-inputs poppins"
              maxlength="85"
              persistent-placeholder
              @keypress="soloLettersAndNumbers"
            >
              <template slot="label">
                <span>Nombre del representante <span class="color-red">*</span></span>
              </template>
            </v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CountryCodeInputComponent from "@/components/CountryCodeInput.vue";

export default {
  name: "LayoutAddEmployeeForm",
  data() {
    return {
      nombre: "",
      apellido: "",
      cargoLaboral: "",
      correoElectronico: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",

      sPublicName: "",
      sEmail: "",
      sContactFullName: "",

      sCountryCallingCode: "",
      sAreaCallingCode: "",
      sPhoneNumber: "",
      sPhoneExtension: "",
    };
  },
  methods: {
    soloLetters: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    formatEmail: function (evt) {
      var regex = new RegExp("^[a-zA-Z-_0-9@,. ]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    formatCity: function (evt) {
      var regex = new RegExp("^[_A-Za-zÀ-ÖØ-öø-ÿ0-9,.'-()¡!]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();

        return false;
      }
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    soloLettersAndNumbers: function (evt) {
      var regex = new RegExp("^[ A-Za-zÀ-ÖØ-öø-ÿ0-9,'-]+$");
      var key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (!regex.test(key)) {
        evt.preventDefault();
        return false;
      }
    },
    // Reepresentante
    setCodigoPais: function (e) {
      this.sCountryCallingCode = e;
    },
    setCodigoArea: function (e) {
      this.sAreaCallingCode = e;
    },
    setNumeroTelefono: function (e) {
      this.sPhoneNumber = e;
    },
    setExtension: function (e) {
      this.sPhoneExtension = e;
    },
    // Empresa
    setCodigoPaisEmpresa: function (e) {
      this.sCountryCallingCode = e;
    },
    setCodigoAreaEmpresa: function (e) {
      this.sAreaCallingCode = e;
    },
    setNumeroTelefonoEmpresa: function (e) {
      this.sPhoneNumber = e;
    },
    setExtensionEmpresa: function (e) {
      this.sPhoneExtension = e;
    },
  },
  watch: {
    sPublicName: function () {
      this.$emit("setPublicName", this.sPublicName);
    },
    sEmail: function () {
      this.$emit("setEmail", this.sEmail);
    },
    sContactFullName: function () {
      this.$emit("setContactFullName", this.sContactFullName);
    },

    sCountryCallingCode: function () {
      this.$emit("setCountryCallingCode", this.sCountryCallingCode);
    },
    sAreaCallingCode: function () {
      this.$emit("setAreaCallingCode", this.sAreaCallingCode);
    },
    sPhoneNumber: function () {
      this.$emit("setPhoneNumber", this.sPhoneNumber);
    },
    sPhoneExtension: function () {
      this.$emit("setPhoneExtension", this.sPhoneExtension);
    },
  },
  components: {
    CountryCodeInputComponent,
  },
};
</script>

<style scoped>
.content-title-section {
  background: #d3e5ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
  padding: 0px 15px;
  width: fit-content;
}

.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>
