<template>
  <div>
    <!-- INICIO seccion homologada de estado actual de la solicitud -->
    <div>
      <div class="content-title-section mb-7">
        Estado actual de la solicitud
      </div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <!-- <v-col cols="12" md="3"> -->
          <div class="content-status-clt">
            <v-col>
              <div class="mb-4">
                <p class="txt-title-def poppins mb-0 ml-3 pt-3">
                  Estado actual
                </p>
                <!-- <p class="txt-value-global">{{ CustomPlan.sName }}</p> -->
              </div>
            </v-col>
            <v-col style="text-align: center">
              <div class="mr-5">
                <v-chip
                  :color="getColorStatus(sSIMRequestStatusId).sColorBackground"
                  :text-color="getColorStatus(sSIMRequestStatusId).sColorText"
                >
                  {{ sName }}
                </v-chip>
              </div>
            </v-col>
          </div>
          <!-- </v-col> -->
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />

    <!-- END seccion homologada de estado actual de la solicitud -->
    <div>
      <div class="content-title-section mt-7 mb-7">Plan solicitado</div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Plan</p>
              <p class="txt-value-global">{{ CustomPlan.sName }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="9">
            <div>
              <p class="txt-label-global">Descripción</p>
              <p v-if="CustomPlan.sDescription" class="txt-value-global">
                {{ CustomPlan.sDescription }}
              </p>
              <p v-else class="txt-empty-global">
                {{ emptyData }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div>
      <div class="content-title-section mb-7">Selección de tarjetas SIM</div>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Tarjeta tamaño: Estándar</p>
              <p class="txt-value-global">
                {{
                  getObjectEmptyGlobal(itemSim)
                    ? itemSim.SIMRequestStatusRecord[0].sName === "Confirmado"
                      ? itemSim.iLargeSIMsQuantityApproved
                      : itemSim.iLargeSIMsQuantityRequired
                    : 0
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Tarjeta tamaño: Micro</p>
              <p class="txt-value-global">
                {{
                  getObjectEmptyGlobal(itemSim)
                    ? itemSim.SIMRequestStatusRecord[0].sName === "Confirmado"
                      ? itemSim.iMediumSIMsQuantityApproved
                      : itemSim.iMediumSIMsQuantityRequired
                    : 0
                }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Tarjetas tamaño: Nano</p>
              <p class="txt-value-global">
                {{
                  getObjectEmptyGlobal(itemSim)
                    ? itemSim.SIMRequestStatusRecord[0].sName === "Confirmado"
                      ? itemSim.iSmallSIMsQuantityApproved
                      : itemSim.iSmallSIMsQuantityRequired
                    : 0
                }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="content-active-cards">
      <!-- <v-checkbox
          disabled
          v-model="itemSim.bActiveSIMCards"
          color="#f89925"
          class="mt-0 pt-0 chk-sim"
          hide-details
        ></v-checkbox> -->
      <p class="text-active-cards">
        Requiero que todas las tarjetas SIM tengan un estado:
        {{ itemSim.bActiveSIMCards ? "Activo" : "Inactivo" }}.
      </p>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div>
      <div class="content-title-section mb-7">
        Información adicional de la solicitud
      </div>
      <!-- <div class="content-title-section mb-7">Comentarios adicionales</div> -->
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="12">
            <div>
              <p class="txt-label-global">Comentarios adicionales</p>
              <p class="txt-value-global">{{ itemSim.sComments }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="separator-line mt-5 mb-9" />
    <div>
      <p class="content-title-section">Información de entrega</p>
      <p class="subcontent-text-section">Datos de envío</p>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Tipo de envío</p>
              <p class="txt-value-global">{{ eDeliveryType }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3" v-show="this.itemSim.eDeliveryType === 2">
            <div>
              <p class="txt-label-global">
                Selecciona paquetería de preferencia
              </p>
              <p class="txt-value-global">{{ itemSim.sCarrierName }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="separator-line mt-5 mb-9" />
      <p class="subcontent-text-section mt-4">Datos de ubicación</p>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">
                País de ubicación de sucursal {{ itemSim.sCarrierName }}
              </p>
              <p class="txt-value-global">{{ itemSim.sCountryName }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Estado de ubicación de sucursal</p>
              <p class="txt-value-global">{{ itemSim.sStateName }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Ciudad de ubicación de sucursal</p>
              <p class="txt-value-global">{{ itemSim.sShippingCity }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Código postal de la sucursal</p>
              <p class="txt-value-global">{{ itemSim.sShippingPostalCode }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <div>
              <p class="txt-label-global">
                Dirección de sucursal {{ itemSim.sCarrierName }}
              </p>
              <p class="txt-value-global">
                {{ itemSim.sShippingAddressLine1 }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <div>
              <p class="txt-label-global">
                Referencias adicionales de la ubicación: Sucursal
                {{ itemSim.sCarrierName }}
              </p>
              <p class="txt-value-global">
                {{ itemSim.sShippingAddressLine2 }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="separator-line mt-5 mb-9" />
      <p class="subcontent-text-section mt-4">Datos de contacto</p>
      <v-container class="pl-0 pr-0" fluid>
        <v-row>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Nombre completo de destinatario</p>
              <p class="txt-value-global">{{ itemSim.sReceiverFullName }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">
                Correo electrónico del destinatario
              </p>
              <p class="txt-value-global">{{ itemSim.sReceiverEmail }}</p>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div>
              <p class="txt-label-global">Número telefónico del destinatario</p>
              <p class="txt-value-global">{{ itemSim.sReceiverPhone }}</p>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="12">
            <div>
              <p class="txt-label-global">
                Comentarios
              </p>
              <p class="txt-value-global">
                {{ itemSim.sComments }}
              </p>
            </div>
          </v-col> -->
        </v-row>
      </v-container>
      <div class="separator-line mt-5 mb-9" />
      <div class="content-buttons-and-message mb-9">
        <div class="content-message">
          <span>
            1.- Un (1) envío gratis al mes dentro de la república mexicana (1 a
            3 días hábiles).
          </span>
          <br />
          <br />
          <span>
            2.- Envíos internacionales pueden generar costos de envío
            adicionales que se podrán visualizar en la próxima factura.</span
          >
          <br />
          <br />
          <span>
            3.-Las solicitudes de tarjetas recibidas después de las 11:00 am
            (CST) Monterrey México serán despachadas al siguiente día
            hábil.</span
          >
        </div>
      </div>
      <div class="content-buttons">
        <v-spacer></v-spacer>
        <v-btn class="button-secondary" @click="onReturn">Regresar</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailSimsForm",
  data() {
    return {
      sSIMRequestStatusId: "",
      emptyData: "Sin información.",
      itemSim: {},
      CustomPlan: {},
      Plan: {},
      oItem: {},
      oCustomPlan: {},
      sName: "",
      aTypeSend: [
        {
          text: "Entrega a domicilio",
          value: 1,
        },
        {
          text: "Entrega en sucursal",
          value: 2,
        },
        {
          text: "Buscar Emprenet",
          value: 3,
        },
      ],
      eDeliveryType: "",
    };
  },
  beforeMount() {
    this.getSimsDetail();
  },
  methods: {
    onReturn() {
      this.$router.push({ name: "sims" });
    },
    getColorStatus(sStatusID) {
      let oColors = {};
      switch (sStatusID) {
        case "7f595ba0-3275-435f-a48e-4dfaa811702a": //Solicitud enviada
          oColors.sColorText = "#2C9DCE";
          oColors.sColorBackground = "#C0E2F0";
          return oColors;
        case "cd495340-fa3b-4234-89cd-b56c59db318d": //Evaluando
          oColors.sColorText = "#E7C127";
          oColors.sColorBackground = "#F8EDBE";

          return oColors;
        case "5834ff8c-0c17-42ee-afd1-5a84163363e8": //Confirmado
          oColors.sColorText = "#8E4DFF";
          oColors.sColorBackground = "#DDCAFD";
          return oColors;
        case "2a94cfb4-b4f1-420d-a513-c3a368946a48": //Enviado
          oColors.sColorText = "#68BBDF";
          oColors.sColorBackground = "#D2EBF6";

          return oColors;
        case "8ee1565e-ee98-462d-a445-b1d7248dbaed": //Entregado
          oColors.sColorText = "#1EA966";
          oColors.sColorBackground = "#BCE5D1";

          return oColors;
        case "875c3c08-cf5a-424b-988b-e724d33e4e8b": //Cancelado
          oColors.sColorText = "#D77580";
          oColors.sColorBackground = "#F3D6D9";

          return oColors;
        case "e6ea266d-ed35-40f4-9f06-98cffb846c9b": //Reposición
          oColors.sColorText = "#424657";
          oColors.sColorBackground = "#C6C8CD";

          return oColors;

        default:
          oColors.sColorText = "#00000";
          oColors.sColorBackground = "#00000";

          return oColors;
      }
    },
    getSimsDetail() {
      DB.get(`${URI}/sim-requests/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          // iPageNumber: this.iCurrentPage,
          // sSearch: this.sSearch,
          // iItemsPerPage: this.iItemsPerPage,
          // bPlatformAccess: this.sStatus,
          // sPlanId: this.sPlanId,
          // sCustomerId: this.$store.state.sUserId,
        },
      })
        .then((response) => {
          // this.bLoading = false;
          this.itemSim = response.data.results;
          this.Plan = response.data.results.CustomPlan.sName;
          this.CustomPlan = response.data.results.CustomPlan;
          this.eDeliveryType = this.aTypeSend.find(
            ({ value }) => value === this.itemSim.eDeliveryType
          ).text;
          this.oItem = response.data.results;
          this.sName = response.data.results.SIMRequestStatusRecord[0].sName;
          this.sSIMRequestStatusId =
            response.data.results.SIMRequestStatusRecord[0].sSIMRequestStatusId;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.content-status-clt {
  display: flex;
  align-items: center;
  width: 100%;
}

.txt-title-def {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.content-active-cards {
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  /* border: 1px solid #f8a130;
  border-left: 5px solid #f8a130; */
  /* border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px; */
  /* padding: 10px 20px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f8a130;
  border-left: 5px solid #f8a130;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #f8a130;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.text-active-cards {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #f89925;
  opacity: 1;
  margin-bottom: 0;
}

.content-message {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #2759a2;
  border-left: 5px solid #2759a2;
  border-radius: 5px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #2759a2;
}

.content-buttons,
.content-buttons-and-message {
  display: flex;
  align-items: center;
}

.button-secondary,
.button-primary {
  height: 40px !important;
  padding: 0px 20px !important;
}

.button-primary,
.button-secondary {
  margin-left: 10px;
}

.separator-line {
  border-bottom: 1px solid #a1acc3;
}

.subcontent-text-section {
  text-align: left;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2759a2;
  opacity: 1;
}

@media (max-width: 600px) {
  .content-buttons,
  .content-buttons-and-message {
    display: block;
    width: 100%;
  }

  .button-primary,
  .button-secondary {
    width: 100%;
  }

  .button-primary,
  .button-secondary {
    margin-left: 0px;
    margin-top: 20px;
  }
}
</style>
