<template>
  <div class="content-buttons-true mb-5" v-if="editData">
    <v-btn v-if="bAdminEmployees" @click="setDeleteDialog" elevation="0" class="button-secondary-delete button4">
      Eliminar
    </v-btn>
    <v-spacer />
    <v-btn v-if="bAdminEmployees" elevation="0" class="button-secondary button5" @click="$emit('setEditData', false)">
      Cancelar
    </v-btn>
    <v-btn v-if="bAdminEmployees" elevation="0" class="button-primary button6" @click="saveChanges" :loading="bLoading">
      Guardar cambios
    </v-btn>
  </div>
  <div class="content-buttons-false mb-5" v-else>
    <v-btn v-if="bAdminEmployees" @click="setDeleteDialog" elevation="0" class="button-secondary-delete button4">
      Eliminar
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn elevation="0" class="button-secondary button1" @click="$router.push({ name: 'employee' })">
      Regresar
    </v-btn>
    <v-btn v-if="bAdminEmployees" elevation="0" class="button-secondary button2" @click="setDialogForgotPassword">
      Solicitar cambio de contraseña
    </v-btn>
    <v-btn v-if="bAdminEmployees" elevation="0" class="button-primary button3" @click="$emit('setEditData', true)">
      Modificar
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LayoutDetailEmployeeButtons",
  props: {
    items: {
      type: Object,
    },
    editData: {
      type: Boolean,
    },
    dataForm: {
      type: Object,
    },
    dataPermissions: {
      type: Array,
    },
    bPermission: {
      type: Boolean
    }
  },
  data() {
    return {
      bLoading: false,
    };
  },
  methods: {
    saveChanges: function () {
      this.bLoading = true;
      this.$store.dispatch("getPermissionsByUserGlobal").then((resp) => {
        if (this.getPermissionsActionsGlobal()) {
          const config = {
            headers: {
              Authorization: `Bearer ${this.$store.state.sToken}`,
            },
          },
            payload = {
              sName: this.dataForm.nombre,
              sLastname: this.dataForm.apellido,
              // sEmail: this.dataForm.correoElectronico,
              sJobTitle: this.dataForm.cargoLaboral,
              sPhoneNumber: this.dataForm.numeroTelefono,
              sCountryCallingCode: this.dataForm.codigoPais,
              sAreaCallingCode: this.dataForm.codigoArea,
              sPhoneExtension: this.dataForm.extension,
              aCustomerPermissions: this.dataPermissions.map((e) => {
                return {
                  sCustomerModuleId: e.sModuleId,
                  aPermissions: [
                    {
                      sPermissionId: e.sShowPermissionId,
                      bBoolean: e.bShow,
                    },
                    {
                      sPermissionId: e.sAdminPermissionId,
                      bBoolean: e.bAdmin,
                    },
                  ]
                }
              })
            };
          DB.put(
            `${URI}/enterprises/${this.$store.state.sEnterpriseId}/customers/${this.items.sCustomerId}`,
            payload,
            config
          )
            .then((response) => {
              this.bLoading = false;

              this.mixSuccess(response.data.message);
              this.$store.commit("refresher", true);
            })
            .catch((error) => {
              this.bLoading = false;
              this.mixError(error.response.data.message);
            });

        } else {
          this.mixError(this.$store.state.sMessageErrorAccess);
          this.bLoading = false;

          this.$store.commit("refresher", true);
        }


      }).catch((error) => {
        this.mixError(error.message, error.code);
      });

    },
    setDialogForgotPassword: function () {
      this.$emit("setDialogForgotPassword", true);
    },
    setDeleteDialog() {
      let options = {
        active: true,
        id: this.$route.params.id,
        api: `/enterprises/${this.$store.state.sEnterpriseId}/customers/${this.$route.params.id}`,
        title: "Eliminar empleado",
        description:
          "Eliminar un empleado es una acción irreversible </br>  ¿Desea continuar?",
        bReturnView: true,
      };
      this.$emit("setOptions", options);
    },
  },
  computed: {
    bAdminEmployees() {
      return this.$store.state.bAdminEmployees
    }
  }
};
</script>

<style scoped>
/* buttons in false */
.button1,
.button2,
.button3 {
  height: 40px !important;
  padding: 0px 30px !important;
}

.content-buttons-false {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button2,
.button3 {
  margin-left: 10px;
}

@media (max-width: 600px) {
  .content-buttons-false {
    display: block;
  }

  .content-buttons-true {
    display: block !important;
  }

  .button1,
  .button2,
  .button3,
  .button4,
  .button5,
  .button6 {
    margin-left: 0px !important;
    margin-top: 20px;
  }

  .button1,
  .button2,
  .button3,
  .button4,
  .button5,
  .button6 {
    width: 100%;
  }
}

/* buttons in true  */
.content-buttons-true {
  display: flex;
  align-items: center;
}

.button4,
.button5,
.button6 {
  height: 40px !important;
  padding: 0px 20px !important;
}

.button6 {
  margin-left: 10px;
}
</style>