<template>
  <div>
    <layout-add-employee-header />
    <div class="separator-line mt-5 mb-9" />
    <layout-add-employee-form
      @setNombre="setNombre"
      @setApellido="setApellido"
      @setCargoLaboral="setCargoLaboral"
      @setCorreoElectronico="setCorreoElectronico"
      @setCodigoPais="setCodigoPais"
      @setCodigoArea="setCodigoArea"
      @setNumeroTelefono="setNumeroTelefono"
      @setExtension="setExtension"
    />
    <div class="separator-line mt-7 mb-9" />
    <layout-add-employee-permissions :bAddOrEdit="true" @setPermissions="setPermissions" @setValdatePermissions="setValdatePermissions" />
    <div class="separator-line mt-7 mb-9" />
    <layout-add-employee-buttons
      :aPermissions="aPermissions"
      :nombre="nombre"
      :apellido="apellido"
      :cargoLaboral="cargoLaboral"
      :correoElectronico="correoElectronico"
      :codigoPais="codigoPais"
      :codigoArea="codigoArea"
      :numeroTelefono="numeroTelefono"
      :extension="extension"
      :bPermission="bPermission"
    />
  </div>
</template>

<script>
import LayoutAddEmployeeHeader from "@/layouts/AddEmployee/Header.vue";
import LayoutAddEmployeeForm from "@/layouts/AddEmployee/Form.vue";
// import LayoutAddEmployeePermissions from "@/layouts/AddEmployee/Permissions.vue";
import LayoutAddEmployeePermissions from "@/components/Permissions.vue";
import LayoutAddEmployeeButtons from "@/layouts/AddEmployee/Buttons.vue";

export default {
  name: "AddEmployeeView",
  data() {
    return {
      aPermissions: [],
      nombre: "",
      apellido: "",
      cargoLaboral: "",
      correoElectronico: "",
      codigoPais: "52",
      codigoArea: "",
      numeroTelefono: "",
      extension: "",
      bPermission:false,
    };
  },
  methods: {
    setValdatePermissions(val){
      this.bPermission = val
    },
    setPermissions: function (val) {
      this.aPermissions = val;
    },
    setNombre: function (val) {
      this.nombre = val;
    },
    setApellido: function (val) {
      this.apellido = val;
    },
    setCargoLaboral: function (val) {
      this.cargoLaboral = val;
    },
    setCorreoElectronico: function (val) {
      this.correoElectronico = val;
    },
    setCodigoPais: function (val) {
      this.codigoPais = val;
    },
    setCodigoArea: function (val) {
      this.codigoArea = val;
    },
    setNumeroTelefono: function (val) {
      this.numeroTelefono = val;
    },
    setExtension: function (val) {
      this.extension = val;
    },
  },
  components: {
    LayoutAddEmployeeHeader,
    LayoutAddEmployeeForm,
    LayoutAddEmployeePermissions,
    LayoutAddEmployeeButtons,
  },
};
</script>

<style scoped>
.separator-line {
  border-bottom: 1px solid #a1acc3;
}
</style>