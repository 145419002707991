<template>
  <div
    class="width-100 display-flex"
    :style="`height: ${screenHeight}px`"
  >
    <div class="display-flex align-items-center justify-content-center content-left height-100">
      <layout-forgot-password-form />
    </div>
    <div class="content-right height-100">
      <layout-forgot-password-background />
    </div>
  </div>
</template>

<script>
import LayoutForgotPasswordBackground from "@/layouts/ForgotPassword/Background.vue";
import LayoutForgotPasswordForm from "@/layouts/ForgotPassword/Form.vue";

export default {
  name: "ForgotPasswordView",
  data() {
    return {
      screenHeight: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight;
    },
  },
  components: {
    LayoutForgotPasswordBackground,
    LayoutForgotPasswordForm,
  },
};
</script>

<style scoped>
.content-left {
  width: 45%;
}

.content-right {
  width: 55%;
}

@media (max-width: 960px) {
  .content-right {
    display: none;
  }

  .content-left {
    width: 100%;
  }
}
</style>