<template>
  <div fluid>
    <v-data-table v-if="!bMobile" :headers="aHeader" :items="aPaid" :items-per-page="-1" :hide-default-footer="true"
      no-data-text="No hay información" class="elevation-1 mt-5">
    </v-data-table>

    <div v-else class="mt-3">
      <v-container class="pa-0" fluid>
        <v-row>
          <v-col v-for="(item, index) in aPaid" :key="index" cols="12" :sm="aPaid.length === 1 ? 12 : 6">
            <div class="content-card-responsive  position-relative">
              <div class="height-100">
                <div>
                  <div class="content-first-row-card">
                    <div class="content-text-title">
                      <p class="txt-item-table-card-title poppins mb-3">
                        {{ item.tPaidAt }}
                      </p>
                    </div>
                  </div>

                  <div class="flex-wrap display-flex align-items-center">
                    <div class="content-left">
                      <p class="px-txt-card-title mb-0 mr-2">Cantidad</p>
                    </div>
                    <div class="content-right">
                      <p class="px-txt-card-item mb-0">{{
                      formatMoneyGlobal(item.dAmount)
                      }}</p>
                    </div>

                  </div>
                  <div class=" display-flex align-items-center">
                    <div class="content-left">
                      <p class="px-txt-card-title mb-0 mr-2">Descripción</p>
                    </div>
                    <div class="content-right">
                      <p class="px-txt-card-item mb-0">
                        {{ item.sDescription }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <v-col v-if="aPaid.length === 0">
            <EmptyContentComponent text="Sin pagos" height="150" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!--#region CONTENIDO DE BOTONES -->
            <div class="content-btns-global">
              <div class="content-btn-edit">
                <v-btn class="button-secondary" width="100%" @click="setDialogAddOrEditPaid(true)">
                  Añadir producto
                </v-btn>

              </div>
            </div>
            <!--#endregion CONTENIDO DE BOTONES -->
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="content-total-all">
      <div class="content-total-paid-pay">
        <div class="content-left-total-all">
          <span class="txt-total-left">Total pagado</span>
        </div>
        <div class="content-right-total-all">
          <span class="txt-total-right">$ {{ oExpense.oTotals ? formatMoneyGlobal(oExpense.oTotals.dPayed) : 0 }}
            MXN</span>
        </div>
      </div>
      <div class="content-total-paid-pay">
        <div class="content-left-total-all">
          <span class="txt-total-left">Total a pagar</span>
        </div>
        <div class="content-right-total-all">
          <span class="txt-total-right">$ {{ oExpense.oTotals ? formatMoneyGlobal(oExpense.oTotals.dPending) : 0}}
            MXN</span>
        </div>
      </div>

      <div class="content-total">
        <div class="content-left-total">
          <span>TOTAL</span>
        </div>
        <v-spacer />
        <div class="content-right-total">
          <span>$ {{ oExpense.oTotals ? formatMoneyGlobal(oExpense.oTotals.dTotal) : 0 }} MXN</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    items: Object,
    oExpense: Object,
  },
  data() {
    return {
      aHeader: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'tPaidAt',
          sClassHeader: "txt-color-black-global w-250"

        },
        {
          text: 'Descripción',
          sortable: false,
          value: 'sDescription',
          sClassHeader: "txt-color-black-global"
        },
        {
          text: 'Cantidad',
          sortable: false,
          value: 'dAmount',
          sClassHeader: "txt-color-black-global w-400"
        },
      ],
      aPaid: [],
      bDialogConfirmGlobal: false,
      oItemDialogFinishedInvoice: {},
      bMobile: false,
      sDate: "",
      sDateEdit: "",
      sDescription: "",
      sDescriptionEdit: "",
      iQuantity: 0,
      iQuantityEdit: 0,
      bAddPaid: false,
      bDialogAddOrEditPaid: false,
      bAddOrEdit: false,
      oItem: {},
      aSubtotal: {}
    }
  },
  beforeMount() {
    this.fullDataPayments(this.oExpense)
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 959.99) {
        this.bMobile = false;
        this.bDialogAddOrEditPaid = false;

      }
      else {
        this.bMobile = true;
        this.bAddPaid = false;
        for (let i = 0; i < this.aPaid.length; i++) {
          this.aPaid[i].bEdit = false;
        }
        this.iQuantity = 0;
      }
    },
    changeQuantity(value) {
      if (value !== "") {
        this.iQuantity = parseFloat(value);
      }
      else {
        this.iQuantity = 0;
      }
    },
    changeQuantityEdit(value) {
      if (value !== "") {
        this.iQuantityEdit = parseFloat(value);
      }
      else {
        this.iQuantityEdit = 0;
      }
    },
    fullDataPayments(oExpense) {
      if (this.getObjectEmptyGlobal(oExpense)) {
        this.aPaid = oExpense.oPaymentList;
      }
    },
    setEditItem(item) {
      this.bAddPaid = false;
      for (let i = 0; i < this.aPaid.length; i++) {
        this.aPaid[i].bEdit = false;
      }
      item.bEdit = true;
      this.sDescriptionEdit = item.sDescription;
      this.iQuantityEdit = item.iQuantity;
      this.iPriceEdit = item.iPrice;

    },
    editItem(item) {
    },
    setAddItem() {
      for (let i = 0; i < this.aPaid.length; i++) {
        this.aPaid[i].bEdit = false;
      }
      this.bAddPaid = true;
    },
    fullData(oItem, oExpense) {
      this.oGeneralInformation = {
        sFolio: oItem.sFolio,
        sClient: oItem.oEnterprise.sName,
        sStatus: oItem.sStatus,
        sPeriod: oItem.oPeriod.sName
      };

      //[
      // {
      //   sAdvanceInvoiceId: 0,
      //   sDate: "01/01/2022",
      //   sDescription: "5Mb global / RENTA SERVICIO",
      //   iQuantity: 10,
      // },
      // {
      //   sAdvanceInvoiceId: 1,
      //   sDate: "01/01/2022",
      //   sDescription: "5Mb global / EXCEDENTE DE DATOS MB kjdhkajhkas daskjdbkas aksjdbkasd ksdjbaksd kasjdbkas",
      //   iQuantity: 34.1,
      // }
      //]

      //[
      // {
      //   sName: "Consumo",
      //   iAmount: 999999999.99
      // },
      // {
      //   sName: "Productos adicionales",
      //   iAmount: 999999999.99
      // },
      // {
      //   sName: "Descuentos",
      //   iAmount: 999999999.99
      // },
      // {
      //   sName: "Impuestos",
      //   iAmount: 999999999.99
      // },
      // {
      //   sName: "Total",
      //   iAmount: 999999999.99
      // }
      //];

    },
    finishedInvoice() {
      let oItem = {
        sTitleDialog: "Finalizar factura",
        sQuestion: `<span>¿Estas seguro de finalizar la prefactura -folio-?</span><br/><span>Al realizar esta acción no podrá revertirse</span>`,
        sDoubleConfirmation: true,
        sConfirmation: `Acepto la responsabilidad y deseo proseguir con la finalización de la prefactura: --folio--`,
        sApi: "",
        sNameBtnAction: "Finalizar",
      }
      this.oItemDialogFinishedInvoice = oItem;
      this.bDialogConfirmGlobal = !this.bDialogConfirmGlobal
    },
    setDialogAddOrEditPaid(bAddOrEdit) {
      this.bAddOrEdit = bAddOrEdit;
      this.bDialogAddOrEditPaid = !this.bDialogAddOrEditPaid;

    }
  },
  watch: {
    oExpense() {
      this.fullDataPayments(this.oExpense);

    }
  },
  computed: {
    validationForm: function () {
      return (this.sDescription !== "" &&
        this.iQuantity > 0);
    },
    validationFormEdit: function () {
      return (this.sDescriptionEdit !== "" &&
        this.iQuantityEdit > 0);
    },
  },
}
</script>

<style scoped>
.information-title {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 2px;
  width: 200px;
}

.content-add-consumption {
  padding: 10px;
}

.btn-add-additional-product {
  width: 150px;
}

.content-total-all {
  margin-top: 15px;
  margin-left: 1px;
  margin-bottom: 1px;
  height: 180px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.content-total-paid-pay {
  padding: 15px;
  display: flex;

}

.content-left {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.content-right {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.content-left-total-all {
  width: 100%;
}

.content-right-total-all {
  width: 100%;
  text-align: end;
}

.content-total {
  display: flex;
  background-color: #D8E5FF;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 17px;
  color: #000000;
}

.txt-total-left {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.txt-total-right {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

/*#region STYLES CARDS */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  overflow-wrap: break-word !important;

}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.content-text-title {
  width: 90%;
  max-width: 90%;
  min-width: auto;
}

.content-actions {
  display: flex;
  width: 20px;
  max-width: 20px;
  min-width: 20px;
  margin-left: 10px;
}

.content-first-row-card {
  display: flex;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}



.content-status-mobile-card {
  width: 70px;
  display: flex;
  /* position: absolute; */
  right: 30px;
  top: 10px;
}


.px-card-test-select-all {
  width: fit-content;
}

.px-card-text-mb {
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.flex-wrap {
  flex-wrap: wrap;
}

.px-txt-card-title {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 600;
}

.px-txt-card-item {
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 5px;
}

.content-left {
  width: 90px;
  display: flex;
  height: 100%;
  align-self: start
}

/*#endregion STYLES CARDS */



/*#region GLOBAL CONTENT BTN */
.content-btns-global {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.content-btn-edit {
  width: 150px;
}


/*#endregion GLOBAL CONTENT BTN */
/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }


  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>